import {call, put, select} from "@redux-saga/core/effects";
import deltas from "../../../../redux/actions/deltas";
import request from "../../../helpers/request";
import {retryFailedPaymentProcessById} from "../../../../api";
import {selectFailedPaymentProcesses} from "../../../../redux/reducers/data/failedPaymentProcesses";
import pollFailedPaymentProcesses from "./pollFailedPaymentProcesses";

const {
    addRetryingFailedPaymentProcessId,
    deleteFailedPaymentProcess,
    setFailedPaymentProcesses,
} = deltas.actionCreators;

const DELAY_BEFORE_ONE_OFF_POLL_IN_MS = 5000;

export default function* retryFailedPaymentProcess(id)
{
    yield put(addRetryingFailedPaymentProcessId(id));

    const {response} = yield call(request, retryFailedPaymentProcessById, {}, {}, id) || {};

    if (404 === response.status) {
        yield put(deleteFailedPaymentProcess(id));
        const failedPaymentProcesses = yield select(selectFailedPaymentProcesses);
        yield put(setFailedPaymentProcesses(failedPaymentProcesses));
    }

    yield call(pollFailedPaymentProcesses, true, DELAY_BEFORE_ONE_OFF_POLL_IN_MS);
}

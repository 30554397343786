import notify from "./notify";
import {
    call,
    select,
    spawn,
} from "redux-saga/effects";
import jwtDecode from 'jwt-decode';
import tryToRefreshToken from "./tryToRefreshToken";
import loggedOut from "../state-machines/loggedOut";
import {login} from "../../api";

const tokenCloseToExpiryThresholdInMinutes = 1;

export default function* request(apiFunction, options, data={}, id, queryParameters) {
    const {
        notificationOnError,
        attempts = 1,
    } = options || {};

    let token = yield select(state => state.token);

    const {isImpersonating, userIdToImpersonate} = yield select(state => state.impersonation);

    if (token) {
        const {exp} = jwtDecode(token);

        if (((1000 * exp) - Date.now()) < (tokenCloseToExpiryThresholdInMinutes * 60 * 1000)) {
            const result = yield call(tryToRefreshToken);

            if (!result) {
                yield call(loggedOut);
                return;
            }

            token = yield select(state => state.token);
        }
    }

    let i = 0;
    while (i < attempts) {
        try {
            const response = yield call(
                apiFunction,
                {
                    data,
                    token,
                    ...isImpersonating && {
                        userIdToImpersonate
                    },
                    id,
                    queryParameters,
                }
            );

            if (response.status && 401 === response.status && login !== apiFunction) {
                const result = yield call(tryToRefreshToken);

                if (!result) {
                    yield call(loggedOut);
                    return;
                }

                token = yield select(state => state.token);

                continue;
            }

            return {response};
        } catch (error) {
            if (notificationOnError) {
                yield spawn(notify, 'warning', notificationOnError.generateMessage(error.message));
            }

            if (i >= attempts - 1) {
                return {error};
            }
        }
        i++;
    }
}

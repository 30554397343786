import createDataReducer from "../../helpers/createDataReducer";
import deltas from "../../actions/deltas";

const {
    SET_DELIVERY_TYPES,
    UPDATE_DELIVERY_TYPE,
    CLEAR_DELIVERY_TYPES,
} = deltas.actionTypes;

export const selectDeliveryTypes = (state) => state.data.deliveryTypes;

export default createDataReducer(
    SET_DELIVERY_TYPES,
    UPDATE_DELIVERY_TYPE,
    CLEAR_DELIVERY_TYPES,
);

import {dateFromText} from "../atoms/DateInput";
import {ValidationError} from 'yup';

const validateYupSchema = (schema, value) => {
    try {
        schema.validateSync(value);
    } catch (e) {
        if (e instanceof ValidationError) {
            return e.errors;
        }

        throw e;
    }

    return null;
}

export default (value, required, maxChars, noFutureDate, yupSchema) =>
    (required && null === value && 'You must enter a value') ||
    (maxChars && value && value.length > maxChars && 'The value you entered is too long') ||
    (noFutureDate && dateFromText(value) > new Date()) && 'This date cannot be in the future.' ||
    (yupSchema && !yupSchema.isValidSync(value)) && validateYupSchema(yupSchema, value) ||
    null;

import createDataReducer from "../../helpers/createDataReducer";
import deltas from "../../actions/deltas";
import {createSelector} from "reselect";

const {
    SET_ENROLMENTS,
    UPDATE_ENROLMENT,
    CLEAR_ENROLMENTS,
} = deltas.actionTypes;

export default createDataReducer(
    SET_ENROLMENTS,
    UPDATE_ENROLMENT,
    CLEAR_ENROLMENTS,
);

export const selectEnrolments = state => state.data.enrolments;
export const selectCountOfEnrolments = createSelector(
    selectEnrolments,
    (enrolments) => Object.keys(enrolments).length,
);
export const selectMaxUpdatedAtFromEnrolments = createSelector(
    selectEnrolments,
    (enrolments) => Object.values(enrolments)
        .reduce((maxUpdatedAt, {updatedAt}) => {
            const updatedAtDateObject = new Date(updatedAt);

            if (null === maxUpdatedAt || updatedAtDateObject > maxUpdatedAt) {
                return updatedAtDateObject;
            }

            return maxUpdatedAt;
        }, null),
);

import createFormReducer from "../../helpers/createFormReducer";
import fields from "../../fields/schoolAndLogin";
import deltas from "../../actions/deltas";

const {
    UPDATE_SCHOOL_AND_LOGIN_FORM,
    SET_SCHOOL_AND_LOGIN_FORM,
    CLEAR_SCHOOL_AND_LOGIN_FORM,
} = deltas.actionTypes;

export default createFormReducer(fields, UPDATE_SCHOOL_AND_LOGIN_FORM, SET_SCHOOL_AND_LOGIN_FORM, CLEAR_SCHOOL_AND_LOGIN_FORM);

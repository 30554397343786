import gridsConfirmationModalTypeStates from "../../../../redux/states/gridsConfirmationModalType";
import React from "react";
import {GridsConfirmationModal} from "./GridsConfirmationModal";

const {
    MARK_CONTRACT_ISSUED,
} = gridsConfirmationModalTypeStates;

export const BulkMarkContractIssuedConfirmationModal = () =>
    <GridsConfirmationModal
        title={'Mark contracts issued'}
        saveButtonText={'Mark contracts issued'}
        type={MARK_CONTRACT_ISSUED}
    >
        Are you sure you want to mark the selected enrolments as "Contract Issued"?
        {/*TODO: Consider loading animation when waiting for request*/}
        {/*TODO: Disable button when clicked*/}
    </GridsConfirmationModal>

import React from "react";
import CheckboxInput from "../../atoms/CheckboxInput";
import classnames from "classnames";

export default ({openModal, row: {original: {id}}, actionColumnHover, setActionColumnHover, selectedRows, setEnrolmentsGridRowSelected, unsetEnrolmentsGridRowSelected}) =>
    <div className="body__cell cell--actions" onMouseEnter={() => setActionColumnHover(true)} onMouseLeave={() => setActionColumnHover(false)}>
        <button className="link-teal-to-gray button-link" data-cy="enrolments-edit" onClick={() => openModal(id)}>
            Edit
        </button>
        {/* Disabled bulk update feature */}
        {/*<div className={"actions__more--checkboxes " + classnames({'show': actionColumnHover})}>*/}
        {/*    <CheckboxInput*/}
        {/*        value={selectedRows.includes(id)}*/}
        {/*        onClickOverride={e => e.target.checked ? setEnrolmentsGridRowSelected(id) : unsetEnrolmentsGridRowSelected(id)}*/}
        {/*    />*/}
        {/*</div>*/}
    </div>;

import createReducer from "./createReducer";

const generateInitialState = fields => ({
    ...Object.assign(...Object.values(fields).map(fieldName => ({
        [fieldName]: {
            value: null,
            isDirty: false,
            error: null,
        },
    }))),
    _clean: {
        ...Object.assign(Object.values(fields).map(fieldName => ({
            [fieldName]: null,
        }))),
    },
    _hasErrors: false,
});

const excludeFields = (fieldsToExclude) => (field) => (fieldsToExclude || []).includes(field);

export default (fields, update, set, clear, clearFieldErrorsByField = {}) => createReducer(
    {...generateInitialState(fields)},
    {
        [update]: (state, {field, value, error}) => ({
            ...state,
            [field]: {
                value: value,
                isDirty: !(value === state._clean[field]),
                error: error || null,
            },
            ...Object.assign({}, ...(clearFieldErrorsByField[field] || []).map(relatedErrorField => ({
                [relatedErrorField]: {
                    ...state[relatedErrorField],
                    error: null,
                }
            }))),
            _hasErrors: !!error || Object.values(filterFields(state, field)).filter(excludeFields(clearFieldErrorsByField[field])).some(field => null !== field.error),
        }),
        [set]: (state, {data, errors = {}, updateClean=true}) => ({
            ...Object.assign(...Object.entries(data).map(([fieldName, value]) => ({
                [fieldName]: {
                    value: undefined !== value ? value : null,
                    isDirty: updateClean ? false : !(value === state._clean[fieldName]),
                    error: errors[fieldName] || null,
                },
            }))),
            _clean: updateClean ? data : state._clean,
            _hasErrors: 0 !== Object.entries(errors).filter(([fieldName, error]) => null !== error && Object.values(fields).includes(fieldName)).length,
        }),
        [clear]: () => generateInitialState(fields),
    },
);

const filterFields = (state, field) => {

    // TODO refactor

    let result;

    try {
        result = {
            ...Object.assign(
                ...Object.keys(state)
                    .filter(key => '_' !== key[0] && field !== key)
                    .map(key => ({
                        ...(key && {[key]: state[key]}),
                    }))
            ),
        };
    } catch (exception) {
        result = {};
    }
    return result;
}

import PaymentsGrid from "../PaymentsGrid";
import React from "react";
import deltas from "../../../redux/actions/deltas";
import {connect} from "react-redux";
import CheckboxField from "../../atoms/CheckboxField";
import SelectInput from "../../atoms/SelectInput";
import signals from "../../../redux/actions/signals";
import roles from "../../../redux/roles";
import {createSelector} from 'reselect';
import PaymentProcessesPollingAlertMessage from "../../molecules/PaymentProcessesPollingAlertMessage";
import DownloadButton from "../../molecules/DownloadButton";
import {selectIsLoadingDownloadAllTransactions} from "../../../redux/reducers/states/downloadAllTransactions";
import {selectIsLoadingDownloadMonthlyClaim} from "../../../redux/reducers/states/downloadMonthlyClaim";
import {selectIsLoadingDownloadFinanceReport} from "../../../redux/reducers/states/downloadFinanceReport";
import {GRID_FILTER_TYPE_DATE, GRID_FILTER_TYPE_NUMERIC} from "../../molecules/GridFilter";

const EARLIEST_TRANSACTIONS_DOWNLOAD_DATE = new Date(2020, 3); // April (0-indexed month)
const now = new Date();
let workingDate = EARLIEST_TRANSACTIONS_DOWNLOAD_DATE;
const allTransactionsDownloadDates = [];

do {
    allTransactionsDownloadDates.unshift(new Date(workingDate.getTime()));
    workingDate.setMonth(workingDate.getMonth() + 1);
} while (!(workingDate.getMonth() === now.getMonth() && workingDate.getFullYear() === now.getFullYear()))

export const allColumns = [
    { accessor: 'enrolmentId', Header: 'Enrolment Id', gridFilterType: GRID_FILTER_TYPE_NUMERIC },
    { accessor: 'fullName', Header: 'Full Name' },
    { accessor: 'email', Header: 'Email' },
    { accessor: 'fund', Header: 'Wave' },
    { accessor: 'providerName', Header: 'Provider' },
    { accessor: 'courseName', Header: 'Course' },
    { accessor: 'cohortName', Header: 'Cohort' },
    { accessor: 'contractSignedAt', Header: 'Contract Signed At', gridFilterType: GRID_FILTER_TYPE_DATE },
    { accessor: 'completedAt', Header: 'Completed At', gridFilterType: GRID_FILTER_TYPE_DATE },
    { accessor: 'status', Header: 'Status' },
    {
        id: 'sumOfFixedTransactions',
        accessor: (row) => (row.sumOfFixedTransactions / 100).toLocaleString('en-GB', {
            style: 'currency',
            currency: 'GBP',
            useGrouping: false,
        }),
        Header: 'Sum of Payments',
        disableFilters: true,
        tooltipMessage: 'This column reflects the payments made up until the end of the previous month. Any payments for the current month can be viewed in the \'Monthly claim\' download.',
    },
];

const columnsProviderDoesNotSeeByKey = [
    'enrolmentId',
    'providerName',
];

export const cecColumns = allColumns;
export const providerColumns = allColumns.filter(column => !columnsProviderDoesNotSeeByKey.includes(column.id || column.accessor));

const {
    hidePaymentsGridColumn,
    showPaymentsGridColumn,
    showAllPaymentsGridColumns,
    resetPaymentsGridFilterAndSort,
    clearPaymentsGridRowsSelected,
    setPaymentsGridAllVisibleRowsSelected,
} = deltas.actionCreators;

const {
    downloadTransactions,
    downloadMonthlyClaim,
    downloadFinanceReport,
} = signals.actionCreators;

const getAreAllVisibleRowsSelected = createSelector(
    state => state.paymentsGrid.filteredSortedData,
    state => state.paymentsGrid.selectedRows,
    (filteredSortedData, selectedRows) => filteredSortedData.map(row => row.id).every(id => selectedRows.includes(id)));

const getBaseColumns = createSelector(
    state => state.role,
    role => (roles.CEC === role) ? cecColumns : providerColumns,
);

const getColumnsToShow = createSelector(
    state => state.paymentsGrid.columnsToHide,
    getBaseColumns,
    (columnsToHide, baseColumns) => baseColumns.filter(column => !columnsToHide.includes(column.id || column.accessor)),
);

const mapStateToProps = state => ({
    isCec: roles.CEC === state.role,
    columnsToHide: state.paymentsGrid.columnsToHide,
    baseColumns: getBaseColumns(state),
    columnsToShow: getColumnsToShow(state),
    allVisibleRowsSelected: getAreAllVisibleRowsSelected(state),
    isLoadingDownloadAllTransactions: selectIsLoadingDownloadAllTransactions(state),
    isLoadingDownloadMonthlyClaim: selectIsLoadingDownloadMonthlyClaim(state),
    isLoadingDownloadFinanceReport: selectIsLoadingDownloadFinanceReport(state),
});

const mapDispatchToProps = (dispatch) => ({
    hidePaymentsGridColumn: column => dispatch(hidePaymentsGridColumn(column)),
    showPaymentsGridColumn: column => dispatch(showPaymentsGridColumn(column)),
    showAllPaymentsGridColumns: () => dispatch(showAllPaymentsGridColumns()),
    resetPaymentsGridFilterAndSort: () => dispatch(resetPaymentsGridFilterAndSort()),
    downloadTransactions: (year, month) => dispatch(downloadTransactions(year, month)),
    downloadMonthlyClaim: () => dispatch(downloadMonthlyClaim()),
    downloadFinanceReport: () => dispatch(downloadFinanceReport()),
    clearPaymentsGridRowsSelected: () => dispatch(clearPaymentsGridRowsSelected()),
    setPaymentsGridAllVisibleRowsSelected: () => dispatch(setPaymentsGridAllVisibleRowsSelected()),
});

const PaymentsSection = ({isCec, resetPaymentsGridFilterAndSort, hidePaymentsGridColumn, showPaymentsGridColumn, showAllPaymentsGridColumns, downloadTransactions, downloadMonthlyClaim, downloadFinanceReport, baseColumns, columnsToHide, columnsToShow, isLoadingDownloadAllTransactions, isLoadingDownloadMonthlyClaim, isLoadingDownloadFinanceReport}) => {

    const formatOptionLabel = ({ value, label }) => (
        <div>
            <CheckboxField
                label={label}
                checked={!columnsToHide.includes(value) && 'ALL' !== value }
            />
        </div>
    );

    return <React.Fragment>
        <PaymentProcessesPollingAlertMessage />
        <div className="d-block d-md-flex mb-3 mt-4">
            <div className="w-100 col-md-3 px-0 no-padding mr-2">
                <SelectInput
                    id="o-columns"
                    placeholder="Hide and show columns"
                    isMulti={true}
                    overrideOnChange={(selectedOptions, {option: {value}}) => {
                        if (value === 'ALL') {
                            showAllPaymentsGridColumns();
                            return;
                        }

                        !columnsToHide.includes(value)
                            ? hidePaymentsGridColumn(value)
                            : showPaymentsGridColumn(value);
                    }}
                    options={
                        (
                            0 === columnsToHide.length
                                ? []
                                : [{label: 'Show All', value: 'ALL'}]
                        ).concat(
                            baseColumns.map(({id, accessor, Header}) => ({label: Header, value: id || accessor}))
                        )
                    }
                    {...{formatOptionLabel}}
                    styles={
                        {
                            container: (provided) => ({
                                ...provided,
                                height: '100%',
                            }),
                            control: (provided) => ({
                                ...provided,
                                height: '100%',
                            })
                        }
                    }
                />
            </div>
            <div className="text-justify mt-3 mt-md-auto ml-md-auto mb-n2">
                <button className="cec-btn cec-btn-secondary-teal mr-2 mb-2" data-cy="payments--reset-table-button" onClick={resetPaymentsGridFilterAndSort}>
                    Reset table
                </button>
                <DownloadButton
                    buttonText='Download all transactions'
                    isLoading={isLoadingDownloadAllTransactions}
                    className='cec-btn cec-btn-primary-teal mt-auto ml-auto dropdown-toggle mr-2 mb-2'
                    data-cy="download-all-transactions-button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                />
                <div className="dropdown-menu dropdown-menu-right">
                    <a className="dropdown-item link-teal-to-gray underline-0"
                       data-cy="download-all-transactions-selector"
                       onClick={(e) => {
                           e.preventDefault();
                           downloadTransactions();
                       }}
                       href={'#'}
                    >
                        All transactions
                    </a>
                    <div className="dropdown-divider" />
                    <div className="y-scrollable-menu">
                        {
                            allTransactionsDownloadDates.map(date =>
                                <a className="dropdown-item link-teal-to-gray underline-0"
                                   data-cy="download-transactions-date-selector"
                                   onClick={(e) => {
                                       e.preventDefault();
                                       downloadTransactions(date.getFullYear(), date.getMonth() + 1);
                                   }}
                                   href={'#'}
                                >
                                    {date.toLocaleDateString(undefined, {month: 'short', year: 'numeric'})}
                                </a>
                            )
                        }
                    </div>
                </div>
                <DownloadButton
                    buttonText='Download monthly claim'
                    isLoading={isLoadingDownloadMonthlyClaim}
                    className='cec-btn cec-btn-primary-teal mr-2 mb-2'
                    data-cy="download-monthly-claim-button"
                    onClick={downloadMonthlyClaim}
                />
                {
                    isCec &&
                    <DownloadButton
                        buttonText='Download finance report'
                        isLoading={isLoadingDownloadFinanceReport}
                        className="cec-btn cec-btn-primary-teal mb-2"
                        data-cy="download-finance-report-button"
                        onClick={downloadFinanceReport}
                    />
                }
            </div>
        </div>
        <PaymentsGrid columns={columnsToShow}/>
    </React.Fragment>;
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentsSection);

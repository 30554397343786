import React from "react";
import CheckboxField from "../atoms/CheckboxField";

export default ({value, selected, addFilterValue, removeFilterValue}) => <React.Fragment>
    <li className="list-group-item p-2">
        <CheckboxField
            label={value}
            id={value}
            checked={selected}
            onClick={
                e => e.target.checked
                    ? addFilterValue(value)
                    : removeFilterValue(value)
            }
        />
    </li>
</React.Fragment>;

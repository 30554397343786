import GridDateFilter from "./GridDateFilter";
import GridTextFilter from "./GridTextFilter";
import React from "react";
import GridNumericFilter from "./GridNumericFilter";

export const GRID_FILTER_TYPE_DATE = 'date';
export const GRID_FILTER_TYPE_NUMERIC = 'numeric';

export default ({filterOptions, addFilterValue, removeFilterValue, clearFilter, filtersApplied, setDateFilter, clearDateFilter, type}) => <React.Fragment>
    {
        (() => { switch(type) {
            case GRID_FILTER_TYPE_DATE:
                return <GridDateFilter {...{filtersApplied, setDateFilter, clearDateFilter}}/>;
            case GRID_FILTER_TYPE_NUMERIC:
                return <GridNumericFilter {...{filterOptions, addFilterValue, removeFilterValue, clearFilter}} />
            default:
                return <GridTextFilter {...{filterOptions, addFilterValue, removeFilterValue, clearFilter}} />
        }})()
    }
</React.Fragment>;

import React from "react";
import {connect} from "react-redux";
import signals from "../../../redux/actions/signals";
import {isRetryingFailedPaymentProcess} from "../../../redux/reducers/states/retryingFailedPaymentProcessIds";
import Tippy from "@tippyjs/react";

const {
    retryFailedPaymentProcess,
    openFailedPaymentProcessesMoreInfoModal,
} = signals.actionCreators;

const FailedPaymentProcessesActionsCell = ({isRetrying, retryFailedPaymentProcess, row: {original: {id}}, setActionColumnHover, openMoreInfoModal}) =>
    <div className="body__cell cell--actions flex-column justify-content-center align-items-start" onMouseEnter={() => setActionColumnHover(true)} onMouseLeave={() => setActionColumnHover(false)}>
        <button className="link-teal-to-gray gray-when-disabled button-link" onClick={() => retryFailedPaymentProcess(id)} disabled={isRetrying}>
            {
                isRetrying
                    ? <Tippy
                        content="This payment process has been queued for retry."
                        placement="top"
                        animation="fade"
                        arrow theme="light-border"
                    >
                        <span>Retrying...</span>
                    </Tippy>
                    : <span>Retry</span>
            }
        </button>
        <button
            className="link-teal-to-gray button-link"
            onClick={() => openMoreInfoModal(id)}
        >
            More info
        </button>
    </div>;

const mapStateToProps = (state, {row: {original: {id}}}) => ({
    isRetrying: isRetryingFailedPaymentProcess(state, id),
});

const mapDispatchToProps = (dispatch) => ({
    retryFailedPaymentProcess: (id) => dispatch(retryFailedPaymentProcess(id)),
    openMoreInfoModal: (id) => dispatch(openFailedPaymentProcessesMoreInfoModal(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FailedPaymentProcessesActionsCell);

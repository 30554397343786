import React from "react";

export default ({id, name, trueLabel, falseLabel, value, updateForm}) =>
    <>
        <div className={('pretty p-pulse p-has-focus p-round p-default')}>
            <input
                id={`${id}-true`}
                name={name}
                type={"radio"}
                checked={true === value}
                onClick={() => updateForm(true)}
            />
            <div className="state p-default-o">
                <label htmlFor={id}>{trueLabel}</label>
            </div>
        </div>
        <div className={('pretty p-pulse p-has-focus p-round p-default')}>
            <input
                id={`${id}-false`}
                name={name}
                type={"radio"}
                checked={false === value}
                onClick={() => updateForm(false)}
            />
            <div className="state p-default-o">
                <label htmlFor={id}>{falseLabel}</label>
            </div>
        </div>
    </>;

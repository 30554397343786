import {combineReducers} from 'redux';
import enrolments from "./data/enrolments";
import profile from "./data/profile";
import courses from "./data/courses";
import deliveryTypes from "./data/deliveryTypes";
import cohorts from "./data/cohorts";
import regions from "./data/regions";
import providers from "./data/providers";
import schoolSearchResults from "./data/schoolSearchResults";
import careersLeadSearchResults from "./data/careersLeadSearchResults";
import payments from './data/payments';
import failedPaymentProcesses from "./data/failedPaymentProcesses";
import funds from "./data/funds";

export default combineReducers({profile, enrolments, courses, deliveryTypes, cohorts, payments, failedPaymentProcesses, funds, regions, providers, schoolSearchResults, careersLeadSearchResults});

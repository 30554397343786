import deltas from "../actions/deltas";
import createGridReducer, {SORT_DESC} from "../helpers/createGridReducer";
import { allColumns } from '../../components/organisms/enrolments/EnrolmentsSection'
import {GRID_FILTER_TYPE_DATE} from "../../components/molecules/GridFilter";

const initialColumnsToHide = [
    'id',
    'email',
    'schoolName',
    'cohortName',
];

const {
    SET_ENROLMENTS,
    ADD_VALUE_TO_ENROLMENTS_GRID_TEXT_FILTER,
    REMOVE_VALUE_FROM_ENROLMENTS_GRID_TEXT_FILTER,
    CLEAR_ENROLMENTS_GRID_TEXT_FILTER,
    CYCLE_ENROLMENTS_GRID_COLUMN_SORT_STATE,
    SET_ENROLMENTS_GRID_DATE_FILTER,
    CLEAR_ENROLMENTS_GRID_DATE_FILTER,
    RESET_ENROLMENTS_GRID_FILTER_AND_SORT,
    HIDE_ENROLMENTS_GRID_COLUMN,
    SHOW_ENROLMENTS_GRID_COLUMN,
    SHOW_ALL_ENROLMENTS_GRID_COLUMNS,
    SET_ENROLMENTS_GRID_ROW_SELECTED,
    UNSET_ENROLMENTS_GRID_ROW_SELECTED,
    CLEAR_ENROLMENTS_GRID_ROWS_SELECTED,
    SET_ENROLMENTS_GRID_ALL_VISIBLE_ROWS_SELECTED,
} = deltas.actionTypes;

export const dateColumns = allColumns.filter(col => col.gridFilterType === GRID_FILTER_TYPE_DATE).map(col => col.accessor);

const initialSorters =
    [
        {
            column: 'signedUpAt',
            direction: SORT_DESC,
        },
    ];

export default createGridReducer(
    [],
    initialSorters,
    initialColumnsToHide,
    dateColumns,
    SET_ENROLMENTS,
    ADD_VALUE_TO_ENROLMENTS_GRID_TEXT_FILTER,
    REMOVE_VALUE_FROM_ENROLMENTS_GRID_TEXT_FILTER,
    CLEAR_ENROLMENTS_GRID_TEXT_FILTER,
    CYCLE_ENROLMENTS_GRID_COLUMN_SORT_STATE,
    SET_ENROLMENTS_GRID_DATE_FILTER,
    CLEAR_ENROLMENTS_GRID_DATE_FILTER,
    RESET_ENROLMENTS_GRID_FILTER_AND_SORT,
    HIDE_ENROLMENTS_GRID_COLUMN,
    SHOW_ENROLMENTS_GRID_COLUMN,
    SHOW_ALL_ENROLMENTS_GRID_COLUMNS,
    SET_ENROLMENTS_GRID_ROW_SELECTED,
    UNSET_ENROLMENTS_GRID_ROW_SELECTED,
    CLEAR_ENROLMENTS_GRID_ROWS_SELECTED,
    SET_ENROLMENTS_GRID_ALL_VISIBLE_ROWS_SELECTED,
);

import React from "react";
import {connect} from "react-redux";
import fields from "../../redux/fields/login";
import FormContext from "../FormContext";
import inputs from "../molecules/inputsWithContextAndSurrounds";
import deltas from "../../redux/actions/deltas";
import signals from "../../redux/actions/signals";

import EmailOutlineIcon from "mdi-react/EmailOutlineIcon";
import KeyIcon from "mdi-react/KeyIcon";
import ForgotPasswordModal from "../organisms/ForgotPasswordModal";
import {useCookies} from "react-cookie";

import {PLEASE_RESET_YOUR_PASSWORD_HERE} from "../../sagas/state-machines/pages/login/loggingIn";

const {EmailInput, PasswordInput} = inputs;

const {
    updateLoginForm,
} = deltas.actionCreators;

const {
    submitLoginForm,
    openForgotPasswordModal,
} = signals.actionCreators;

const mapStateToProps = state => ({
    formState: state.forms.login,
});

const mapDispatchToProps = {
    openForgotPasswordModal,
    updateLoginForm,
    submitLoginForm,
};

const SCHOOLSHUB_REGISTER_ENDPOINT = 'register';
const schoolshubUrl = (endpoint) => `${'' !== process.env.REACT_APP_SCHOOLSHUB_URL ? process.env.REACT_APP_SCHOOLSHUB_URL : 'https://tools.jx-cec-pr-5847.k8.careersandenterprise.co.uk'}/${endpoint}`;

const CHANGE_USER_TOKEN_COOKIE_NAME = 'change_user_request';

const Login = ({formState, updateLoginForm, submitLoginForm, openForgotPasswordModal}) => {
    const [{[CHANGE_USER_TOKEN_COOKIE_NAME]: changeUserTokenCookie}] = useCookies([CHANGE_USER_TOKEN_COOKIE_NAME]);

    const email = formState[fields.EMAIL].value;
    const loginErrorReplacements = {
        [PLEASE_RESET_YOUR_PASSWORD_HERE]: <a href="#" onClick={(e) => {e.preventDefault(); openForgotPasswordModal(email)}} className="link-red-to-gray">{PLEASE_RESET_YOUR_PASSWORD_HERE}</a>,
    };

    return (
        <React.Fragment>
            <div className="form--login bg-white rounded shadow-lg px-3 py-5 px-md-5">
                {/* Intro */}
                <div className="mb-5">
                    <p className="text-muted mb-2">Welcome to the</p>
                    <h1 className="font-20 font-weight-bold">Careers Leader Training Portal</h1>
                </div>
                {/* Messaging */}
                <div className="alert alert-cec-success fade-in-bottom login-alert mb-4">
                    <div className="d-flex">
                        <div className="border-right d-none d-sm-block border-info my-auto mr-3 pr-3">
                            <i className="mdi mdi-24px mdi-icon mdi-information-outline" />
                        </div>
                        <div className="my-auto">
                            <p>
                                If you are a Compass Classic or Compass+ user, you can use your existing login details to access the portal.
                            </p>
                            <p className="mb-0">
                                Don’t have an account? You can <a href={schoolshubUrl(SCHOOLSHUB_REGISTER_ENDPOINT)} className="link-teal-to-gray">sign-up here</a>.
                                This request will then be sent to your school administrator for approval before you
                                can access the portal.
                            </p>
                        </div>
                    </div>
                </div>
                {
                    null != changeUserTokenCookie &&
                        <div className="mb-4 red">
                            Login with your new credentials in order to connect your accounts.
                        </div>
                }
                {/* Form */}
                <FormContext.Provider value={{formState, updater: updateLoginForm}}>
                    <form action={"#"} onSubmit={e => {e.preventDefault(); submitLoginForm();}}>
                        <div className="mb-3">
                            <EmailInput id="o-loginEmail" label="Email" data-cy="login-email" placeholder="Enter your email address" icon={<EmailOutlineIcon />} field={fields.EMAIL}/>
                        </div>
                        <div className="mb-5">
                            <PasswordInput id="o-loginPassword" label="Password" data-cy="login-password" placeholder="Enter your password" icon={<KeyIcon />} field={fields.PASSWORD} errorTextToComponentReplacements={loginErrorReplacements}/>
                        </div>
                        <div className="mt-3">
                            <div className="row no-gutters justify-content-between align-items-end">
                                <div className="col-auto">
                                    <button type={"submit"} data-cy="login-button" className="cec-btn cec-btn-primary-teal">Log in</button>
                                </div>
                                <div className="col-auto text-right">
                                    <p className="mb-0">
                                        <a href="#" onClick={(e) => {e.preventDefault(); openForgotPasswordModal()}} className="link-teal-to-gray">Reset your password</a>
                                    </p>
                                    <p className="mb-0">
                                        Having any difficulty logging in? Contact <a href="mailto:help@careersandenterprise.co.uk?subject=Issue with logging into CLTP" className="link-teal-to-gray">help@careersandenterprise.co.uk</a>
                                    </p>
                                    <p className="mb-0">
                                        This website is for Authorised Users and is subject to our&nbsp;
                                        <a className="link-teal-to-gray" href="https://www.careersandenterprise.co.uk/careers-leaders/tools-resources/terms-and-conditions/">
                                            Terms and Conditions.</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </form>
                </FormContext.Provider>
            </div>
            <ForgotPasswordModal />
        </React.Fragment>
    )
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);

import {call, put, select} from "@redux-saga/core/effects";
import deltas from "../../../../redux/actions/deltas";
import {login} from "../../../../api";
import loginStates from '../../../../redux/states/login';
import getFieldsFromFormReducer from "../../../../redux/helpers/getFieldsFromFormReducer";
import getFieldValuesFromFormData from "../../../helpers/getFieldValuesFromFormData";
import {login as authLogin} from '../../../../auth';
import {spawn} from "redux-saga/effects";
import notify from "../../../helpers/notify";
import appLogin, {CltpUnauthorizedException} from "../../appLogin";

const {
    LOGGING_IN
} = loginStates;

const {
    setLoginState,
    setLoginForm,
    setToken,
    clearLoginForm,
} = deltas.actionCreators;

const failureMessage = 'There was a problem logging you in';

const DEFAULT_ERROR = 'Unexpected error. Please try again.';
const INCORRECT_CREDENTIALS_ERROR = 'The credentials you entered did not match our records. Please double-check and try again.';
const NOT_AUTHORIZED_ERROR = 'You are not authorized to access this application';
export const PLEASE_RESET_YOUR_PASSWORD_HERE = 'please reset your password here';
const PASSWORD_RESET_REQUIRED_ERROR = `Apologies, you need to reset your password. This will only take a minute - ${PLEASE_RESET_YOUR_PASSWORD_HERE}`;

const AWS_NOT_AUTHORIZED_ERROR_CODE = 'NotAuthorizedException';
const AWS_RESET_REQUIRED_ERROR_CODE = 'PasswordResetRequiredException';

const AWS_ERROR_ALLOWED_ERROR_CODES = [
    AWS_NOT_AUTHORIZED_ERROR_CODE,
    AWS_RESET_REQUIRED_ERROR_CODE,
];

const AWS_ERROR_INCORRECT_CREDENTIALS = 'Incorrect username or password.';
const AWS_ERROR_DISABLED_USER = 'User is disabled.';
const AWS_ERROR_USER_DOES_NOT_EXIST = 'User does not exist.';
const AWS_ERROR_PASSWORD_EXPIRED = 'PostAuthentication failed with error Password expired.';
const AWS_ERROR_SESSION_EXPIRED = 'Invalid session for the user, session is expired.';
const AWS_ERROR_PASSWORD_RESET_REQUIRED = 'Password reset required for the user';

const resolveAwsError = (awsErrorMessage) => ({
    [AWS_ERROR_INCORRECT_CREDENTIALS]: INCORRECT_CREDENTIALS_ERROR,
    [AWS_ERROR_DISABLED_USER]: NOT_AUTHORIZED_ERROR,
    [AWS_ERROR_USER_DOES_NOT_EXIST]: INCORRECT_CREDENTIALS_ERROR,
    [AWS_ERROR_PASSWORD_EXPIRED]: DEFAULT_ERROR,
    [AWS_ERROR_SESSION_EXPIRED]: DEFAULT_ERROR,
    [AWS_ERROR_PASSWORD_RESET_REQUIRED]: PASSWORD_RESET_REQUIRED_ERROR,
}[awsErrorMessage]);

const ALLOWED_CLTP_ERRORS = [
    'You do not have access to this tool. Please contact CEC if you think you should have access.',
    'You must be associated with a school in Compass to use this tool.',
    "Your user doesn't have the right role to access.",
];

const resolveCltpError = (cltpErrorMessage) => ALLOWED_CLTP_ERRORS.includes(cltpErrorMessage) && cltpErrorMessage;

export default function* loggingIn() {
    yield put(setLoginState(LOGGING_IN));
    const {email, password} = yield select(state => getFieldsFromFormReducer(state, 'login'));

    try {
        const token = yield call(authLogin, email, password);

        if (!token) {
            return false;
        }

        yield put(setToken(token));
        yield put(clearLoginForm());

        yield call(appLogin);
    } catch (error) {
        yield spawn(notify, 'warning', failureMessage);

        const loginFormData = yield select(state => state.forms.login);

        loginFormData.password.value = '';

        yield put(setLoginForm(getFieldValuesFromFormData(loginFormData), {
            password: ((AWS_ERROR_ALLOWED_ERROR_CODES.includes(error.code)) && resolveAwsError(error.message))
                || ((error instanceof CltpUnauthorizedException) && resolveCltpError(error.message))
                || DEFAULT_ERROR,
        }));

        return false;
    }

    return true;
}

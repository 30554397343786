import {put, select, takeLatest} from "redux-saga/effects";
import redirect from "../../redux/helpers/redirect";
import routes from "../../redux/routes";
import roles from "../../redux/roles";
import appStates from "../../redux/states/app";
import deltas from "../../redux/actions/deltas";
import respondToRoute from "../helpers/respondToRoute";

const {
    setAppState,
} = deltas.actionCreators;

export default function* loggedIn() {
    const role = yield select(state => state.role);

    const location = yield select(state => state.location);

    yield takeLatest(Object.values(routes), ({type: route}) => respondToRoute(route));

    let routeToRedirectTo = location.type;

    // If we just came from the login page, or the user has tried to go to the home page, or they just reloaded the page, instead redirect to the appropriate place
    if (routes.LOGIN === location.type || routes.HOME === location.type || routes.HOME === location.prev.type || '' === location.prev.type) {
        routeToRedirectTo = {
            [roles.CAREERS_LEAD]: routes.CL_PROFILE,
            [roles.PROVIDER]: routes.ENROLMENTS,
            [roles.CEC]: routes.ENROLMENTS,
        }[role];
    }

    yield put(redirect(routeToRedirectTo));

    yield put(setAppState(appStates.LOGGED_IN));
}

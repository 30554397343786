import deltas from "../actions/deltas";
import createGridReducer, {SORT_DESC} from "../helpers/createGridReducer";
import {allColumns} from "../../components/organisms/enrolments/PaymentsSection";
import {GRID_FILTER_TYPE_DATE} from "../../components/molecules/GridFilter";

const initialColumnsToHide = [
    'enrolmentId',
    'email',
    'cohortName',
];

const {
    SET_PAYMENTS,
    ADD_VALUE_TO_PAYMENTS_GRID_TEXT_FILTER,
    REMOVE_VALUE_FROM_PAYMENTS_GRID_TEXT_FILTER,
    CLEAR_PAYMENTS_GRID_TEXT_FILTER,
    CYCLE_PAYMENTS_GRID_COLUMN_SORT_STATE,
    SET_PAYMENTS_GRID_DATE_FILTER,
    CLEAR_PAYMENTS_GRID_DATE_FILTER,
    RESET_PAYMENTS_GRID_FILTER_AND_SORT,
    HIDE_PAYMENTS_GRID_COLUMN,
    SHOW_PAYMENTS_GRID_COLUMN,
    SHOW_ALL_PAYMENTS_GRID_COLUMNS,
    SET_PAYMENTS_GRID_ROW_SELECTED,
    UNSET_PAYMENTS_GRID_ROW_SELECTED,
    CLEAR_PAYMENTS_GRID_ROWS_SELECTED,
    SET_PAYMENTS_GRID_ALL_VISIBLE_ROWS_SELECTED,
} = deltas.actionTypes;

export const dateColumns = allColumns.filter(col => col.gridFilterType === GRID_FILTER_TYPE_DATE).map(col => col.accessor);

const initialSorters =
    [
        {
            column: 'contractSignedAt',
            direction: SORT_DESC,
        },
    ];

export default createGridReducer(
    [],
    initialSorters,
    initialColumnsToHide,
    dateColumns,
    SET_PAYMENTS,
    ADD_VALUE_TO_PAYMENTS_GRID_TEXT_FILTER,
    REMOVE_VALUE_FROM_PAYMENTS_GRID_TEXT_FILTER,
    CLEAR_PAYMENTS_GRID_TEXT_FILTER,
    CYCLE_PAYMENTS_GRID_COLUMN_SORT_STATE,
    SET_PAYMENTS_GRID_DATE_FILTER,
    CLEAR_PAYMENTS_GRID_DATE_FILTER,
    RESET_PAYMENTS_GRID_FILTER_AND_SORT,
    HIDE_PAYMENTS_GRID_COLUMN,
    SHOW_PAYMENTS_GRID_COLUMN,
    SHOW_ALL_PAYMENTS_GRID_COLUMNS,
    SET_PAYMENTS_GRID_ROW_SELECTED,
    UNSET_PAYMENTS_GRID_ROW_SELECTED,
    CLEAR_PAYMENTS_GRID_ROWS_SELECTED,
    SET_PAYMENTS_GRID_ALL_VISIBLE_ROWS_SELECTED,
);

import deltas from "../../actions/deltas";
import createReducer from "../../helpers/createReducer";

const {
    ADD_RETRYING_FAILED_PAYMENT_PROCESS_ID,
    REMOVE_RETRYING_FAILED_PAYMENT_PROCESS_ID,
    SET_FAILED_PAYMENT_PROCESSES,
} = deltas.actionTypes;

export default createReducer(
    [],
    {
        [SET_FAILED_PAYMENT_PROCESSES]: () => ([]),
        [ADD_RETRYING_FAILED_PAYMENT_PROCESS_ID]: (state, {id}) => ([
            ...new Set(
                [
                    ...state,
                    id,
                ]
            )
        ]),
        [REMOVE_RETRYING_FAILED_PAYMENT_PROCESS_ID]: (state, {id}) => state.filter((existingId) => id !== existingId),
    },
);

export const isRetryingFailedPaymentProcess = (state, id) => state.states.retryingFailedPaymentProcessIds.includes(id);

import createReducer from "../helpers/createReducer";
import deltas from "../actions/deltas";

const {
    SET_TOKEN,
    CLEAR_TOKEN,
} = deltas.actionTypes;

export default createReducer(null,
    {
        [SET_TOKEN]: (state, {token}) => token,
        [CLEAR_TOKEN]: () => null,
    },
);

import React, {useState} from "react";
import {connect} from "react-redux";
import signals from "../../../../redux/actions/signals";
import CourseInformationSection from "../CourseInformationSection";
import useEnrolmentsMappedAndOrderedMostRecentFirst from "../hooks/useEnrolmentsMappedAndOrderedMostRecentFirst";

const {
    openCourseInformationModal,
} = signals.actionCreators;

const mapStateToProps = state => ({
    profile: state.data.profile,
});

const mapDispatchToProps = {
    openCourseInformationModal,
};

const CourseInformationSectionContainer = ({profile, openCourseInformationModal}) => {
    const [openEnrolmentIndex, setOpenEnrolmentIndex] = useState(0);
    const enrolmentsMappedAndOrderedMostRecentFirst = useEnrolmentsMappedAndOrderedMostRecentFirst();

    return <CourseInformationSection
        profile={profile}
        openCourseInformationModal={openCourseInformationModal}
        openEnrolmentIndex={openEnrolmentIndex}
        setOpenEnrolmentIndex={setOpenEnrolmentIndex}
        enrolmentsMappedAndOrderedMostRecentFirst={enrolmentsMappedAndOrderedMostRecentFirst}
    />;
};

export default connect(mapStateToProps, mapDispatchToProps)(CourseInformationSectionContainer);

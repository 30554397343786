import React from 'react';
import ModalMessage from "../molecules/ModalMessage";
import FormContext from "../FormContext";
import AccountMultipleIcon from "mdi-react/AccountMultipleIcon";
import fields from "../../redux/fields/forgotPassword";
import inputs from "../molecules/inputsWithContextAndSurrounds";
import {connect} from "react-redux";
import signals from "../../redux/actions/signals";
import deltas from "../../redux/actions/deltas";
import states from "../../redux/states/forgotPasswordModal";

const {
    EmailInput,
} = inputs;

const {
    submitForgotPasswordForm,
    closeForgotPasswordModal,
} = signals.actionCreators;

const {
    updateForgotPasswordForm,
} = deltas.actionCreators;

const {
    OPEN_STATIC,
    REQUESTING,
    SUCCESS,
} = states;

const mapStateToProps = (state) => ({
    isOpen: [OPEN_STATIC, REQUESTING, SUCCESS].includes(state.states.forgotPasswordModal.state),
    isRequesting: REQUESTING === state.states.forgotPasswordModal.state,
    isSuccessful: SUCCESS === state.states.forgotPasswordModal.state,
    formState: state.forms.forgotPassword,
});

const mapDispatchToProps = {
    closeForgotPasswordModal,
    updateForgotPasswordForm,
    submitForgotPasswordForm,
};

const ForgotPasswordModal = ({isOpen, isRequesting, isSuccessful, closeForgotPasswordModal, formState, updateForgotPasswordForm, submitForgotPasswordForm}) =>
    <ModalMessage
        title={'Reset your password'}
        overrideSaveButtonText={
            isRequesting
                ? 'Sending...'
                : 'Send'
        }
        overrideCancelButtonText={
            isSuccessful
                ? 'Close'
                : 'Cancel'
        }
        isOpen={isOpen}
        closeModal={closeForgotPasswordModal}
        size={"md"}
        submitForm={isSuccessful ? null : submitForgotPasswordForm}
    >
      <FormContext.Provider value={{formState, updater: updateForgotPasswordForm}}>
          <p className="text-left">
              Enter your email address below and we will send you an email with a link to reset your password.
          </p>
        <div className="mb-3">
          <EmailInput
              id="o-email"
              data-cy="forgot-password--email"
              label="Your email"
              placeholder="Email"
              icon={<AccountMultipleIcon />}
              field={fields.EMAIL}
              required
              maxChars={60}
          />
        </div>
        {
          isSuccessful && <div className="alert alert-success">
            If an account with that email address exists, you will receive a link to reset your password. This verification code is valid for 1 hour and then it will expire.
          </div>
        }
      </FormContext.Provider>
    </ModalMessage>;
          {/*<form onSubmit={handleSubmit}>*/}
          {/*  <ModalHeader*/}
          {/*    toggle={() => toggle()}*/}
          {/*  >*/}
          {/*    Reset your password*/}
          {/*  </ModalHeader>*/}
          {/*  <ModalBody>*/}
          {/*    <p className="text-left">*/}
          {/*      Enter your email address below and we will send you an email with a link to reset your password.*/}
          {/*    </p>*/}
          {/*    /!*Email*!/*/}
          {/*    <div className="default-form text-left">*/}
          {/*      <div className="form-group row">*/}
          {/*        <div className="form-block col-12">*/}
          {/*          <TextInput*/}
          {/*            {...getFieldProps('email')}*/}
          {/*            id="reset-email"*/}
          {/*            placeholder="Email"*/}
          {/*            label="Your email"*/}
          {/*            error={errors.email}*/}
          {/*            required*/}
          {/*          />*/}
          {/*          <i className="mdi mdi-email-outline input-icon" />*/}
          {/*        </div>*/}
          {/*      </div>*/}
          {/*      ) : (*/}
          {/*        <>*/}
          {/*          {null != awsErrorMessage && (*/}
          {/*            <div className="alert alert-cec alert-cec-warning text-left fade-in-bottom">*/}
          {/*              {awsError(awsErrorMessage)}*/}
          {/*            </div>*/}
          {/*          )}*/}
          {/*        </>*/}
          {/*      )}*/}
          {/*    </div>*/}
          {/*  </ModalBody>*/}
          {/*  <ModalFooter>*/}
          {/*    <button*/}
          {/*      type="button"*/}
          {/*      className="cec-btn cec-btn-secondary-teal"*/}
          {/*      onClick={() => toggle()}*/}
          {/*    >*/}
          {/*      Close*/}
          {/*    </button>*/}
          {/*    <button*/}
          {/*      type="submit"*/}
          {/*      className="cec-btn cec-btn-primary-teal"*/}
          {/*      disabled={LOADING === callState || SUCCESS === callState}*/}
          {/*    >*/}
          {/*      {LOADING !== callState ? 'Send' : 'Sending...'}*/}
          {/*    </button>*/}
          {/*  </ModalFooter>*/}
          {/*</form>*/}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordModal);

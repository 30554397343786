import deltas from "../actions/deltas";
import createGridReducer, {SORT_DESC} from "../helpers/createGridReducer";

const initialColumnsToHide = [
    'entityId',
];

const {
    SET_FAILED_PAYMENT_PROCESSES,
    ADD_VALUE_TO_FAILED_PAYMENT_PROCESSES_GRID_TEXT_FILTER,
    REMOVE_VALUE_FROM_FAILED_PAYMENT_PROCESSES_GRID_TEXT_FILTER,
    CLEAR_FAILED_PAYMENT_PROCESSES_GRID_TEXT_FILTER,
    CYCLE_FAILED_PAYMENT_PROCESSES_GRID_COLUMN_SORT_STATE,
    SET_FAILED_PAYMENT_PROCESSES_GRID_DATE_FILTER,
    CLEAR_FAILED_PAYMENT_PROCESSES_GRID_DATE_FILTER,
    RESET_FAILED_PAYMENT_PROCESSES_GRID_FILTER_AND_SORT,
    HIDE_FAILED_PAYMENT_PROCESSES_GRID_COLUMN,
    SHOW_FAILED_PAYMENT_PROCESSES_GRID_COLUMN,
    SHOW_ALL_FAILED_PAYMENT_PROCESSES_GRID_COLUMNS,
    SET_FAILED_PAYMENT_PROCESSES_GRID_ROW_SELECTED,
    UNSET_FAILED_PAYMENT_PROCESSES_GRID_ROW_SELECTED,
    CLEAR_FAILED_PAYMENT_PROCESSES_GRID_ROWS_SELECTED,
    SET_FAILED_PAYMENT_PROCESSES_GRID_ALL_VISIBLE_ROWS_SELECTED,
} = deltas.actionTypes;

export const dateColumns = [
];

const initialSorters =
    [
        {
            column: 'failedAt',
            direction: SORT_DESC,
        },
    ];

export default createGridReducer(
    [],
    initialSorters,
    initialColumnsToHide,
    dateColumns,
    SET_FAILED_PAYMENT_PROCESSES,
    ADD_VALUE_TO_FAILED_PAYMENT_PROCESSES_GRID_TEXT_FILTER,
    REMOVE_VALUE_FROM_FAILED_PAYMENT_PROCESSES_GRID_TEXT_FILTER,
    CLEAR_FAILED_PAYMENT_PROCESSES_GRID_TEXT_FILTER,
    CYCLE_FAILED_PAYMENT_PROCESSES_GRID_COLUMN_SORT_STATE,
    SET_FAILED_PAYMENT_PROCESSES_GRID_DATE_FILTER,
    CLEAR_FAILED_PAYMENT_PROCESSES_GRID_DATE_FILTER,
    RESET_FAILED_PAYMENT_PROCESSES_GRID_FILTER_AND_SORT,
    HIDE_FAILED_PAYMENT_PROCESSES_GRID_COLUMN,
    SHOW_FAILED_PAYMENT_PROCESSES_GRID_COLUMN,
    SHOW_ALL_FAILED_PAYMENT_PROCESSES_GRID_COLUMNS,
    SET_FAILED_PAYMENT_PROCESSES_GRID_ROW_SELECTED,
    UNSET_FAILED_PAYMENT_PROCESSES_GRID_ROW_SELECTED,
    CLEAR_FAILED_PAYMENT_PROCESSES_GRID_ROWS_SELECTED,
    SET_FAILED_PAYMENT_PROCESSES_GRID_ALL_VISIBLE_ROWS_SELECTED,
);

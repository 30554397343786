import {put, select, take, call, fork, cancel} from "@redux-saga/core/effects";
import deltas from "../../../../../redux/actions/deltas";
import signals from "../../../../../redux/actions/signals";
import personalInformationFields from "../../../../../redux/fields/personalInformation";
import personalInformationModalStates from "../../../../../redux/states/personalInformationModal";
import getDataForForm from "../../../../helpers/getDataForForm";
import isFormDataClean from "../../../../helpers/isFormDataClean";
import getFieldValuesFromFormData from "../../../../helpers/getFieldValuesFromFormData";
import {updatePersonalInformation} from "../../../../../api";
import submitFormData from "../../../../helpers/submitFormData";
import notify from "../../../../helpers/notify";

const areYouSureMessage = 'Are you sure? You have unsaved changes.';
const failureMessage = 'There was a problem updating your personal information';

const {
    setPersonalInformationModalState,
    setPersonalInformationForm,
    setProfile,
    clearPersonalInformationForm,
} = deltas.actionCreators;

const {
    OPEN_STATIC,
    REQUESTING,
    CLOSED,
} = personalInformationModalStates;

const {
    SUBMIT_PERSONAL_INFORMATION_FORM,
    CLOSE_PERSONAL_INFORMATION_MODAL,
} = signals.actionTypes;

export default function* personalInformation() {
    const profile = yield select(state => state.data.profile);

    yield put(setPersonalInformationForm(getDataForForm(profile, personalInformationFields)));
    yield put(setPersonalInformationModalState(OPEN_STATIC));

    while (true) {
        const {type} = yield take([SUBMIT_PERSONAL_INFORMATION_FORM, CLOSE_PERSONAL_INFORMATION_MODAL]);
        const formData = yield select(state => state.forms.personalInformation);

        switch (type) {
            case SUBMIT_PERSONAL_INFORMATION_FORM:
                yield put(setPersonalInformationModalState(REQUESTING));

                if (formData._hasErrors) {
                    yield call(notify, 'warning', 'Please fix the form errors before continuing');
                    break;
                }

                const {currentEntity: profile, fieldErrors} = yield call(submitFormData, ...[updatePersonalInformation, formData, failureMessage]);

                if(!profile) {
                    break;
                }

                yield put(setProfile(profile));

                if (fieldErrors) {
                    yield put(setPersonalInformationForm(getFieldValuesFromFormData(formData), fieldErrors, false));
                    break;
                }

                yield put(setPersonalInformationModalState(CLOSED));
                yield put(clearPersonalInformationForm());
                return;
            case CLOSE_PERSONAL_INFORMATION_MODAL:
                if (isFormDataClean(formData) || window.confirm(areYouSureMessage)) {
                    yield put(clearPersonalInformationForm());
                    yield put(setPersonalInformationModalState(CLOSED));
                    return;
                }
                break;
            default:
        }

        yield put(setPersonalInformationModalState(OPEN_STATIC));
    }
}

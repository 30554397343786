import AlertMessage from "../../../atoms/AlertMessage";
import React from "react";

// TODO consider moving careersleaders@careersandenterprise.co.uk to an env or global variable
const IndependentSchoolAlertMessage = () => <div className="mb-4">
    <AlertMessage>
        <p className="mb-0">
            This is a private school, we do not currently fund training places for private schools.
            However, if you believe you should be entitled to a place please contact <a className="link-teal-to-gray" href="mailto:careersleaders@careersandenterprise.co.uk">careersleaders@careersandenterprise.co.uk</a>.
        </p>
    </AlertMessage>
</div>;

export default IndependentSchoolAlertMessage;

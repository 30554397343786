import enrolmentModalStates from "../../../../redux/states/enrolmentModal";
import React from "react";
import ModalMessage from '../../../molecules/ModalMessage';
import {connect} from "react-redux";
import EnrolmentModalPersonalInformationSection from "./sections/EnrolmentModalPersonalInformationSection";
import EnrolmentModalCourseInformationSection from "./sections/EnrolmentModalCourseInformationSection";
import EnrolmentModalStatusResultsSection from "./sections/EnrolmentModalStatusResultsSection";
import signals from "../../../../redux/actions/signals";
import FormContext from "../../../FormContext";
import roles from "../../../../redux/roles";
import deltas from "../../../../redux/actions/deltas";
import fields from "../../../../redux/fields/enrolment";
import {createSelector} from "reselect";
import dateObjectFromUkTextDate from "../../../../redux/helpers/dateObjectFromUkTextDate";
import {
    getAllowedStatuses,
    selectIsEnrolmentLockedDueToCompleted,
    selectIsEnrolmentLockedDueToReleased,
    selectIsEnrolmentLockedDueToContractSigned,
} from "../../../../redux/reducers/forms/enrolment";
import {selectEnrolments} from "../../../../redux/reducers/data/enrolments";

const {
    OPEN_STATIC,
    REQUESTING,
} = enrolmentModalStates;

const {
    closeEnrolmentModal,
    submitEnrolmentForm,
    openConfirmDeleteEnrolmentModal,
} = signals.actionCreators;

const {
    updateEnrolmentForm,
} = deltas.actionCreators;

const getIsCec = state => roles.CEC === state.role;
const getProviderId = state => state.provider && state.provider.id;
const getProviders = state => state.data.providers;
const getCourses = state => state.data.courses;
const getDeliveryTypes = state => state.data.deliveryTypes;
const getRegions = state => state.data.regions;
const getCohorts = state => state.data.cohorts;
const getFunds = state => state.data.funds;
const getEnrolmentFormCourseId = state => state.forms.enrolment[fields.COURSE_ID].value;
const getChosenCourseProviderId = state => getChosenCourse(state) && getChosenCourse(state).providerId;
const getChosenCourseProviderName = state => getChosenCourse(state) && getChosenCourse(state).providerName;
const selectDoesCareersLeadHaveOtherCompletedEnrolments = (state, enrolmentId) => {
    if (null === enrolmentId) {
        return false;
    }

    const enrolments = selectEnrolments(state);

    if (!enrolments.hasOwnProperty(enrolmentId)) {
        return false;
    }

    const thisEnrolment = enrolments[enrolmentId];

    const otherCompletedEnrolmentsWithSameCareersLeadId = Object.values(enrolments).filter(
        (enrolment) => enrolment.id !== thisEnrolment.id &&
            enrolment.careersLeadId === thisEnrolment.careersLeadId &&
            enrolment.isCompleted
    );

    return otherCompletedEnrolmentsWithSameCareersLeadId.length;
}
const getIsChosenCourseATopUpCourse = state => getChosenCourse(state) && getChosenCourse(state).isTopUp;
const getEnrolmentModalId = state => state.states.enrolmentModalId.id;
const getEnrolmentModalEnrolment = state => null != state.states.enrolmentModalId.id ? state.data.enrolments[state.states.enrolmentModalId.id] : null;
const getSchoolMembershipsOfCareersLead = (state, {enrolmentId}) => state.data.enrolments[enrolmentId].schoolMemberships;
const getSelectedContractSignedDate = state => state.forms.enrolment[fields.CONTRACT_SIGNED_AT].value &&
    dateObjectFromUkTextDate(state.forms.enrolment[fields.CONTRACT_SIGNED_AT].value);
const getEnrolmentClSchoolName = (state, {enrolmentId}) => state.data.enrolments[enrolmentId].schoolName;
const getEnrolmentClUrn = (state, {enrolmentId}) => state.data.enrolments[enrolmentId].schoolUrn;
const getEnrolmentClSchoolRegionName = (state, {enrolmentId}) => state.data.enrolments[enrolmentId].regionName;
const getEnrolmentClSchoolStartedAt = (state, {enrolmentId}) => state.data.enrolments[enrolmentId].schoolStartedAt;
const getEnrolmentClFirstName = (state, {enrolmentId}) => state.data.enrolments[enrolmentId].firstName;
const getEnrolmentClLastName = (state, {enrolmentId}) => state.data.enrolments[enrolmentId].lastName;
const getEnrolmentClEmail = (state, {enrolmentId}) => state.data.enrolments[enrolmentId].email;
const getEnrolmentDeliveryTypeId = state => null != state.states.enrolmentModalId.id ? state.data.enrolments[state.states.enrolmentModalId.id].deliveryTypeId : null;
const getEnrolmentCourseId = state => null != state.states.enrolmentModalId.id ? state.data.enrolments[state.states.enrolmentModalId.id].courseId : null;
const getEnrolmentFormStatus = state => state.forms.enrolment[fields.STATUS].value;
const hasCourseChanged = state => null != state.states.enrolmentModalId.id ? state.data.enrolments[state.states.enrolmentModalId.id].courseId !== state.forms.enrolment[fields.COURSE_ID].value : false;
const hasDeliveryTypeChanged = state => null != state.states.enrolmentModalId.id ? state.data.enrolments[state.states.enrolmentModalId.id].deliveryTypeId !== state.forms.enrolment[fields.DELIVERY_TYPE_ID].value : false;

export const getEnrolmentHasFixedTransactions = createSelector(
    [
        getEnrolmentModalEnrolment
    ],
    enrolment => null != enrolment && true === enrolment.hasFixedTransactions
);

const getEnrolmentProviderId = createSelector(
    [
        getEnrolmentModalEnrolment
    ],
    enrolment => null != enrolment ? enrolment.providerId : null
);

const getSchoolMembershipAtContractSignedDate = createSelector(
    [
        getSchoolMembershipsOfCareersLead,
        getSelectedContractSignedDate,
    ],
    (schoolMemberships, contractSignedDate) => {
        const schoolMembershipsMostRecentFirst = schoolMemberships
            .map(
                schoolMembership => ({
                    ...schoolMembership,
                    startedAt: dateObjectFromUkTextDate(schoolMembership.startedAt),
                })
            )
            .sort(
                (a,b) => b.startedAt.getTime() - a.startedAt.getTime()
            );

        return schoolMembershipsMostRecentFirst.find(
            schoolMembership => contractSignedDate > schoolMembership.startedAt
            )
        || { // Return this if nothing found
            urn: null,
            schoolName: null,
            schoolRegionName: null,
        };
    },
);

const getChosenCourse = createSelector(
    [
        getCourses,
        getEnrolmentFormCourseId,
    ],
    (courses, enrolmentFormCourseId) => courses[enrolmentFormCourseId]
);

const sortByLabel = (a, b) => String(a.label).localeCompare(String(b.label));

export const filterCourseOptions = (selectedCourseId, contractSignedDate, courseId, courseStartDate, courseEndDate) => {
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);

    if (selectedCourseId === parseInt(courseId)) {
        return true;
    }

    return (dateObjectFromUkTextDate(courseStartDate) <= (contractSignedDate || currentDate))
        && (
            null === courseEndDate
            || (dateObjectFromUkTextDate(courseEndDate) >= (contractSignedDate || currentDate))
        )
};

const getCourseOptions = createSelector(
    [
        getIsCec,
        getProviderId,
        getProviders,
        getCourses,
        getEnrolmentHasFixedTransactions,
        getEnrolmentProviderId,
        getSelectedContractSignedDate,
        getEnrolmentCourseId,
    ],
    (isCec, providerId, providers, courses, hasFixedTransactions, enrolmentProviderId, contractSignedDate, selectedCourseId) =>
        (isCec
            ? Object.entries(providers)
                .filter(([providerId]) => false === hasFixedTransactions || String(providerId) === String(enrolmentProviderId))
                .map(([providerId, {name: providerName}]) => ({
                    label: providerName,
                    options: Object.entries(courses)
                        .filter(([,course]) => String(providerId) === String(course.providerId))
                        .filter(([courseId, {startDate, endDate}]) => filterCourseOptions(selectedCourseId, contractSignedDate, courseId, startDate, endDate))
                        .map(([selectedCourseId, {name: courseName, isTopUp}]) => ({
                            label: courseName,
                            value: parseInt(selectedCourseId),
                            providerName: providerName,
                            isTopUp,
                        }))
                        .sort(sortByLabel),
                }))
                .sort(sortByLabel)
            : Object.entries(courses)
                .filter(([,course]) => providerId === course.providerId)
                .filter(([courseId, {startDate, endDate}]) => filterCourseOptions(selectedCourseId, contractSignedDate, courseId, startDate, endDate))
                .map(([id, {name}]) => ({
                    label: name,
                    value: parseInt(id),
                }))
                .sort(sortByLabel)
        )
);

const getDeliveryTypeOptions = createSelector(
    [
        getDeliveryTypes,
        getChosenCourseProviderId,
        getEnrolmentDeliveryTypeId,
        hasCourseChanged,
        hasDeliveryTypeChanged,
    ],
    (deliveryTypes, chosenCourseProviderId, enrolmentDeliveryTypeId, hasCourseChanged, hasDeliveryTypeChanged) =>
        chosenCourseProviderId
            ? Object.entries(deliveryTypes)
                .filter(([id, {providers}]) => (!hasDeliveryTypeChanged && !hasCourseChanged && parseInt(id) === enrolmentDeliveryTypeId) || providers.includes(chosenCourseProviderId))
                .map(([id, {name}]) => ({
                    label: name,
                    value: parseInt(id),
                }))
            : [],
);

const getRegionOptions = createSelector(
    [
        getChosenCourse,
        getRegions,
    ],
    (chosenCourse, regions) =>
        chosenCourse
            ? Object.entries(regions)
                .filter(([regionId]) => chosenCourse.regionIds.includes(parseInt(regionId)))
                .map(([id, {name}]) => ({
                    label: name,
                    value: parseInt(id),
                }))
            : [],
);

const getCohortOptions = createSelector(
    [
        getIsCec,
        getChosenCourseProviderId,
        getCohorts,
        getProviderId,
    ],
    (isCec, chosenCourseProviderId, cohorts, providerId) =>
        (isCec ? chosenCourseProviderId : true)
            ? Object.entries(cohorts)
                . filter(([,cohort]) => (isCec ? String(chosenCourseProviderId) : String(providerId)) === String(cohort.providerId))
                .map(([id, {name}]) => ({
                    label: name,
                    value: parseInt(id),
                }))
            : []
);

const getEnrolmentContractSignedDate = createSelector(
    [
        getEnrolmentModalEnrolment,
    ],
    enrolment => enrolment && enrolment.contractSignedAt && dateObjectFromUkTextDate(enrolment.contractSignedAt)
);

const getFundDateRangeFromContractSignedDate = createSelector(
    [
        getEnrolmentContractSignedDate,
        getFunds,
    ],
    (contractSignedDate, funds) => {
        for (const key in funds) {
            const fundStartDate = dateObjectFromUkTextDate(funds[key].startDate);
            let fundEndDate = null === funds[key].endDate
                ? null
                : dateObjectFromUkTextDate(funds[key].endDate);
            if (fundStartDate <= contractSignedDate && (null === fundEndDate || contractSignedDate < fundEndDate)) {
                if (null !== fundEndDate) {
                    fundEndDate.setDate(fundEndDate.getDate() - 1);
                }

                return {
                    startDate: fundStartDate,
                    endDate: fundEndDate,
                };
            }
        }
    }
)

const mapStateToProps = (state) => {
    return {
        isCec: getIsCec(state),
        chosenCourseProviderName: getChosenCourseProviderName(state),
        isChosenCourseATopUpCourse: getIsChosenCourseATopUpCourse(state),
        isEnrolmentModalOpen: [OPEN_STATIC, REQUESTING].includes(state.states.enrolmentModal.state),
        isEnrolmentModalRequesting: REQUESTING === state.states.enrolmentModal.state,
        formState: state.forms.enrolment,
        isCompleted: state.forms.enrolment[fields.IS_COMPLETED].value,
        courseOptions: getCourseOptions(state),
        deliveryTypeOptions: getDeliveryTypeOptions(state),
        regionOptions: getRegionOptions(state),
        cohortOptions: getCohortOptions(state),
        statusOptions: [],
        isContractSigned: null !== state.forms.enrolment[fields.CONTRACT_SIGNED_AT].value,
        contractSignedAt: state.forms.enrolment[fields.CONTRACT_SIGNED_AT].value,
        phone: state.forms.enrolment[fields.PHONE].value,
        signedUpAt: state.forms.enrolment[fields.SIGNED_UP_AT].value,
        loggedInProviderName: state.provider && state.provider.name,
        ...(getEnrolmentModalId(state) && {
                email: getEnrolmentClEmail(state, {enrolmentId: getEnrolmentModalId(state)}),
                firstName: getEnrolmentClFirstName(state, {enrolmentId: getEnrolmentModalId(state)}),
                lastName: getEnrolmentClLastName(state, {enrolmentId: getEnrolmentModalId(state)}),
                urn: getEnrolmentClUrn(state, {enrolmentId: getEnrolmentModalId(state)}),
                schoolName: getEnrolmentClSchoolName(state, {enrolmentId: getEnrolmentModalId(state)}),
                schoolRegionName: getEnrolmentClSchoolRegionName(state, {enrolmentId: getEnrolmentModalId(state)}),
                schoolStartedAt: getEnrolmentClSchoolStartedAt(state, {enrolmentId: getEnrolmentModalId(state)}),
                statusOptions: getAllowedStatuses(getEnrolmentFormStatus(state)).map(status => ({
                    label: status,
                    value: status,
                })),
                isEnrolmentLockedDueToReleased: selectIsEnrolmentLockedDueToReleased(state, getEnrolmentModalId(state)),
                isEnrolmentLockedDueToContractSigned: selectIsEnrolmentLockedDueToContractSigned(state, getEnrolmentModalId(state)),
                fundDateRangeFromContractSignedDate: getFundDateRangeFromContractSignedDate(state),
                hasFixedTransactions: getEnrolmentHasFixedTransactions(state),
        }),
        schoolAtContractSignedDate: getEnrolmentModalId(state) && getSelectedContractSignedDate(state)
        ? getSchoolMembershipAtContractSignedDate(state, {enrolmentId: getEnrolmentModalId(state)})
        : {
            urn: null,
            schoolName: null,
            schoolRegionName: null,
        },
        isEnrolmentLockedDueToCompleted: selectIsEnrolmentLockedDueToCompleted(state),
        careersLeadHasOtherCompletedEnrolments: selectDoesCareersLeadHaveOtherCompletedEnrolments(state, getEnrolmentModalId(state)),
    };
};

const mapDispatchToProps = {
    closeEnrolmentModal,
    submitEnrolmentForm,
    updateEnrolmentForm,
    openConfirmDeleteEnrolmentModal,
};

const EnrolmentModal = ({isEnrolmentModalOpen, closeEnrolmentModal, submitEnrolmentForm, isCec, formState, updateEnrolmentForm, courseOptions, deliveryTypeOptions, cohortOptions, isCompleted, statusOptions, isContractSigned, contractSignedAt, fundDateRangeFromContractSignedDate, hasFixedTransactions, regionOptions, chosenCourseProviderName, firstName, lastName, email, phone, urn, schoolName, schoolRegionName, signedUpAt, loggedInProviderName, schoolAtContractSignedDate, schoolStartedAt, openConfirmDeleteEnrolmentModal, isEnrolmentLockedDueToCompleted, isEnrolmentLockedDueToReleased, isEnrolmentLockedDueToContractSigned, isEnrolmentModalRequesting, isChosenCourseATopUpCourse, careersLeadHasOtherCompletedEnrolments}) =>
    <ModalMessage
        title="Edit the information of this enrolment"
        isOpen={isEnrolmentModalOpen}
        additionalButtons={
            isCec
                ? <button type="button" className="cec-btn cec-btn-secondary-red mr-auto" disabled={hasFixedTransactions} onClick={() => openConfirmDeleteEnrolmentModal()}>Delete Enrolment</button>
                : undefined
        }
        closeModal={closeEnrolmentModal}
        submitForm={submitEnrolmentForm}
        size={"lg"}
        isRequesting={isEnrolmentModalRequesting}
    >
        <div className="mx-n4 mx-md-n5">
            <FormContext.Provider value={{formState, updater: updateEnrolmentForm}}>
                {/* User & School & Organisation Information */}
                <EnrolmentModalPersonalInformationSection {...{isCec, firstName, lastName, email, phone, urn, schoolName, schoolRegionName, signedUpAt, isEnrolmentLockedDueToReleased}} />
                {/* Course Information */}
                <EnrolmentModalCourseInformationSection {...{isCec, courseOptions, chosenCourseProviderName, deliveryTypeOptions, cohortOptions, regionOptions, isCompleted, statusOptions, isContractSigned, contractSignedAt, fundDateRangeFromContractSignedDate, hasFixedTransactions, loggedInProviderName, schoolAtContractSignedDate, schoolStartedAt, isEnrolmentLockedDueToCompleted, isEnrolmentLockedDueToReleased, isEnrolmentLockedDueToContractSigned, isChosenCourseATopUpCourse, careersLeadHasOtherCompletedEnrolments}} />
                {/* Status & Results Information */}
                <EnrolmentModalStatusResultsSection {...{isCec, statusOptions, isContractSigned, isEnrolmentLockedDueToCompleted, isEnrolmentLockedDueToReleased}} />
            </FormContext.Provider>
        </div>
    </ModalMessage>;

export default connect(mapStateToProps, mapDispatchToProps)(EnrolmentModal);

import {fork, call, take, put, spawn, select} from "@redux-saga/core/effects";
import privacy from "./privacy";
import comms from "./comms";
import signals from "../../../../redux/actions/signals";
import personalInformation from "./modals/personalInformation";
import courseInformation from "./modals/courseInformation";
import schoolAndLogin from "./modals/schoolAndLogin";
import deltas from "../../../../redux/actions/deltas";
import personalInformationModalStates from "../../../../redux/states/personalInformationModal";
import courseInformationModalStates from "../../../../redux/states/courseInformationModal";
import schoolAndLoginModalStates from "../../../../redux/states/schoolAndLoginModal";
import notify from "../../../helpers/notify";
import endImpersonate from "../../endImpersonate";

const {
    OPEN_PERSONAL_INFORMATION_MODAL,
    OPEN_COURSE_INFORMATION_MODAL,
    OPEN_SCHOOL_AND_LOGIN_MODAL,
    TRY_TO_STOP_IMPERSONATING,
} = signals.actionTypes;

const {
    setPersonalInformationModalState,
    setCourseInformationModalState,
    setSchoolAndLoginModalState,
} = deltas.actionCreators;

const privacyNotAgreedMessage = 'You must agree to the CEC privacy notice before signing up for a course';

export default function* profile() {
    // Close modals, in case we are returning here after an error
    yield put(setPersonalInformationModalState(personalInformationModalStates.CLOSED));
    yield put(setCourseInformationModalState(courseInformationModalStates.CLOSED));
    yield put(setSchoolAndLoginModalState(schoolAndLoginModalStates.CLOSED));

    // Respond to and process privacy and comms "forms"
    yield fork(privacy);
    yield fork(comms);

    while (true) {
        const {type} = yield take([
            OPEN_PERSONAL_INFORMATION_MODAL,
            OPEN_COURSE_INFORMATION_MODAL,
            OPEN_SCHOOL_AND_LOGIN_MODAL,
            TRY_TO_STOP_IMPERSONATING,
        ]);

        switch (type) {
            case OPEN_PERSONAL_INFORMATION_MODAL:
                yield call(personalInformation);
                break;
            case OPEN_COURSE_INFORMATION_MODAL:
                const isPrivacyAgreed = yield select(state => state.data.profile.isPrivacyAgreed);
                if (!isPrivacyAgreed) {
                    yield spawn(notify, 'warning', privacyNotAgreedMessage);
                    break;
                }
                yield call(courseInformation);
                break;
            case OPEN_SCHOOL_AND_LOGIN_MODAL:
                yield call(schoolAndLogin);
                break;
            case TRY_TO_STOP_IMPERSONATING:
                yield call(endImpersonate);
                break;
            default:
        }
    }
}

import {call, put} from "@redux-saga/core/effects";
import request from "../../../helpers/request";
import deltas from "../../../../redux/actions/deltas";
import careersLeadSearchStates from "../../../../redux/states/careersLeadSearch";
import {searchCareersLeadByFullNameEmailInstitutionNameOrUrn} from "../../../../api";

const {
    setCareersLeadSearchResults,
    setCareersLeadSearchState,
} = deltas.actionCreators;

export default function* careersLeadSearch({query}) {
    if ('' === query) {
        return;
    }

    yield put (setCareersLeadSearchState(careersLeadSearchStates.REQUESTING));

    const result = yield call(
        request,
        searchCareersLeadByFullNameEmailInstitutionNameOrUrn,
        ...[
            {},
            {query},
        ]
    );

    const {response: {data} = {}} = result || {};

    if (data) {
        yield put(setCareersLeadSearchResults({
            results: data,
        }));
    }

    yield put (setCareersLeadSearchState(careersLeadSearchStates.STATIC));
}

import {take, spawn} from "@redux-saga/core/effects";
import privacyStates from "../../../../redux/states/privacy";
import signals from '../../../../redux/actions/signals'
import deltas from "../../../../redux/actions/deltas";
import updatePrivacy from "./updatePrivacy";

const {
    TOGGLE_IS_PRIVACY_AGREED,
} = signals.actionTypes;

const {
    SET_PRIVACY_STATE,
} = deltas.actionTypes;

export default function* privacy() {
    while (true) {
        yield take(TOGGLE_IS_PRIVACY_AGREED);
        yield spawn(updatePrivacy);

        while (true) {
            const {stateConstant} = yield take(SET_PRIVACY_STATE);
            if (privacyStates.STATIC === stateConstant) {
                break;
            }
        }
    }
}

import React from "react";
import routes from "../redux/routes";
import Error404 from "./pages/Error404";
import Login from "./pages/Login";
import CareersLeadProfile from "./pages/CareersLeadProfile";
import Enrolments from "./pages/Enrolments";
import Logout from "./pages/Logout";
import isRouteAllowed from "./isRouteAllowed";
import ResetPassword from "./pages/ResetPassword";

export default (route, role) => toJsx(findComponent(route, role));

const findComponent = (route, role) => ({
    [routes.LOGIN]: Login,
    [routes.RESET_PASSWORD]: ResetPassword,
    [routes.LOGOUT]: Logout,
    [routes.CL_PROFILE]: CareersLeadProfile,
    [routes.ENROLMENTS]: Enrolments,
}[
    isRouteAllowed(route, role) && route
] || Error404);

const toJsx = Component => <Component/>;

import createFormReducer from "../../helpers/createFormReducer";
import fields from "../../fields/applyCohort";
import deltas from "../../actions/deltas";

const {
    UPDATE_APPLY_COHORT_FORM,
    SET_APPLY_COHORT_FORM,
    CLEAR_APPLY_COHORT_FORM,
} = deltas.actionTypes;

export default createFormReducer(fields, UPDATE_APPLY_COHORT_FORM, SET_APPLY_COHORT_FORM, CLEAR_APPLY_COHORT_FORM);

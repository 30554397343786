import {connect} from "react-redux";
import Grid from "./Grid";
import deltas from "../../redux/actions/deltas";
import roles from "../../redux/roles";
import FailedPaymentProcessesActionsCell from "../molecules/enrolments/FailedPaymentProcessesActionsCell";
import FailedPaymentProcessesGridActionsHeader from "../molecules/FailedPaymentProcessesGridActionsHeader";
import {selectIsReloadingFailedPaymentProcesses} from "../../redux/reducers/states/reloadingFailedPaymentProcesses";

const {
    addValueToFailedPaymentProcessesGridTextFilter,
    removeValueFromFailedPaymentProcessesGridTextFilter,
    clearFailedPaymentProcessesGridTextFilter,
    cycleFailedPaymentProcessesGridColumnSortState,
    setFailedPaymentProcessesGridDateFilter,
    clearFailedPaymentProcessesGridDateFilter,
} = deltas.actionCreators;

const mapStateToProps = (state) => ({
    rowsFromRedux: state.failedPaymentProcessesGrid.filteredSortedData,
    selectedRows: state.failedPaymentProcessesGrid.selectedRows,
    gridStateSliceName: 'failedPaymentProcessesGrid',
    ActionsCell: FailedPaymentProcessesActionsCell,
    ActionsHeader: FailedPaymentProcessesGridActionsHeader,
    isCec: roles.CEC === state.role,
    isLoading: selectIsReloadingFailedPaymentProcesses(state),
});

const mapDispatchToProps = dispatch => ({
    addValueToTextFilter: addValueToFailedPaymentProcessesGridTextFilter,
    removeValueFromTextFilter: removeValueFromFailedPaymentProcessesGridTextFilter,
    clearTextFilter: clearFailedPaymentProcessesGridTextFilter,
    cycleColumnSortState: cycleFailedPaymentProcessesGridColumnSortState,
    setDateFilter: setFailedPaymentProcessesGridDateFilter,
    clearDateFilter: clearFailedPaymentProcessesGridDateFilter,
});

export default connect(mapStateToProps, mapDispatchToProps)(Grid);

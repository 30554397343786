export default (state, form) => ({
        ...Object.assign(
            ...Object.entries(state.forms[form])
                .filter(
                    ([key]) => '_' !== key[0]
                )
                .map(
                    ([key, fieldData]) => ({[key]: fieldData.value})
                )
        )
    });

import {connect} from "react-redux";

import signals from "../../redux/actions/signals";
import Grid from "./Grid";
import deltas from "../../redux/actions/deltas";
import CohortsActionsCell from "../molecules/enrolments/CohortsActionsCell";
import CohortsGridActionsHeader from "../molecules/CohortsGridActionsHeader";
import roles from "../../redux/roles";
import {selectIsReloadingEnrolmentsAndCohorts} from "../../redux/reducers/states/reloadingEnrolmentsAndCohorts";

const {
    addValueToCohortsGridTextFilter,
    removeValueFromCohortsGridTextFilter,
    clearCohortsGridTextFilter,
    cycleCohortsGridColumnSortState,
    setCohortsGridDateFilter,
    clearCohortsGridDateFilter,
} = deltas.actionCreators;

const {
    openCohortModal,
    tryToDeleteCohort,
} = signals.actionCreators;


const mapStateToProps = (state) => ({
    rowsFromRedux: state.cohortsGrid.filteredSortedData,
    gridStateSliceName: 'cohortsGrid',
    ActionsCell: CohortsActionsCell,
    ActionsHeader: CohortsGridActionsHeader,
    isCec: roles.CEC === state.role,
    isLoading: selectIsReloadingEnrolmentsAndCohorts(state),
});

const mapDispatchToProps = dispatch => ({
    openModal: id => dispatch(openCohortModal(id)),
    deleteRow: id => dispatch(tryToDeleteCohort(id)),
    addValueToTextFilter: addValueToCohortsGridTextFilter,
    removeValueFromTextFilter: removeValueFromCohortsGridTextFilter,
    clearTextFilter: clearCohortsGridTextFilter,
    cycleColumnSortState: cycleCohortsGridColumnSortState,
    setDateFilter: setCohortsGridDateFilter,
    clearDateFilter: clearCohortsGridDateFilter,
});

export default connect(mapStateToProps, mapDispatchToProps)(Grid);

import React from "react";
import classnames from 'classnames';
import Error from "../atoms/Error";
import Tippy from "@tippyjs/react";
import Warning from "./Warning";

export default Component => ({field, value, id, label, placeholder, icon, isDirty, updateForm, showError=true, error, errorTextToComponentReplacements, options, isGrouped, isMulti, formatOptionLabel, checkboxLabel, isClearable, required, maxChars, disabled, filterOption, onInputChange, isLoading, minDate, maxDate, warning, warningTooltip, ...rest}) => {
    return (
        <div className="form__group">
            {label && (
                <div className="form__label">
                    <label htmlFor={id}>{label} {required && <span style={{color: 'red'}}>*</span>}</label>
                </div>
            )}
            <Tippy
                content={warningTooltip}
                placement="top"
                animation="fade"
                arrow theme="light-border"
                disabled={!warningTooltip}
            >
                <span>
                    <div className={("form__container ") + (classnames({'form--no-icon' : !icon}))}>
                        <Component {...{value, id, placeholder, isDirty, updateForm, options, isGrouped, isMulti, formatOptionLabel, checkboxLabel, isClearable, disabled, filterOption, onInputChange, isLoading, minDate, maxDate, ...rest}}/>
                        {icon && (
                            <div className="form__icon" aria-hidden="true">
                                {icon}
                            </div>
                        )}
                    </div>
                    {
                        <div className="form__warning">
                            {warning && <Warning text={warning} />}
                        </div>
                    }
                </span>
            </Tippy>
            <div className="form__error">
                {showError && error && <Error {...{error, errorTextToComponentReplacements}}/>}
            </div>
        </div>
    );
}

import {take, spawn} from "@redux-saga/core/effects";
import commsStates from "../../../../redux/states/comms";
import signals from '../../../../redux/actions/signals'
import deltas from "../../../../redux/actions/deltas";
import updateComms from "./updateComms";

const {
    TOGGLE_IS_COMMS_AGREED,
} = signals.actionTypes;

const {
    SET_COMMS_STATE,
} = deltas.actionTypes;

export default function* privacy() {
    while (true) {
        yield take(TOGGLE_IS_COMMS_AGREED);
        yield spawn(updateComms);

        while (true) {
            const {stateConstant} = yield take(SET_COMMS_STATE);
            if (commsStates.STATIC === stateConstant) {
                break;
            }
        }
    }
}

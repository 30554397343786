import React from 'react';

const MutedText = ({text}) => {
    return (
        <p className="mb-0" style={{height: '38px', lineHeight: '20px', verticalAlign: 'middle'}}>
            <i className="text-muted">{text}</i>
        </p>
    )
}

export default MutedText;

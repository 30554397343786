import {call, put, select} from "@redux-saga/core/effects";
import request from "../helpers/request";
import {login} from "../../api";
import deltas from "../../redux/actions/deltas";
import roles from "../../redux/roles";

const {
    setRole,
} = deltas.actionCreators;

const {
    CAREERS_LEAD,
} = roles;

export class CltpUnauthorizedException extends Error {}

export default function* appLogin() {
    let {response: {status, data: {role, message}}} = yield call(request, login);

    if (401 === status) {
        throw new CltpUnauthorizedException(message);
    }

    const {isImpersonating} = yield select(state => state.impersonation);

    if (isImpersonating) {
        role = CAREERS_LEAD;
    }

    yield put(setRole(role));
}

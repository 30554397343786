import {useSelector} from "react-redux";
import {selectEnrolments} from "../../../../redux/reducers/data/enrolments";
import {selectProfile} from "../../../../redux/reducers/data/profile";
import {selectCourses} from "../../../../redux/reducers/data/courses";
import {selectProviders} from "../../../../redux/reducers/data/providers";
import {selectRegions} from "../../../../redux/reducers/data/regions";
import {selectDeliveryTypes} from "../../../../redux/reducers/data/deliveryTypes";

const useEnrolmentsMappedAndOrderedMostRecentFirst = () => {
    const enrolments = useSelector(selectEnrolments);
    const profile = useSelector(selectProfile);
    const courses = useSelector(selectCourses);
    const providers = useSelector(selectProviders);
    const regions = useSelector(selectRegions);
    const deliveryTypes = useSelector(selectDeliveryTypes);

    return profile.enrolmentIdsMostRecentFirst
        .map(
            mapEnrolment(courses, enrolments, providers, regions, deliveryTypes)
        )
        .filter(
            (enrolment) => null !== enrolment
        );
}

const mapEnrolment = (courses, enrolments, providers, regions, deliveryTypes) => (enrolmentId) => {
    const enrolment = enrolments[enrolmentId];

    if (!enrolment) {
        return null;
    }

    const course = courses[enrolment.courseId];
    const provider = providers[course.providerId];
    const region = regions[enrolment.regionId];
    const deliveryType = deliveryTypes[enrolment.deliveryTypeId];

    return {
        ...enrolment,
        courseName: course.name,
        providerName: provider.name,
        regionName: region.name,
        deliveryTypeName: deliveryType.name,
    };
};

export default useEnrolmentsMappedAndOrderedMostRecentFirst;

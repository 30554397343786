import createStateReducer from "../../helpers/createStateReducer";
import deltas from "../../actions/deltas";
import pollingPaymentProcessesStates from "../../states/pollingPaymentProcesses";
import React from "react";

const {SET_POLLING_PAYMENT_PROCESSES_STATE} = deltas.actionTypes;

export default createStateReducer(pollingPaymentProcessesStates, null, SET_POLLING_PAYMENT_PROCESSES_STATE);

export const selectPollingPaymentProcessesState = (state) => state.states.pollingPaymentProcesses.state;

export const selectIsPollingProcessesPending = (state) => selectPollingPaymentProcessesState(state) === pollingPaymentProcessesStates.POLLING_PROCESSES_PENDING;

export const selectIsProcessesPending = (state) => selectPollingPaymentProcessesState(state) === pollingPaymentProcessesStates.PROCESSES_PENDING || selectPollingPaymentProcessesState(state) === pollingPaymentProcessesStates.POLLING_PROCESSES_PENDING;

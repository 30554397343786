import React from "react";

import CheckIcon from "mdi-react/CheckIcon";
import Tippy from "@tippyjs/react";

const maxLabelChars = 40;

const reduceLabelIfNecessary = label => label.length > maxLabelChars ? `${label.substr(0, maxLabelChars)}...` : label;

export default ({id, name, label, type = "checkbox", checked = false, onClick = () => null}) =>
    <Tippy
        content={label}
        placement="top"
        animation="fade"
        arrow theme="light-border"
        appendTo={() => document.body}
        disabled={label.length < maxLabelChars}
    >
        <div className={("pretty p-has-focus ") + ('checkbox' === type ? 'p-icon' : 'p-round p-default')}>
            <input
                id={id}
                name={name}
                type={type}
                checked={checked}
                onClick={onClick}
            />
            <div className="state p-default-o">
                {'checkbox' === type && (
                    <CheckIcon className="icon" />
                )}
                <label htmlFor={id}>{reduceLabelIfNecessary(label)}</label>
            </div>
        </div>
    </Tippy>;

import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

toast.configure();

const toastSeconds = 12;

export default (message, type = 'success') => toast(message, {
        autoClose: toastSeconds * 1000,
        bodyClassName: 'alert-text',
        className: 'toast-notification bg-' + type,
        progressClassName: 'alert-progress'
    });

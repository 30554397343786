import createReducer from "../helpers/createReducer";
import deltas from "../actions/deltas";

const {
    SET_ROLE,
    CLEAR_ROLE,
} = deltas.actionTypes;

export default createReducer(null,
    {
        [SET_ROLE]: (state, {role}) => role,
        [CLEAR_ROLE]: () => null,
    },
);

import {spawn, put, call, delay} from "@redux-saga/core/effects";
import loggedOut from "./loggedOut";
import deltas from "../../redux/actions/deltas";
import notify from "../helpers/notify";
import loggedIn from "./loggedIn";
import {logout} from "../../auth";

const DELAY_WHILE_LOGGING_OUT_IN_MS = 1200;

const {
    clearToken,
    clearRole,
    clearProvider,
    clearEnrolments,
    clearCourses,
    clearCohorts,
    clearRegions,
    clearProviders,
    clearProfile,
    clearDeliveryTypes,
    clearPayments,
    clearFunds,
    clearFailedPaymentProcesses,
} = deltas.actionCreators;

const failureMessage = 'We were unable to log you out';

export default function* loggingOut() {
    try {
        yield call(logout);
    } catch (e) {
        yield spawn(notify, ...['warning', failureMessage]);

        yield call(loggedIn);

        return;
    }

    yield delay(DELAY_WHILE_LOGGING_OUT_IN_MS);

    yield put(clearToken());
    yield put(clearRole());
    yield put(clearProvider());
    yield put(clearEnrolments());
    yield put(clearCourses());
    yield put(clearCohorts());
    yield put(clearRegions());
    yield put(clearProviders());
    yield put(clearProfile());
    yield put(clearDeliveryTypes());
    yield put(clearPayments());
    yield put(clearFunds());
    yield put(clearFailedPaymentProcesses());

    yield call(loggedOut);
}

import gridsConfirmationModalTypeStates from "../../../../redux/states/gridsConfirmationModalType";
import React from "react";
import {GridsConfirmationModal} from "./GridsConfirmationModal";

const {
    DELETE_COHORT,
} = gridsConfirmationModalTypeStates;

export const DeleteCohortConfirmationModal = () =>
    <GridsConfirmationModal
        title={'Delete cohort'}
        saveButtonText={'Delete cohort'}
        type={DELETE_COHORT}
    >
        Are you sure you want to delete this cohort?
    </GridsConfirmationModal>;

import gridsConfirmationModalStates from "../../../../redux/states/gridsConfirmationModal";
import React from "react";
import ModalMessage from "../../../molecules/ModalMessage";
import {useDispatch, useSelector} from "react-redux";
import signals from "../../../../redux/actions/signals";

const {
    OPEN_STATIC,
    REQUESTING,
} = gridsConfirmationModalStates;

const {
    closeGridsConfirmationModal,
    submitGridsConfirmationModal,
} = signals.actionCreators;

export const GridsConfirmationModal = ({title, saveButtonText, type, children}) => {

    const isGridsConfirmationModalOpen = useSelector((state) => ([OPEN_STATIC, REQUESTING].includes(state.states.gridsConfirmationModal.state)));
    const openModalType = useSelector((state) => state.states.gridsConfirmationModalType.state);
    const dispatch = useDispatch();
    const closeModal = () => dispatch(closeGridsConfirmationModal());
    const submit = () => dispatch(submitGridsConfirmationModal());

    return <ModalMessage
            title={title}
            overrideSaveButtonText={saveButtonText}
            isOpen={isGridsConfirmationModalOpen && openModalType === type}
            closeModal={closeModal}
            size="md"
            submitForm={submit}
        >
            {/* Content */}
            {children}
        </ModalMessage>
    };

import React from "react";
import WelcomeSection from "../organisms/WelcomeSection";
import ImpersonationWrapper from "../organisms/ImpersonationWrapper";
import PersonalInformationSection from "../organisms/careers-lead-profile/PersonalInformationSection";
import PersonalInformationModal from "../organisms/careers-lead-profile/modals/PersonalInformationModal";
import CourseInformationModal from "../organisms/careers-lead-profile/modals/CourseInformationModal";
import SchoolAndLoginModal from "../organisms/careers-lead-profile/modals/SchoolAndLoginModal";
import CourseInformationSectionContainer
    from "../organisms/careers-lead-profile/containers/CourseInformationSectionContainer";

export default () =>
    <React.Fragment>
        {/* Modals */}
        <React.Fragment>
            {/* Personal Information */}
            <PersonalInformationModal />
            {/* Course Information */}
            <CourseInformationModal />
            {/* School and Login */}
            <SchoolAndLoginModal />
        </React.Fragment>
        {/* Content */}
        <React.Fragment>
            <ImpersonationWrapper>
                <WelcomeSection
                    title="Welcome to your profile page"
                    description={
                        <>
                            <p>
                                Welcome to the Careers Leader training portal, if you are unsure about what we offer please visit: <a className="link-teal-to-gray" href="https://www.careersandenterprise.co.uk/education/training-careers-leaders" target="_blank">https://www.careersandenterprise.co.uk/education/training-careers-leaders</a> where there is more information on the courses available to you.
                            </p>

                            <p>
                                The training is free of charge to those who complete it and you will also receive a £1000 bursary to cover staff costs at the end of the course. Please note that training is not free to any privately funded institutions.
                            </p>

                            <p className="mb-0">
                                Once you have selected your preferred course the chosen provider will contact you and make arrangements for you to start.
                            </p>
                        </>
                    }
                />
                <div className="my-4">
                    <div className="row flex-wrap">
                        <PersonalInformationSection />
                        <CourseInformationSectionContainer />
                    </div>
                </div>
            </ImpersonationWrapper>
        </React.Fragment>
    </React.Fragment>;

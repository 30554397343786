import {put, call, fork, take, debounce} from "@redux-saga/core/effects";
import signals from "../../../../redux/actions/signals";
import editEnrolment from "./modals/editEnrolment";
import downloadCsv from "./downloadCsv";
import createOrEditCohort from "./modals/createOrEditCohort";
import deleteCohort from "./modals/deleteCohort";
import confirmBulkAction from "./modals/confirmBulkAction";
import deltas from "../../../../redux/actions/deltas";
import gridsConfirmationModalStates from "../../../../redux/states/gridsConfirmationModalType";
import enrolmentModalStates from "../../../../redux/states/enrolmentModal";
import cohortModalStates from "../../../../redux/states/cohortModal";
import impersonate from "../../impersonate";
import careersLeadSearch from "./careersLeadSearch";
import {downloadExcel} from "./downloadExcel";
import {getAllTransactionsExcel, getFinanceReportExcel, getMonthlyClaimExcel} from "../../../../api";
import retryFailedPaymentProcess from "./retryFailedPaymentProcess";
import polling from "./polling";
import reloadFailedPaymentProcesses from "./reloadFailedPaymentProcesses";
import reloadEnrolmentsAndCohorts from "./reloadEnrolmentsAndCohorts";
import failedPaymentProcessesMoreInfo from "./modals/failedPaymentProcessesMoreInfo";

const {
    OPEN_ENROLMENT_MODAL,
    OPEN_COHORT_MODAL,
    OPEN_FAILED_PAYMENT_PROCESSES_MORE_INFO_MODAL,
    DOWNLOAD_ENROLMENTS_CSV,
    TRY_TO_MARK_CONTRACTS_AS_ISSUED,
    TRY_TO_MARK_COURSES_AS_COMPLETE,
    TRY_TO_APPLY_COHORT_TO_ENROLMENTS,
    TRY_TO_DELETE_COHORT,
    TRY_TO_START_IMPERSONATING,
    SEARCH_CAREERS_LEADS,
    DOWNLOAD_TRANSACTIONS,
    DOWNLOAD_MONTHLY_CLAIM,
    DOWNLOAD_FINANCE_REPORT,
    RETRY_FAILED_PAYMENT_PROCESS,
    RELOAD_FAILED_PAYMENT_PROCESSES,
    RELOAD_ENROLMENTS_AND_COHORTS,
} = signals.actionTypes;

const {
    setGridsConfirmationModalState,
    setEnrolmentModalState,
    setCohortModalState,
    setDownloadAllTransactionsState,
    setDownloadMonthlyClaimState,
    setDownloadFinanceReportState,
} = deltas.actionCreators;

const debounceMilliseconds = 200;

export default function* enrolments() {
    // Close modals, in case we are returning here after an error
    yield put(setGridsConfirmationModalState(gridsConfirmationModalStates.CLOSED));
    yield put(setEnrolmentModalState(enrolmentModalStates.CLOSED));
    yield put(setCohortModalState(cohortModalStates.CLOSED));

    yield debounce(debounceMilliseconds, [SEARCH_CAREERS_LEADS], careersLeadSearch);
    yield fork(polling);

    while (true) {
        const {type, id, year, month} = yield take([
            OPEN_ENROLMENT_MODAL,
            OPEN_COHORT_MODAL,
            OPEN_FAILED_PAYMENT_PROCESSES_MORE_INFO_MODAL,
            TRY_TO_MARK_CONTRACTS_AS_ISSUED,
            TRY_TO_MARK_COURSES_AS_COMPLETE,
            TRY_TO_APPLY_COHORT_TO_ENROLMENTS,
            TRY_TO_DELETE_COHORT,
            DOWNLOAD_ENROLMENTS_CSV,
            TRY_TO_START_IMPERSONATING,
            DOWNLOAD_TRANSACTIONS,
            DOWNLOAD_MONTHLY_CLAIM,
            DOWNLOAD_FINANCE_REPORT,
            RETRY_FAILED_PAYMENT_PROCESS,
            RELOAD_FAILED_PAYMENT_PROCESSES,
            RELOAD_ENROLMENTS_AND_COHORTS,
        ]);

        switch (type) {
            case TRY_TO_MARK_CONTRACTS_AS_ISSUED:
            case TRY_TO_MARK_COURSES_AS_COMPLETE:
            case TRY_TO_APPLY_COHORT_TO_ENROLMENTS:
                yield call(confirmBulkAction, type);
                break;
            case OPEN_ENROLMENT_MODAL:
                yield call(editEnrolment, id);
                break;
            case OPEN_COHORT_MODAL:
                yield call(createOrEditCohort, id);
                break;
            case OPEN_FAILED_PAYMENT_PROCESSES_MORE_INFO_MODAL:
                yield call(failedPaymentProcessesMoreInfo, id);
                break;
            case DOWNLOAD_ENROLMENTS_CSV:
                yield call(downloadCsv);
                break;
            case TRY_TO_DELETE_COHORT:
                yield call(deleteCohort, id);
                break;
            case TRY_TO_START_IMPERSONATING:
                yield call(impersonate);
                break;
            case DOWNLOAD_TRANSACTIONS:
                yield fork(downloadExcel, getAllTransactionsExcel, setDownloadAllTransactionsState, 'all-transactions.xlsx', {year, month});
                break;
            case DOWNLOAD_MONTHLY_CLAIM:
                yield fork(downloadExcel, getMonthlyClaimExcel, setDownloadMonthlyClaimState, 'monthly-claim.xlsx');
                break;
            case DOWNLOAD_FINANCE_REPORT:
                yield fork(downloadExcel, getFinanceReportExcel, setDownloadFinanceReportState, 'finance-report.xlsx');
                break;
            case RETRY_FAILED_PAYMENT_PROCESS:
                yield fork(retryFailedPaymentProcess, id);
                break;
            case RELOAD_FAILED_PAYMENT_PROCESSES:
                yield fork(reloadFailedPaymentProcesses);
                break;
            case RELOAD_ENROLMENTS_AND_COHORTS:
                yield fork(reloadEnrolmentsAndCohorts);
                break;
            default:
        }
    }
}

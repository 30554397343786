import CohortsGrid from "../CohortsGrid";
import React from "react";
import deltas from "../../../redux/actions/deltas";
import {connect} from "react-redux";
import signals from "../../../redux/actions/signals";
import roles from "../../../redux/roles";
import EnrolmentsAndCohortsPollingAlertMessage from "../../molecules/EnrolmentsAndCohortsPollingAlertMessage";

const allColumns = [
    { accessor: 'providerName', Header: 'Provider' },
    { accessor: 'name', Header: 'Name' },
    { accessor: 'description', Header: 'Description' },
    { accessor: 'enrolments', Header: 'Enrolments' },
];

const columnsProviderDoesNotSeeByKey = [
    'providerName',
];

const cecColumns = allColumns;
const providerColumns = allColumns.filter(column => !columnsProviderDoesNotSeeByKey.includes(column.accessor));

const {
    resetCohortsGridFilterAndSort,
} = deltas.actionCreators;

const {
    openCohortModal,
} = signals.actionCreators;

const mapStateToProps = state => {
    const role = state.role;
    const columns = (roles.CEC === role)
        ? cecColumns
        : providerColumns;

    return {
        columns,
    };
};

const mapDispatchToProps = dispatch => ({
    resetCohortsGridFilterAndSort: () => dispatch(resetCohortsGridFilterAndSort()),
    openCohortModal: () => dispatch(openCohortModal(null)),
});

const CohortsSection = ({resetCohortsGridFilterAndSort, openCohortModal, columns}) =>
    <React.Fragment>
        <EnrolmentsAndCohortsPollingAlertMessage />
        <div className="d-flex mb-3 mt-4">
            <div className="ml-auto">
                <button className="cec-btn cec-btn-secondary-teal mr-2" data-cy="cohorts--reset-table-button" onClick={resetCohortsGridFilterAndSort}>Reset table</button>
                <button className="cec-btn cec-btn-primary-teal" data-cy="create-cohorts-button" onClick={openCohortModal}>Create cohort</button>
            </div>
        </div>
        <CohortsGrid columns={columns}/>
    </React.Fragment>;

export default connect(mapStateToProps, mapDispatchToProps)(CohortsSection);

import {call, spawn} from "@redux-saga/core/effects";
import request from "./request";
import getFieldValuesFromFormData from "./getFieldValuesFromFormData";
import notify from "./notify";

export default function* submitFormData(apiFunction, formData, failureMessage, id) {
    const result = yield call(
        request,
        apiFunction,
        ...[
            {notificationOnError: {generateMessage: message => failureMessage}},
            getFieldValuesFromFormData(formData),
            id,
        ]
    );

    const {response: {data, data: {error, fieldErrors, currentEntity} = {}, headers} = {}} = result || {};

    if (error) {
        yield spawn(notify, ...['warning', 'Please correct the errors in the form']);
        return {fieldErrors, currentEntity}
    }

    return {currentEntity: data, headers};
}

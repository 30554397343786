import createFormReducer from "../../helpers/createFormReducer";
import fields from "../../fields/login";
import deltas from "../../actions/deltas";

const {
    UPDATE_LOGIN_FORM,
    SET_LOGIN_FORM,
    CLEAR_LOGIN_FORM,
} = deltas.actionTypes;

export default createFormReducer(fields, UPDATE_LOGIN_FORM, SET_LOGIN_FORM, CLEAR_LOGIN_FORM);

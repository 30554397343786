import React from 'react';
import FilterIcon from "../atoms/FilterIcon";
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light-border.css';
import {connect} from "react-redux";
import SortIcon from "../atoms/SortIcon";
import GridFilter from "./GridFilter";
import {dateColumns} from "../../redux/reducers/enrolmentsGrid";
import {createSelector} from "reselect";
import InformationVariantIcon from "mdi-react/InformationVariantIcon";

const getSorters = (state, {gridStateSliceName}) => state[gridStateSliceName].sorters;
const getFilters = (state, {gridStateSliceName}) => state[gridStateSliceName].filters;

const makeGetFilterOptions = columnName => createSelector(
    [
        (state, {gridStateSliceName}) => state[gridStateSliceName].data,
        getFilters,
    ],
    (data, filters) => [...new Set(data.map(row => row[columnName]))].map(value => ({
        value,
        selected: filters[columnName] && !dateColumns.includes(columnName)
            ? filters[columnName].includes(value)
            : false,
    })),
);

const makeGetSortDirection = columnName => createSelector(
    [
        getSorters,
    ],
    (sorters) => (sorters.find(sorter => columnName === sorter.column) || {direction: null})['direction'],
);

const makeGetSortOrder = columnName => createSelector(
    [
        getSorters,
    ],
    (sorters) => sorters.map(({column}) => column).indexOf(columnName) + 1,
);

const mapStateToProps = (state, {column: {id: columnName}}) => {
    // Memoized to improve performance (otherwise this component rerenders on every character input in modal)
    const getFilterOptions = makeGetFilterOptions(columnName);
    const getSortDirection = makeGetSortDirection(columnName);
    const getSortOrder = makeGetSortOrder(columnName);

    // Return a function in order to preserve memoized context of getters/selectors above
    return (state, {column, column: {id: columnName}, gridStateSliceName}) => ({
        column,
        // TODO improve this because it deals with text and date cases, it should only deal with one
        filterOptions: getFilterOptions(state, {gridStateSliceName}),
        filtersApplied: getFilters(state, {gridStateSliceName})[columnName],
        sortDirection: getSortDirection(state, {gridStateSliceName}),
        sortOrder: getSortOrder(state, {gridStateSliceName}),
    });
};

const mapDispatchToProps = (dispatch, {column: {id: columnName}, addValueToTextFilter, removeValueFromTextFilter, clearTextFilter, cycleColumnSortState, setDateFilter, clearDateFilter}) => ({
    addFilterValue: value => dispatch(addValueToTextFilter(columnName, value)),
    removeFilterValue: value => dispatch(removeValueFromTextFilter(columnName, value)),
    clearFilter: () => dispatch(clearTextFilter(columnName)),
    cycleSort: () => dispatch(cycleColumnSortState(columnName)),
    setDateFilter: range => dispatch(setDateFilter(columnName, range)),
    clearDateFilter: () => dispatch(clearDateFilter(columnName)),
});

const GridHeader = ({column, column: {id: columnName, disableFilters, gridFilterType}, filterOptions, addFilterValue, removeFilterValue, clearFilter, filtersApplied, sortDirection, sortOrder, cycleSort, setDateFilter, clearDateFilter}) => <React.Fragment>
    <span className="cell__sort">
        <SortIcon {...{sortDirection, sortOrder, cycleSort}}/>
    </span>
    <div className="cell__text cell__sort header-with-help">
        <span onClick={cycleSort} className="">
            {column.render('Header')}
        </span>
        {
            column.tooltipMessage && <span className="help-icon">
                <Tippy
                    content={column.tooltipMessage}
                    placement="top"
                    animation="fade"
                    arrow theme="light-border"
                    appendTo={() => document.body}
                >
                    <span>
                        <InformationVariantIcon/>
                    </span>
                </Tippy>
            </span>
        }
    </div>
    {!disableFilters &&
        <Tippy
            content={<GridFilter {...{filterOptions, addFilterValue, removeFilterValue, clearFilter, filtersApplied, setDateFilter, clearDateFilter, type: gridFilterType}}/>}
            placement="bottom"
            animation="fade"
            arrow={false}
            theme="light-border"
            trigger="click"
            interactive={true}
            appendTo={() => document.body}
        >
            <span className="cell__filter">
                <FilterIcon {...{filtersApplied, columnName}}/>
            </span>
        </Tippy>
    }
</React.Fragment>;

export default connect(mapStateToProps, mapDispatchToProps)(GridHeader);

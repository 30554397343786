import createDataReducer from "../../helpers/createDataReducer";
import deltas from "../../actions/deltas";

const {
    SET_CAREERS_LEAD_SEARCH_RESULTS,
    UPDATE_CAREERS_LEAD_SEARCH_RESULTS,
    CLEAR_CAREERS_LEAD_SEARCH_RESULTS,
} = deltas.actionTypes;

export default createDataReducer(
    SET_CAREERS_LEAD_SEARCH_RESULTS,
    UPDATE_CAREERS_LEAD_SEARCH_RESULTS,
    CLEAR_CAREERS_LEAD_SEARCH_RESULTS,
);

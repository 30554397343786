import React from "react";

export default ({value, id, placeholder, updateForm}) =>
    <input
        type={'email'}
        className="form__field"
        id={id}
        placeholder={placeholder}
        value={value}
        onChange={e => updateForm(e.target.value)}
    />;

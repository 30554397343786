import {call, put} from "@redux-saga/core/effects";
import request from "../../../helpers/request";
import sharedDownloadStates from "../../../../redux/states/sharedDownloadStates"

const {
    LOADING,
    NOT_LOADING,
} = sharedDownloadStates;

export function* downloadExcel(apiFunction, setDownloadState, nullFilename, queryParameters)
{
    yield put(setDownloadState(LOADING));

    const result = yield call(
        request,
        apiFunction,
        ...[
            {notificationOnError: {generateMessage: message => 'Error downloading Excel'}},
            undefined,
            undefined,
            queryParameters,
        ]
    );

    const {response, error} = result || {};

    if (error) {
        yield put(setDownloadState(NOT_LOADING));
        return;
    }

    let filename;

    const match = (response.headers['content-disposition'] || '').match(/.*filename=(?<filename>.*\.xlsx)(;|$)?/);

    if (null != match) {
        filename = match.groups.filename;
    } else {
        filename = nullFilename;
    }

    const url = window.URL.createObjectURL(response.data);
    const a = document.createElement('a');

    a.href = url;
    a.setAttribute('download', filename);
    a.click();

    yield put(setDownloadState(NOT_LOADING));
}

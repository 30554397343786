import createDataReducer from "../../helpers/createDataReducer";
import deltas from "../../actions/deltas";

const {
    SET_PROFILE,
    UPDATE_PROFILE,
    CLEAR_PROFILE,
} = deltas.actionTypes;

export const selectProfile = (state) => state.data.profile;

export default createDataReducer(
    SET_PROFILE,
    UPDATE_PROFILE,
    CLEAR_PROFILE,
);

import CheckboxField from "../../atoms/CheckboxField";
import React from "react";
import LoadingIcon from "mdi-react/LoadingIcon";
import {connect} from "react-redux";
import commsStates from "../../../redux/states/comms";

const {
    REQUESTING,
} = commsStates;

const mapStateToProps = state => ({
    isLoading: REQUESTING === state.states.comms.state,
});

const CommsPermission = ({isCommsAgreed, toggleIsCommsAgreed, isLoading}) => <div>
    <p className="font-14 text-muted mb-2 mt-4">Communications opt-in</p>
    {
        isLoading
        ? <LoadingIcon className="more__icon animation--spin"/>
        : <CheckboxField
                id="o-commsAgreement"
                label="I agree**"
                checked={isCommsAgreed}
                onClick={toggleIsCommsAgreed}
            />
    }
    <p className="font-12 text-muted mt-3 mb-0">
        ** Would you like to opt-in to our register of Careers Leaders? We will add your details to our list of Careers Leaders in England. We will use this list to keep you informed of future opportunities and policy developments in relation to your role by email and phone, and will not pass your details on to anyone outside the Careers & Enterprise Company.
    </p>
</div>

export default connect(mapStateToProps)(CommsPermission);

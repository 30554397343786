import ModalMessage from "../../../molecules/ModalMessage";
import React from "react";
import {connect} from "react-redux";
import signals from "../../../../redux/actions/signals";
import confirmReleaseEnrolmentModal from "../../../../redux/states/confirmReleaseEnrolmentModal";

const {
    confirmReleaseEnrolment,
    cancelReleaseEnrolment,
} = signals.actionCreators;

const {
    OPEN_STATIC,
    REQUESTING,
} = confirmReleaseEnrolmentModal;

const mapStateToProps = state => ({
    isConfirmReleaseEnrolmentModalOpen: [OPEN_STATIC, REQUESTING].includes(state.states.confirmReleaseEnrolmentModal.state),
});

const mapDispatchToProps = {
    confirmReleaseEnrolment,
    cancelReleaseEnrolment,
};

const ConfirmReleaseEnrolmentModal = ({isConfirmReleaseEnrolmentModalOpen, confirmReleaseEnrolment, cancelReleaseEnrolment}) =>
    <ModalMessage
        title="Confirm Release Enrolment"
        overrideSaveButtonText="Release enrolment"
        overrideCancelButtonText="Cancel"
        isOpen={isConfirmReleaseEnrolmentModalOpen}
        isRequesting={false}
        closeModal={cancelReleaseEnrolment}
        submitForm={confirmReleaseEnrolment}
        additionalClasses={"mt-5"}
    >
        <div className={`alert alert-cec-warning fade-in-bottom mb-0`}>
            <div>
                Once an enrolment is moved to ‘Released', you can no longer edit this enrolment and the Careers Leader will have to sign up again to your training if this status is incorrect.
            </div>
        </div>
        <div className="mt-4">
            Please confirm that you want to release this enrolment.
        </div>
    </ModalMessage>;

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmReleaseEnrolmentModal);

import createReducer from "./createReducer";

export default (update) => createReducer(
    {
        id: null,
    },
    {
        [update]: (state, {id}) => ({
            id: id,
        }),
    },
);

import {put, select, take, call, spawn} from "@redux-saga/core/effects";
import deltas from "../../../../../redux/actions/deltas";
import signals from "../../../../../redux/actions/signals";
import courseInformationFields from "../../../../../redux/fields/courseInformation";
import courseInformationModalStates from "../../../../../redux/states/courseInformationModal";
import getDataForForm from "../../../../helpers/getDataForForm";
import isFormDataClean from "../../../../helpers/isFormDataClean";
import getFieldValuesFromFormData from "../../../../helpers/getFieldValuesFromFormData";
import {getAllEnrolments, updateCourseInformation} from "../../../../../api";
import submitFormData from "../../../../helpers/submitFormData";
import fields from "../../../../../redux/fields/courseInformation";
import notify from "../../../../helpers/notify";
import validator from "../../../../../components/molecules/validator";
import request from "../../../../helpers/request";

const areYouSureMessage = 'Are you sure? You have unsaved changes.';
const failureMessage = 'There was a problem updating your course information';

const {
    setCourseInformationModalState,
    setCourseInformationForm,
    setProfile,
    setEnrolments,
    clearCourseInformationForm,
    updateCourseInformationForm,
} = deltas.actionCreators;

const {
    OPEN_STATIC,
    REQUESTING,
    CLOSED,
} = courseInformationModalStates;

const {
    SUBMIT_COURSE_INFORMATION_FORM,
    CLOSE_COURSE_INFORMATION_MODAL,
} = signals.actionTypes;

const {
    UPDATE_COURSE_INFORMATION_FORM
} = deltas.actionTypes;

export default function* courseInformation() {
    const profile = yield select(state => state.data.profile);

    yield put(setCourseInformationForm(getDataForForm(profile, courseInformationFields)));
    yield put(setCourseInformationModalState(OPEN_STATIC));

    while (true) {
        const {type, field} = yield take([SUBMIT_COURSE_INFORMATION_FORM, UPDATE_COURSE_INFORMATION_FORM, CLOSE_COURSE_INFORMATION_MODAL]);
        const formData = yield select(state => state.forms.courseInformation);

        switch (type) {
            case UPDATE_COURSE_INFORMATION_FORM:
                if (fields.CURRENT_ENROLMENT_PROVIDER_ID === field) {
                    yield put(updateCourseInformationForm(fields.CURRENT_ENROLMENT_COURSE_ID, null, validator(null, true)));
                    yield put(updateCourseInformationForm(fields.CURRENT_ENROLMENT_REGION_ID, null, validator(null, true)));
                    yield put(updateCourseInformationForm(fields.CURRENT_ENROLMENT_DELIVERY_TYPE_ID, null, validator(null, true)));
                }
                if (fields.CURRENT_ENROLMENT_COURSE_ID === field) {
                    yield put(updateCourseInformationForm(fields.CURRENT_ENROLMENT_REGION_ID, null, validator(null, true)));
                }
                break;
            case SUBMIT_COURSE_INFORMATION_FORM:
                yield put(setCourseInformationModalState(REQUESTING));

                if (formData._hasErrors) {
                    yield call(notify, 'warning', 'Please fix the form errors before continuing');
                    break;
                }

                const {currentEntity: profile, fieldErrors} = yield call(submitFormData, ...[updateCourseInformation, formData, failureMessage]);

                if(!profile) {
                    break;
                }

                yield put(setProfile(profile));

                const options = {
                    notificationOnFailure: {
                        generateMessage: message => `There was a problem reloading the enrolments data... Retrying...`,
                    },
                    attempts: 3,
                }
                const {response: {data: enrolments}} = yield call(request, ...[getAllEnrolments, options]);
                yield put(setEnrolments(enrolments));

                if (fieldErrors) {
                    yield put(setCourseInformationForm(getFieldValuesFromFormData(formData), fieldErrors, false));
                    break;
                }

                yield spawn(notify, ...['success', 'Thank you, you will shortly receive a confirmation email detailing the next steps']);

                yield put(setCourseInformationModalState(CLOSED));
                yield put(clearCourseInformationForm());
                return;
            case CLOSE_COURSE_INFORMATION_MODAL:
                if (isFormDataClean(formData) || window.confirm(areYouSureMessage)) {
                    yield put(clearCourseInformationForm());
                    yield put(setCourseInformationModalState(CLOSED));
                    return;
                }
            break;
            default:
        }
        yield put(setCourseInformationModalState(OPEN_STATIC));
    }
}

import DataLayout from "../../../atoms/careers-lead-profile/DataLayout";
import React from "react";
import {Collapse} from "reactstrap";

const EnrolmentInformationBox = ({mappedEnrolment, isOpen, setOpen, isMostRecent}) => {
    return <div className="bg-light border p-3 mb-2">
        <div onClick={setOpen} style={{cursor: 'pointer'}}>
            {
                isMostRecent
                    ? <b>Current Course</b>
                    : <b>Previous Course</b>
            }
            {
                !isOpen && <> - {mappedEnrolment.courseName} [{mappedEnrolment.status}]</>
            }
        </div>
        <Collapse isOpen={isOpen}>
                <div className="row mb-6 mt-3">
                    <DataLayout
                        item={{label: 'Course Name', value: mappedEnrolment.courseName}}
                    />
                    <DataLayout
                        item={{label: 'Enrolment Status', value: mappedEnrolment.status}}
                    />
                </div>
                <div className="row mb-6">
                    <DataLayout
                        item={{label: 'Contract Signed On', value: mappedEnrolment.contractSignedAt}}
                    />
                    {
                        mappedEnrolment.isComplete && <DataLayout
                            item={{label: 'Completed On', value: mappedEnrolment.completedAt}}
                        />
                    }
                </div>
                <div className="row mb-6">
                    <DataLayout
                        item={{label: 'This course is provided by', value: mappedEnrolment.providerName}}
                    />
                    <DataLayout
                        item={{label: 'For the region', value: mappedEnrolment.regionName}}
                    />
                </div>
                <div className="row mb-6">
                    <DataLayout
                        item={{label: 'Delivery type', value: mappedEnrolment.deliveryTypeName}}
                        marginBottom={0}
                    />
                </div>
        </Collapse>
    </div>
}

export default EnrolmentInformationBox;

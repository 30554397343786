import React from "react";

export default ({value, id, placeholder, updateForm, ...rest}) => {
    const dataCy = rest['data-cy'];
    return <input
        className="form__field"
        data-cy={dataCy}
        placeholder={placeholder}
        id={id} type={'text'}
        value={value}
        onChange={e => updateForm(e.target.value)}
    />;
}

import createActionCreator from "../helpers/createActionCreator";

const actionTypes = {
    // Token
    SET_TOKEN: 'SET_TOKEN',
    CLEAR_TOKEN: 'CLEAR_TOKEN',

    // Role
    SET_ROLE: 'SET_ROLE',
    CLEAR_ROLE: 'CLEAR_ROLE',

    // Provider
    SET_PROVIDER: 'SET_PROVIDER',
    CLEAR_PROVIDER: 'CLEAR_PROVIDER',

    // Forms
    UPDATE_LOGIN_FORM: 'UPDATE_LOGIN_FORM',
    SET_LOGIN_FORM: 'SET_LOGIN_FORM',
    CLEAR_LOGIN_FORM: 'CLEAR_LOGIN_FORM',

    UPDATE_FORGOT_PASSWORD_FORM: 'UPDATE_FORGOT_PASSWORD_FORM',
    SET_FORGOT_PASSWORD_FORM: 'SET_FORGOT_PASSWORD_FORM',
    CLEAR_FORGOT_PASSWORD_FORM: 'CLEAR_FORGOT_PASSWORD_FORM',

    UPDATE_RESET_PASSWORD_FORM: 'UPDATE_RESET_PASSWORD_FORM',
    SET_RESET_PASSWORD_FORM: 'SET_RESET_PASSWORD_FORM',
    CLEAR_RESET_PASSWORD_FORM: 'CLEAR_RESET_PASSWORD_FORM',

    UPDATE_PERSONAL_INFORMATION_FORM: 'UPDATE_PERSONAL_INFORMATION_FORM',
    SET_PERSONAL_INFORMATION_FORM: 'SET_PERSONAL_INFORMATION_FORM',
    CLEAR_PERSONAL_INFORMATION_FORM: 'CLEAR_PERSONAL_INFORMATION_FORM',

    UPDATE_COURSE_INFORMATION_FORM: 'UPDATE_COURSE_INFORMATION_FORM',
    SET_COURSE_INFORMATION_FORM: 'SET_COURSE_INFORMATION_FORM',
    CLEAR_COURSE_INFORMATION_FORM: 'CLEAR_COURSE_INFORMATION_FORM',

    UPDATE_SCHOOL_AND_LOGIN_FORM: 'UPDATE_SCHOOL_AND_LOGIN_FORM',
    SET_SCHOOL_AND_LOGIN_FORM: 'SET_SCHOOL_AND_LOGIN_FORM',
    CLEAR_SCHOOL_AND_LOGIN_FORM: 'CLEAR_SCHOOL_AND_LOGIN_FORM',

    UPDATE_COHORT_FORM: 'UPDATE_COHORT_FORM',
    SET_COHORT_FORM: 'SET_COHORT_FORM',
    CLEAR_COHORT_FORM: 'CLEAR_COHORT_FORM',

    UPDATE_ENROLMENT_FORM: 'UPDATE_ENROLMENT_FORM',
    SET_ENROLMENT_FORM: 'SET_ENROLMENT_FORM',
    CLEAR_ENROLMENT_FORM: 'CLEAR_ENROLMENT_FORM',

    UPDATE_APPLY_COHORT_FORM: 'UPDATE_APPLY_COHORT_FORM',
    SET_APPLY_COHORT_FORM: 'SET_APPLY_COHORT_FORM',
    CLEAR_APPLY_COHORT_FORM: 'CLEAR_APPLY_COHORT_FORM',

    // Enrolments Grid
    ADD_VALUE_TO_ENROLMENTS_GRID_TEXT_FILTER: 'ADD_VALUE_TO_ENROLMENTS_GRID_TEXT_FILTER',
    REMOVE_VALUE_FROM_ENROLMENTS_GRID_TEXT_FILTER: 'REMOVE_VALUE_FROM_ENROLMENTS_GRID_TEXT_FILTER',
    CLEAR_ENROLMENTS_GRID_TEXT_FILTER: 'CLEAR_ENROLMENT_GRID_TEXT_FILTER',
    CYCLE_ENROLMENTS_GRID_COLUMN_SORT_STATE: 'CYCLE_ENROLMENTS_GRID_COLUMN_SORT_STATE',
    SET_ENROLMENTS_GRID_DATE_FILTER: 'SET_ENROLMENTS_GRID_DATE_FILTER',
    CLEAR_ENROLMENTS_GRID_DATE_FILTER: 'CLEAR_ENROLMENTS_GRID_DATE_FILTER',
    RESET_ENROLMENTS_GRID_FILTER_AND_SORT: 'RESET_ENROLMENTS_GRID_FILTER_AND_SORT',
    HIDE_ENROLMENTS_GRID_COLUMN: 'HIDE_ENROLMENTS_GRID_COLUMN',
    SHOW_ENROLMENTS_GRID_COLUMN: 'SHOW_ENROLMENTS_GRID_COLUMN',
    SHOW_ALL_ENROLMENTS_GRID_COLUMNS: 'SHOW_ALL_ENROLMENTS_GRID_COLUMNS',
    SET_ENROLMENTS_GRID_ROW_SELECTED: 'SET_ENROLMENTS_GRID_ROW_SELECTED',
    UNSET_ENROLMENTS_GRID_ROW_SELECTED: 'UNSET_ENROLMENTS_GRID_ROW_SELECTED',
    CLEAR_ENROLMENTS_GRID_ROWS_SELECTED: 'CLEAR_ENROLMENTS_GRID_ROWS_SELECTED',
    SET_ENROLMENTS_GRID_ALL_VISIBLE_ROWS_SELECTED: 'SET_ENROLMENTS_GRID_ALL_VISIBLE_ROWS_SELECTED',

    // Cohorts Grid
    ADD_VALUE_TO_COHORTS_GRID_TEXT_FILTER: 'ADD_VALUE_TO_COHORTS_GRID_TEXT_FILTER',
    REMOVE_VALUE_FROM_COHORTS_GRID_TEXT_FILTER: 'REMOVE_VALUE_FROM_COHORTS_GRID_TEXT_FILTER',
    CLEAR_COHORTS_GRID_TEXT_FILTER: 'CLEAR_ENROLMENT_GRID_TEXT_FILTER',
    CYCLE_COHORTS_GRID_COLUMN_SORT_STATE: 'CYCLE_COHORTS_GRID_COLUMN_SORT_STATE',
    SET_COHORTS_GRID_DATE_FILTER: 'SET_COHORTS_GRID_DATE_FILTER',
    CLEAR_COHORTS_GRID_DATE_FILTER: 'CLEAR_COHORTS_GRID_DATE_FILTER',
    RESET_COHORTS_GRID_FILTER_AND_SORT: 'RESET_COHORTS_GRID_FILTER_AND_SORT',
    HIDE_COHORTS_GRID_COLUMN: 'HIDE_COHORTS_GRID_COLUMN',
    SHOW_COHORTS_GRID_COLUMN: 'SHOW_COHORTS_GRID_COLUMN',

    // Payments Grid
    ADD_VALUE_TO_PAYMENTS_GRID_TEXT_FILTER: 'ADD_VALUE_TO_PAYMENTS_GRID_TEXT_FILTER',
    REMOVE_VALUE_FROM_PAYMENTS_GRID_TEXT_FILTER: 'REMOVE_VALUE_FROM_PAYMENTS_GRID_TEXT_FILTER',
    CLEAR_PAYMENTS_GRID_TEXT_FILTER: 'CLEAR_ENROLMENT_GRID_TEXT_FILTER',
    CYCLE_PAYMENTS_GRID_COLUMN_SORT_STATE: 'CYCLE_PAYMENTS_GRID_COLUMN_SORT_STATE',
    SET_PAYMENTS_GRID_DATE_FILTER: 'SET_PAYMENTS_GRID_DATE_FILTER',
    CLEAR_PAYMENTS_GRID_DATE_FILTER: 'CLEAR_PAYMENTS_GRID_DATE_FILTER',
    RESET_PAYMENTS_GRID_FILTER_AND_SORT: 'RESET_PAYMENTS_GRID_FILTER_AND_SORT',
    HIDE_PAYMENTS_GRID_COLUMN: 'HIDE_PAYMENTS_GRID_COLUMN',
    SHOW_PAYMENTS_GRID_COLUMN: 'SHOW_PAYMENTS_GRID_COLUMN',
    SHOW_ALL_PAYMENTS_GRID_COLUMNS: 'SHOW_ALL_PAYMENTS_GRID_COLUMNS',

    // Failed Payments Grid
    ADD_VALUE_TO_FAILED_PAYMENT_PROCESSES_GRID_TEXT_FILTER: 'ADD_VALUE_TO_FAILED_PAYMENT_PROCESSES_GRID_TEXT_FILTER',
    REMOVE_VALUE_FROM_FAILED_PAYMENT_PROCESSES_GRID_TEXT_FILTER: 'REMOVE_VALUE_FROM_FAILED_PAYMENT_PROCESSES_GRID_TEXT_FILTER',
    CLEAR_FAILED_PAYMENT_PROCESSES_GRID_TEXT_FILTER: 'CLEAR_ENROLMENT_GRID_TEXT_FILTER',
    CYCLE_FAILED_PAYMENT_PROCESSES_GRID_COLUMN_SORT_STATE: 'CYCLE_FAILED_PAYMENT_PROCESSES_GRID_COLUMN_SORT_STATE',
    SET_FAILED_PAYMENT_PROCESSES_GRID_DATE_FILTER: 'SET_FAILED_PAYMENT_PROCESSES_GRID_DATE_FILTER',
    CLEAR_FAILED_PAYMENT_PROCESSES_GRID_DATE_FILTER: 'CLEAR_FAILED_PAYMENT_PROCESSES_GRID_DATE_FILTER',
    RESET_FAILED_PAYMENT_PROCESSES_GRID_FILTER_AND_SORT: 'RESET_FAILED_PAYMENT_PROCESSES_GRID_FILTER_AND_SORT',
    HIDE_FAILED_PAYMENT_PROCESSES_GRID_COLUMN: 'HIDE_FAILED_PAYMENT_PROCESSES_GRID_COLUMN',
    SHOW_FAILED_PAYMENT_PROCESSES_GRID_COLUMN: 'SHOW_FAILED_PAYMENT_PROCESSES_GRID_COLUMN',
    SHOW_ALL_FAILED_PAYMENT_PROCESSES_GRID_COLUMNS: 'SHOW_ALL_FAILED_PAYMENT_PROCESSES_GRID_COLUMNS',
    SET_FAILED_PAYMENT_PROCESSES_GRID_ROW_SELECTED: 'SET_FAILED_PAYMENT_PROCESSES_GRID_ROW_SELECTED',
    UNSET_FAILED_PAYMENT_PROCESSES_GRID_ROW_SELECTED: 'UNSET_FAILED_PAYMENT_PROCESSES_GRID_ROW_SELECTED',
    CLEAR_FAILED_PAYMENT_PROCESSES_GRID_ROWS_SELECTED: 'CLEAR_FAILED_PAYMENT_PROCESSES_GRID_ROWS_SELECTED',
    SET_FAILED_PAYMENT_PROCESSES_GRID_ALL_VISIBLE_ROWS_SELECTED: 'SET_FAILED_PAYMENT_PROCESSES_GRID_ALL_VISIBLE_ROWS_SELECTED',

    // Data
    SET_PROFILE: 'SET_PROFILE',
    UPDATE_PROFILE: 'UPDATE_PROFILE',
    CLEAR_PROFILE: 'CLEAR_PROFILE',

    SET_ENROLMENTS: 'SET_ENROLMENTS',
    UPDATE_ENROLMENT: 'UPDATE_ENROLMENT',
    CLEAR_ENROLMENTS: 'CLEAR_ENROLMENTS',

    SET_COHORTS: 'SET_COHORTS',
    UPDATE_COHORT: 'UPDATE_COHORT',
    DELETE_COHORT: 'DELETE_COHORT',
    CLEAR_COHORTS: 'CLEAR_COHORTS',

    SET_COURSES: 'SET_COURSES',
    UPDATE_COURSE: 'UPDATE_COURSE',
    CLEAR_COURSES: 'CLEAR_COURSES',

    SET_DELIVERY_TYPES: 'SET_DELIVERY_TYPES',
    UPDATE_DELIVERY_TYPE: 'UPDATE_DELIVERY_TYPE',
    CLEAR_DELIVERY_TYPES: 'CLEAR_DELIVERY_TYPES',

    SET_PAYMENTS: 'SET_PAYMENTS',
    UPDATE_PAYMENT: 'UPDATE_PAYMENT',
    CLEAR_PAYMENTS: 'CLEAR_PAYMENTS',

    SET_FAILED_PAYMENT_PROCESSES: 'SET_FAILED_PAYMENT_PROCESSES',
    UPDATE_FAILED_PAYMENT_PROCESS: 'UPDATE_FAILED_PAYMENT_PROCESS',
    CLEAR_FAILED_PAYMENT_PROCESSES: 'CLEAR_FAILED_PAYMENT_PROCESSES',
    DELETE_FAILED_PAYMENT_PROCESS: 'DELETE_FAILED_PAYMENT_PROCESS',

    SET_FUNDS: 'SET_FUNDS',
    UPDATE_FUND: 'UPDATE_FUND',
    CLEAR_FUNDS: 'CLEAR_FUNDS',

    SET_REGIONS: 'SET_REGIONS',
    UPDATE_REGION: 'UPDATE_REGION',
    CLEAR_REGIONS: 'CLEAR_REGIONS',

    SET_PROVIDERS: 'SET_PROVIDERS',
    UPDATE_PROVIDER: 'UPDATE_PROVIDER',
    CLEAR_PROVIDERS: 'CLEAR_PROVIDERS',

    SET_SCHOOL_SEARCH_RESULTS: 'SET_SCHOOL_SEARCH_RESULTS',
    UPDATE_SCHOOL_SEARCH_RESULTS: 'UPDATE_SCHOOL_SEARCH_RESULTS',
    CLEAR_SCHOOL_SEARCH_RESULTS: 'CLEAR_SCHOOL_SEARCH_RESULTS',

    SET_CAREERS_LEAD_SEARCH_RESULTS: 'SET_CAREERS_LEAD_SEARCH_RESULTS',
    UPDATE_CAREERS_LEAD_SEARCH_RESULTS: 'UPDATE_CAREERS_LEAD_SEARCH_RESULTS',
    CLEAR_CAREERS_LEAD_SEARCH_RESULTS: 'CLEAR_CAREERS_LEAD_SEARCH_RESULTS',

    // States
    SET_APP_STATE: 'SET_APP_STATE',
    SET_LOGIN_STATE: 'SET_LOGIN_STATE',
    SET_PRIVACY_STATE: 'SET_PRIVACY_STATE',
    SET_COMMS_STATE: 'SET_COMMS_STATE',
    SET_PERSONAL_INFORMATION_MODAL_STATE: 'SET_PERSONAL_INFORMATION_MODAL_STATE',
    SET_COURSE_INFORMATION_MODAL_STATE: 'SET_COURSE_INFORMATION_MODAL_STATE',
    SET_SCHOOL_AND_LOGIN_MODAL_STATE: 'SET_SCHOOL_AND_LOGIN_MODAL_STATE',
    SET_CONFIRM_DELETE_ENROLMENT_MODAL_STATE: 'SET_CONFIRM_DELETE_ENROLMENT_MODAL_STATE',
    SET_CONFIRM_RELEASE_ENROLMENT_MODAL_STATE: 'SET_CONFIRM_RELEASE_ENROLMENT_MODAL_STATE',
    SET_FAILED_PAYMENT_PROCESSES_MORE_INFO_MODAL_STATE: 'SET_FAILED_PAYMENT_PROCESSES_MORE_INFO_MODAL_STATE',
    SET_FAILED_PAYMENT_PROCESSES_MORE_INFO_MODAL_ID_STATE: 'SET_FAILED_PAYMENT_PROCESSES_MORE_INFO_MODAL_ID_STATE',
    SET_ENROLMENT_MODAL_STATE: 'SET_ENROLMENT_MODAL_STATE',
    SET_ENROLMENT_MODAL_ID_STATE: 'SET_ENROLMENT_MODAL_ID_STATE',
    SET_COHORT_MODAL_STATE: 'SET_COHORT_MODAL_STATE',
    SET_COHORT_MODAL_TYPE_STATE: 'SET_COHORT_MODAL_TYPE_STATE',
    SET_COHORT_MODAL_ID_STATE: 'SET_COHORT_MODAL_ID_STATE',
    SET_GRIDS_CONFIRMATION_MODAL_STATE: 'SET_GRIDS_CONFIRMATION_MODAL_STATE',
    SET_GRIDS_CONFIRMATION_MODAL_TYPE_STATE: 'SET_GRIDS_CONFIRMATION_MODAL_TYPE_STATE',
    SET_SCHOOL_SEARCH_STATE: 'SET_SCHOOL_SEARCH_STATE',
    SET_CAREERS_LEAD_SEARCH_STATE: 'SET_CAREERS_LEAD_SEARCH_STATE',
    SET_ENROLMENTS_PAGE_TAB_STATE: 'SET_ENROLMENTS_PAGE_TAB_STATE',
    SET_POLLING_PAYMENT_PROCESSES_STATE: 'SET_POLLING_PAYMENT_PROCESSES_STATE',
    SET_POLLING_FAILED_PAYMENT_PROCESSES_STATE: 'SET_POLLING_FAILED_PAYMENT_PROCESSES_STATE',
    SET_POLLING_ENROLMENTS_AND_COHORTS_STATE: 'SET_POLLING_ENROLMENTS_AND_COHORTS_STATE',
    SET_RELOADING_FAILED_PAYMENT_PROCESSES_STATE: 'SET_RELOADING_FAILED_PAYMENT_PROCESSES_STATE',
    SET_RELOADING_ENROLMENTS_AND_COHORTS_STATE: 'SET_RELOADING_ENROLMENTS_AND_COHORTS_STATE',
    SET_DOWNLOAD_ALL_TRANSACTIONS_STATE: 'SET_DOWNLOAD_ALL_TRANSACTIONS_STATE',
    SET_DOWNLOAD_MONTHLY_CLAIM_STATE: 'SET_DOWNLOAD_MONTHLY_CLAIM_STATE',
    SET_DOWNLOAD_FINANCE_REPORT_STATE: 'SET_DOWNLOAD_FINANCE_REPORT_STATE',
    SET_GRIDS_CONFIRMATION_MODAL_ERRORS: 'SET_GRIDS_CONFIRMATION_MODAL_ERRORS',
    UPDATE_GRIDS_CONFIRMATION_MODAL_ERRORS: 'UPDATE_GRIDS_CONFIRMATION_MODAL_ERRORS',
    CLEAR_GRIDS_CONFIRMATION_MODAL_ERRORS: 'CLEAR_GRIDS_CONFIRMATION_MODAL_ERRORS',
    SET_FORGOT_PASSWORD_MODAL_STATE: 'SET_FORGOT_PASSWORD_MODAL_STATE',
    SET_RESET_PASSWORD_STATE: 'SET_RESET_PASSWORD_STATE',

    ADD_RETRYING_FAILED_PAYMENT_PROCESS_ID: 'ADD_RETRYING_FAILED_PAYMENT_PROCESS_ID',
    REMOVE_RETRYING_FAILED_PAYMENT_PROCESS_ID: 'REMOVE_RETRYING_FAILED_PAYMENT_PROCESS_ID',

    // Notifications
    PUSH_NOTIFICATION: 'PUSH_NOTIFICATION',
    UNSHIFT_NOTIFICATION: 'UNSHIFT_NOTIFICATION',

    // Impersonation
    SET_USER_ID_TO_IMPERSONATE: 'SET_USER_ID_TO_IMPERSONATE',
    CLEAR_USER_ID_TO_IMPERSONATE: 'CLEAR_USER_ID_TO_IMPERSONATE',
    START_IMPERSONATING: 'START_IMPERSONATING',
    STOP_IMPERSONATING: 'STOP_IMPERSONATING',
};

const actionCreators = {
    // Token
    setToken: createActionCreator(actionTypes.SET_TOKEN, 'token'),
    clearToken: createActionCreator(actionTypes.CLEAR_TOKEN),

    // Role
    setRole: createActionCreator(actionTypes.SET_ROLE, 'role'),
    clearRole: createActionCreator(actionTypes.CLEAR_ROLE),

    // Provider
    setProvider: createActionCreator(actionTypes.SET_PROVIDER, 'data'),
    clearProvider: createActionCreator(actionTypes.CLEAR_PROVIDER),

    // Forms
    updateLoginForm: createActionCreator(actionTypes.UPDATE_LOGIN_FORM, 'field', 'value', 'error'),
    setLoginForm: createActionCreator(actionTypes.SET_LOGIN_FORM, 'data', 'errors', 'updateClean'),
    clearLoginForm: createActionCreator(actionTypes.CLEAR_LOGIN_FORM),

    updateForgotPasswordForm: createActionCreator(actionTypes.UPDATE_FORGOT_PASSWORD_FORM, 'field', 'value', 'error'),
    setForgotPasswordForm: createActionCreator(actionTypes.SET_FORGOT_PASSWORD_FORM, 'data', 'errors', 'updateClean'),
    clearForgotPasswordForm: createActionCreator(actionTypes.CLEAR_FORGOT_PASSWORD_FORM),

    updateResetPasswordForm: createActionCreator(actionTypes.UPDATE_RESET_PASSWORD_FORM, 'field', 'value', 'error'),
    setResetPasswordForm: createActionCreator(actionTypes.SET_RESET_PASSWORD_FORM, 'data', 'errors', 'updateClean'),
    clearResetPasswordForm: createActionCreator(actionTypes.CLEAR_RESET_PASSWORD_FORM),

    updatePersonalInformationForm: createActionCreator(actionTypes.UPDATE_PERSONAL_INFORMATION_FORM, 'field', 'value', 'error'),
    setPersonalInformationForm: createActionCreator(actionTypes.SET_PERSONAL_INFORMATION_FORM, 'data', 'errors', 'updateClean'),
    clearPersonalInformationForm: createActionCreator(actionTypes.CLEAR_PERSONAL_INFORMATION_FORM),

    updateCourseInformationForm: createActionCreator(actionTypes.UPDATE_COURSE_INFORMATION_FORM, 'field', 'value', 'error'),
    setCourseInformationForm: createActionCreator(actionTypes.SET_COURSE_INFORMATION_FORM, 'data', 'errors', 'updateClean'),
    clearCourseInformationForm: createActionCreator(actionTypes.CLEAR_COURSE_INFORMATION_FORM),

    updateSchoolAndLoginForm: createActionCreator(actionTypes.UPDATE_SCHOOL_AND_LOGIN_FORM, 'field', 'value', 'error'),
    setSchoolAndLoginForm: createActionCreator(actionTypes.SET_SCHOOL_AND_LOGIN_FORM, 'data', 'errors', 'updateClean'),
    clearSchoolAndLoginForm: createActionCreator(actionTypes.CLEAR_SCHOOL_AND_LOGIN_FORM),

    updateCohortForm: createActionCreator(actionTypes.UPDATE_COHORT_FORM, 'field', 'value', 'error'),
    setCohortForm: createActionCreator(actionTypes.SET_COHORT_FORM, 'data', 'errors', 'updateClean'),
    clearCohortForm: createActionCreator(actionTypes.CLEAR_COHORT_FORM),

    updateEnrolmentForm: createActionCreator(actionTypes.UPDATE_ENROLMENT_FORM, 'field', 'value', 'error'),
    setEnrolmentForm: createActionCreator(actionTypes.SET_ENROLMENT_FORM, 'data', 'errors', 'updateClean'),
    clearEnrolmentForm: createActionCreator(actionTypes.CLEAR_ENROLMENT_FORM),

    updateApplyCohortForm: createActionCreator(actionTypes.UPDATE_APPLY_COHORT_FORM, 'field', 'value', 'error'),
    setApplyCohortForm: createActionCreator(actionTypes.SET_APPLY_COHORT_FORM, 'data', 'errors', 'updateClean'),
    clearApplyCohortForm: createActionCreator(actionTypes.CLEAR_APPLY_COHORT_FORM),

    // Enrolments Grid
    addValueToEnrolmentsGridTextFilter: createActionCreator(actionTypes.ADD_VALUE_TO_ENROLMENTS_GRID_TEXT_FILTER, 'column', 'value'),
    removeValueFromEnrolmentsGridTextFilter: createActionCreator(actionTypes.REMOVE_VALUE_FROM_ENROLMENTS_GRID_TEXT_FILTER, 'column', 'value'),
    clearEnrolmentsGridTextFilter: createActionCreator(actionTypes.CLEAR_ENROLMENTS_GRID_TEXT_FILTER, 'column'),
    cycleEnrolmentsGridColumnSortState: createActionCreator(actionTypes.CYCLE_ENROLMENTS_GRID_COLUMN_SORT_STATE, 'column'),
    setEnrolmentsGridDateFilter: createActionCreator(actionTypes.SET_ENROLMENTS_GRID_DATE_FILTER, 'column', 'range'),
    clearEnrolmentsGridDateFilter: createActionCreator(actionTypes.CLEAR_ENROLMENTS_GRID_DATE_FILTER, 'column'),
    resetEnrolmentsGridFilterAndSort: createActionCreator(actionTypes.RESET_ENROLMENTS_GRID_FILTER_AND_SORT),
    hideEnrolmentsGridColumn: createActionCreator(actionTypes.HIDE_ENROLMENTS_GRID_COLUMN, 'column'),
    showEnrolmentsGridColumn: createActionCreator(actionTypes.SHOW_ENROLMENTS_GRID_COLUMN, 'column'),
    showAllEnrolmentsGridColumns: createActionCreator(actionTypes.SHOW_ALL_ENROLMENTS_GRID_COLUMNS),
    setEnrolmentsGridRowSelected: createActionCreator(actionTypes.SET_ENROLMENTS_GRID_ROW_SELECTED, 'id'),
    unsetEnrolmentsGridRowSelected: createActionCreator(actionTypes.UNSET_ENROLMENTS_GRID_ROW_SELECTED, 'id'),
    clearEnrolmentsGridRowsSelected: createActionCreator(actionTypes.CLEAR_ENROLMENTS_GRID_ROWS_SELECTED, 'id'),
    setEnrolmentsGridAllVisibleRowsSelected: createActionCreator(actionTypes.SET_ENROLMENTS_GRID_ALL_VISIBLE_ROWS_SELECTED),

    // Cohorts Grid
    addValueToCohortsGridTextFilter: createActionCreator(actionTypes.ADD_VALUE_TO_COHORTS_GRID_TEXT_FILTER, 'column', 'value'),
    removeValueFromCohortsGridTextFilter: createActionCreator(actionTypes.REMOVE_VALUE_FROM_COHORTS_GRID_TEXT_FILTER, 'column', 'value'),
    clearCohortsGridTextFilter: createActionCreator(actionTypes.CLEAR_COHORTS_GRID_TEXT_FILTER, 'column'),
    cycleCohortsGridColumnSortState: createActionCreator(actionTypes.CYCLE_COHORTS_GRID_COLUMN_SORT_STATE, 'column'),
    setCohortsGridDateFilter: createActionCreator(actionTypes.SET_COHORTS_GRID_DATE_FILTER, 'column', 'range'),
    clearCohortsGridDateFilter: createActionCreator(actionTypes.CLEAR_COHORTS_GRID_DATE_FILTER, 'column'),
    resetCohortsGridFilterAndSort: createActionCreator(actionTypes.RESET_COHORTS_GRID_FILTER_AND_SORT),
    hideCohortsGridColumn: createActionCreator(actionTypes.HIDE_COHORTS_GRID_COLUMN, 'column'),
    showCohortsGridColumn: createActionCreator(actionTypes.SHOW_COHORTS_GRID_COLUMN, 'column'),

    // Payments Grid
    addValueToPaymentsGridTextFilter: createActionCreator(actionTypes.ADD_VALUE_TO_PAYMENTS_GRID_TEXT_FILTER, 'column', 'value'),
    removeValueFromPaymentsGridTextFilter: createActionCreator(actionTypes.REMOVE_VALUE_FROM_PAYMENTS_GRID_TEXT_FILTER, 'column', 'value'),
    clearPaymentsGridTextFilter: createActionCreator(actionTypes.CLEAR_PAYMENTS_GRID_TEXT_FILTER, 'column'),
    cyclePaymentsGridColumnSortState: createActionCreator(actionTypes.CYCLE_PAYMENTS_GRID_COLUMN_SORT_STATE, 'column'),
    setPaymentsGridDateFilter: createActionCreator(actionTypes.SET_PAYMENTS_GRID_DATE_FILTER, 'column', 'range'),
    clearPaymentsGridDateFilter: createActionCreator(actionTypes.CLEAR_PAYMENTS_GRID_DATE_FILTER, 'column'),
    resetPaymentsGridFilterAndSort: createActionCreator(actionTypes.RESET_PAYMENTS_GRID_FILTER_AND_SORT),
    hidePaymentsGridColumn: createActionCreator(actionTypes.HIDE_PAYMENTS_GRID_COLUMN, 'column'),
    showPaymentsGridColumn: createActionCreator(actionTypes.SHOW_PAYMENTS_GRID_COLUMN, 'column'),
    showAllPaymentsGridColumns: createActionCreator(actionTypes.SHOW_ALL_PAYMENTS_GRID_COLUMNS),

    // Failed Payments Grid
    addValueToFailedPaymentProcessesGridTextFilter: createActionCreator(actionTypes.ADD_VALUE_TO_FAILED_PAYMENT_PROCESSES_GRID_TEXT_FILTER, 'column', 'value'),
    removeValueFromFailedPaymentProcessesGridTextFilter: createActionCreator(actionTypes.REMOVE_VALUE_FROM_FAILED_PAYMENT_PROCESSES_GRID_TEXT_FILTER, 'column', 'value'),
    clearFailedPaymentProcessesGridTextFilter: createActionCreator(actionTypes.CLEAR_FAILED_PAYMENT_PROCESSES_GRID_TEXT_FILTER, 'column'),
    cycleFailedPaymentProcessesGridColumnSortState: createActionCreator(actionTypes.CYCLE_FAILED_PAYMENT_PROCESSES_GRID_COLUMN_SORT_STATE, 'column'),
    setFailedPaymentProcessesGridDateFilter: createActionCreator(actionTypes.SET_FAILED_PAYMENT_PROCESSES_GRID_DATE_FILTER, 'column', 'range'),
    clearFailedPaymentProcessesGridDateFilter: createActionCreator(actionTypes.CLEAR_FAILED_PAYMENT_PROCESSES_GRID_DATE_FILTER, 'column'),
    resetFailedPaymentProcessesGridFilterAndSort: createActionCreator(actionTypes.RESET_FAILED_PAYMENT_PROCESSES_GRID_FILTER_AND_SORT),
    hideFailedPaymentProcessesGridColumn: createActionCreator(actionTypes.HIDE_FAILED_PAYMENT_PROCESSES_GRID_COLUMN, 'column'),
    showFailedPaymentProcessesGridColumn: createActionCreator(actionTypes.SHOW_FAILED_PAYMENT_PROCESSES_GRID_COLUMN, 'column'),
    showAllFailedPaymentProcessesGridColumns: createActionCreator(actionTypes.SHOW_ALL_FAILED_PAYMENT_PROCESSES_GRID_COLUMNS),
    setFailedPaymentProcessesGridRowSelected: createActionCreator(actionTypes.SET_FAILED_PAYMENT_PROCESSES_GRID_ROW_SELECTED, 'id'),
    unsetFailedPaymentProcessesGridRowSelected: createActionCreator(actionTypes.UNSET_FAILED_PAYMENT_PROCESSES_GRID_ROW_SELECTED, 'id'),
    clearFailedPaymentProcessesGridRowsSelected: createActionCreator(actionTypes.CLEAR_FAILED_PAYMENT_PROCESSES_GRID_ROWS_SELECTED, 'id'),
    setFailedPaymentProcessesGridAllVisibleRowsSelected: createActionCreator(actionTypes.SET_FAILED_PAYMENT_PROCESSES_GRID_ALL_VISIBLE_ROWS_SELECTED),

    // Data
    setProfile: createActionCreator(actionTypes.SET_PROFILE, 'data'),
    clearProfile: createActionCreator(actionTypes.CLEAR_PROFILE),

    setEnrolments: createActionCreator(actionTypes.SET_ENROLMENTS, 'data'),
    updateEnrolment: createActionCreator(actionTypes.UPDATE_ENROLMENT, 'id', 'data'),
    clearEnrolments: createActionCreator(actionTypes.CLEAR_ENROLMENTS),

    setCohorts: createActionCreator(actionTypes.SET_COHORTS, 'data'),
    updateCohort: createActionCreator(actionTypes.UPDATE_COHORT, 'id', 'data'),
    deleteCohort: createActionCreator(actionTypes.DELETE_COHORT, 'id'),
    clearCohorts: createActionCreator(actionTypes.CLEAR_COHORTS),

    setCourses: createActionCreator(actionTypes.SET_COURSES, 'data'),
    updateCourse: createActionCreator(actionTypes.UPDATE_COURSE, 'id', 'data'),
    clearCourses: createActionCreator(actionTypes.CLEAR_COURSES),

    setDeliveryTypes: createActionCreator(actionTypes.SET_DELIVERY_TYPES, 'data'),
    updateDeliveryType: createActionCreator(actionTypes.UPDATE_DELIVERY_TYPE, 'id', 'data'),
    clearDeliveryTypes: createActionCreator(actionTypes.CLEAR_DELIVERY_TYPES),

    setPayments: createActionCreator(actionTypes.SET_PAYMENTS, 'data'),
    updatePayment: createActionCreator(actionTypes.UPDATE_PAYMENT, 'id', 'data'),
    clearPayments: createActionCreator(actionTypes.CLEAR_PAYMENTS),

    setFailedPaymentProcesses: createActionCreator(actionTypes.SET_FAILED_PAYMENT_PROCESSES, 'data'),
    updateFailedPayment: createActionCreator(actionTypes.UPDATE_FAILED_PAYMENT_PROCESS, 'id', 'data'),
    clearFailedPaymentProcesses: createActionCreator(actionTypes.CLEAR_FAILED_PAYMENT_PROCESSES),
    deleteFailedPaymentProcess: createActionCreator(actionTypes.DELETE_FAILED_PAYMENT_PROCESS, 'id'),

    setFunds: createActionCreator(actionTypes.SET_FUNDS, 'data'),
    updateFund: createActionCreator(actionTypes.UPDATE_FUND, 'id', 'data'),
    clearFunds: createActionCreator(actionTypes.CLEAR_FUNDS),

    setRegions: createActionCreator(actionTypes.SET_REGIONS, 'data'),
    updateRegion: createActionCreator(actionTypes.UPDATE_REGION, 'id', 'data'),
    clearRegions: createActionCreator(actionTypes.CLEAR_REGIONS),

    setProviders: createActionCreator(actionTypes.SET_PROVIDERS, 'data'),
    updateProvider: createActionCreator(actionTypes.UPDATE_PROVIDER, 'id', 'data'),
    clearProviders: createActionCreator(actionTypes.CLEAR_PROVIDERS),

    setSchoolSearchResults: createActionCreator(actionTypes.SET_SCHOOL_SEARCH_RESULTS, 'data'),
    updateSchoolSearchResults: createActionCreator(actionTypes.UPDATE_SCHOOL_SEARCH_RESULTS, 'id', 'data'),
    clearSchoolSearchResults: createActionCreator(actionTypes.CLEAR_SCHOOL_SEARCH_RESULTS),

    setCareersLeadSearchResults: createActionCreator(actionTypes.SET_CAREERS_LEAD_SEARCH_RESULTS, 'data'),
    updateCareersLeadSearchResults: createActionCreator(actionTypes.UPDATE_CAREERS_LEAD_SEARCH_RESULTS, 'id', 'data'),
    clearCareersLeadSearchResults: createActionCreator(actionTypes.CLEAR_CAREERS_LEAD_SEARCH_RESULTS),

    // States
    setAppState: createActionCreator(actionTypes.SET_APP_STATE, 'stateConstant'),
    setLoginState: createActionCreator(actionTypes.SET_LOGIN_STATE, 'stateConstant'),
    setPrivacyState: createActionCreator(actionTypes.SET_PRIVACY_STATE, 'stateConstant'),
    setCommsState: createActionCreator(actionTypes.SET_COMMS_STATE, 'stateConstant'),
    setPersonalInformationModalState: createActionCreator(actionTypes.SET_PERSONAL_INFORMATION_MODAL_STATE, 'stateConstant'),
    setCourseInformationModalState: createActionCreator(actionTypes.SET_COURSE_INFORMATION_MODAL_STATE, 'stateConstant'),
    setSchoolAndLoginModalState: createActionCreator(actionTypes.SET_SCHOOL_AND_LOGIN_MODAL_STATE, 'stateConstant'),
    setConfirmDeleteEnrolmentModalState: createActionCreator(actionTypes.SET_CONFIRM_DELETE_ENROLMENT_MODAL_STATE, 'stateConstant'),
    setConfirmReleaseEnrolmentModalState: createActionCreator(actionTypes.SET_CONFIRM_RELEASE_ENROLMENT_MODAL_STATE, 'stateConstant'),
    setFailedPaymentProcessesMoreInfoModalState: createActionCreator(actionTypes.SET_FAILED_PAYMENT_PROCESSES_MORE_INFO_MODAL_STATE, 'stateConstant'),
    setFailedPaymentProcessesMoreInfoModalIdState: createActionCreator(actionTypes.SET_FAILED_PAYMENT_PROCESSES_MORE_INFO_MODAL_ID_STATE, 'id'),
    setEnrolmentModalState: createActionCreator(actionTypes.SET_ENROLMENT_MODAL_STATE, 'stateConstant'),
    setEnrolmentModalIdState: createActionCreator(actionTypes.SET_ENROLMENT_MODAL_ID_STATE, 'id'),
    setCohortModalState: createActionCreator(actionTypes.SET_COHORT_MODAL_STATE, 'stateConstant'),
    setCohortModalTypeState: createActionCreator(actionTypes.SET_COHORT_MODAL_TYPE_STATE, 'stateConstant'),
    setCohortModalIdState: createActionCreator(actionTypes.SET_COHORT_MODAL_ID_STATE, 'id'),
    setGridsConfirmationModalState: createActionCreator(actionTypes.SET_GRIDS_CONFIRMATION_MODAL_STATE, 'stateConstant'),
    setGridsConfirmationModalTypeState: createActionCreator(actionTypes.SET_GRIDS_CONFIRMATION_MODAL_TYPE_STATE, 'stateConstant'),
    setSchoolSearchState: createActionCreator(actionTypes.SET_SCHOOL_SEARCH_STATE, 'stateConstant'),
    setCareersLeadSearchState: createActionCreator(actionTypes.SET_CAREERS_LEAD_SEARCH_STATE, 'stateConstant'),
    setEnrolmentsPageTabState: createActionCreator(actionTypes.SET_ENROLMENTS_PAGE_TAB_STATE, 'stateConstant'),
    setPollingPaymentProcessesState: createActionCreator(actionTypes.SET_POLLING_PAYMENT_PROCESSES_STATE, 'stateConstant'),
    setPollingFailedPaymentProcessesState: createActionCreator(actionTypes.SET_POLLING_FAILED_PAYMENT_PROCESSES_STATE, 'stateConstant'),
    setPollingEnrolmentsAndCohortsState: createActionCreator(actionTypes.SET_POLLING_ENROLMENTS_AND_COHORTS_STATE, 'stateConstant'),
    setReloadingFailedPaymentProcessesState: createActionCreator(actionTypes.SET_RELOADING_FAILED_PAYMENT_PROCESSES_STATE, 'stateConstant'),
    setReloadingEnrolmentsAndCohortsState: createActionCreator(actionTypes.SET_RELOADING_ENROLMENTS_AND_COHORTS_STATE, 'stateConstant'),
    setDownloadAllTransactionsState: createActionCreator(actionTypes.SET_DOWNLOAD_ALL_TRANSACTIONS_STATE, 'stateConstant'),
    setDownloadMonthlyClaimState: createActionCreator(actionTypes.SET_DOWNLOAD_MONTHLY_CLAIM_STATE, 'stateConstant'),
    setDownloadFinanceReportState: createActionCreator(actionTypes.SET_DOWNLOAD_FINANCE_REPORT_STATE, 'stateConstant'),
    setGridsConfirmationModalErrors: createActionCreator(actionTypes.SET_GRIDS_CONFIRMATION_MODAL_ERRORS, 'data'),
    updateGridsConfirmationModalErrors: createActionCreator(actionTypes.UPDATE_GRIDS_CONFIRMATION_MODAL_ERRORS, 'id', 'data'),
    clearGridsConfirmationModalErrors: createActionCreator(actionTypes.CLEAR_GRIDS_CONFIRMATION_MODAL_ERRORS),
    setForgotPasswordModalState: createActionCreator(actionTypes.SET_FORGOT_PASSWORD_MODAL_STATE, 'stateConstant'),
    setResetPasswordState: createActionCreator(actionTypes.SET_RESET_PASSWORD_STATE, 'stateConstant'),

    addRetryingFailedPaymentProcessId: createActionCreator(actionTypes.ADD_RETRYING_FAILED_PAYMENT_PROCESS_ID, 'id'),
    removeRetryingFailedPaymentProcessId: createActionCreator(actionTypes.REMOVE_RETRYING_FAILED_PAYMENT_PROCESS_ID, 'id'),

    // Notifications
    pushNotification: createActionCreator(actionTypes.PUSH_NOTIFICATION, 'style', 'message'),
    unshiftNotification: createActionCreator(actionTypes.UNSHIFT_NOTIFICATION),

    // Impersonation
    setUserIdToImpersonate: createActionCreator(actionTypes.SET_USER_ID_TO_IMPERSONATE, 'userIdToImpersonate'),
    clearUserIdToImpersonate: createActionCreator(actionTypes.CLEAR_USER_ID_TO_IMPERSONATE),
    startImpersonating: createActionCreator(actionTypes.START_IMPERSONATING),
    stopImpersonating: createActionCreator(actionTypes.STOP_IMPERSONATING),
};

export default {
    actionTypes,
    actionCreators,
};

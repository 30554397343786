import routes from "./routes";
import roles from "./roles";

export const UNAUTHENTICATED = 'UNAUTHENTICATED';

export default {
    [UNAUTHENTICATED]: [
        routes.LOGIN,
        routes.RESET_PASSWORD,
        routes.LOGOUT,
    ],
    [roles.CAREERS_LEAD]: [
        routes.CL_PROFILE,
        routes.LOGOUT,
    ],
    [roles.PROVIDER]: [
        routes.ENROLMENTS,
        routes.LOGOUT,
    ],
    [roles.CEC]: [
        routes.ENROLMENTS,
        routes.LOGOUT,
    ],
}

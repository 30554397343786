import React from "react";
import {connect} from "react-redux";
import PollingAlertMessage from "./PollingAlertMessage";
import {selectIsUpdateNeeded} from "../../redux/reducers/states/pollingFailedPaymentProcesses";
import {selectIsReloadingFailedPaymentProcesses} from "../../redux/reducers/states/reloadingFailedPaymentProcesses";
import signals from "../../redux/actions/signals";

const {
    reloadFailedPaymentProcesses,
} = signals.actionCreators;

const mapStateToProps = state => ({
    isLoading: selectIsReloadingFailedPaymentProcesses(state),
    showAlert: selectIsUpdateNeeded(state),
    message: 'The data in this table has been updated. Please select the reload data button to reload the data in the table.',
});

const mapDispatchToProps = {
    buttonOnClick: reloadFailedPaymentProcesses,
};

export default connect(mapStateToProps, mapDispatchToProps)(PollingAlertMessage);

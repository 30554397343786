const fields = {
    SIGNED_UP_AT: 'signedUpAt',
    PHONE: 'phone',
    COURSE_ID: 'courseId',
    DELIVERY_TYPE_ID: 'deliveryTypeId',
    COHORT_ID: 'cohortId',
    REGION_ID: 'regionId',
    PROVIDER_ID: 'providerId',
    IS_CONTRACT_ISSUED: 'isContractIssued',
    CONTRACT_ISSUED_AT: 'contractIssuedAt',
    CONTRACT_SIGNED_AT: 'contractSignedAt',
    START_DATE: 'startDate',
    THEORETICAL_COMPLETION_DATE: 'theoreticalCompletionDate',
    IS_COMPLETED: 'isCompleted',
    COMPLETED_AT: 'completedAt',
    STATUS: 'status',
    STATUS_UPDATED_AT: 'statusUpdatedAt',
    ASSESSMENT_OUTCOME: 'assessmentOutcome',
    ASSESSMENT_OUTCOME_UPDATED_AT: 'assessmentOutcomeUpdatedAt',
    SCHOOL_START_DATE: 'schoolStartedAt',
};

export default fields;

export const clearFieldErrorsByField = {
    [fields.SIGNED_UP_AT]: [fields.CONTRACT_SIGNED_AT, fields.CONTRACT_ISSUED_AT],
    [fields.CONTRACT_SIGNED_AT]: [fields.START_DATE, fields.COMPLETED_AT],
    [fields.START_DATE]: [fields.THEORETICAL_COMPLETION_DATE, fields.COMPLETED_AT],
};

export const enrolmentFieldsToExcludeForProvider = [
    fields.SIGNED_UP_AT,
    fields.PHONE,
    fields.PROVIDER_ID,
    fields.CONTRACT_ISSUED_AT,
    fields.COMPLETED_AT,
    fields.STATUS_UPDATED_AT,
    fields.ASSESSMENT_OUTCOME_UPDATED_AT,
    fields.SCHOOL_START_DATE,
];

export const enrolmentFieldsToExcludeForCec = [
    fields.IS_CONTRACT_ISSUED,
    fields.IS_COMPLETED,
];

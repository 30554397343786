import createReducer from "../helpers/createReducer";
import deltas from "../actions/deltas";

const {
    START_IMPERSONATING,
    SET_USER_ID_TO_IMPERSONATE,
    CLEAR_USER_ID_TO_IMPERSONATE,
    STOP_IMPERSONATING,
} = deltas.actionTypes;

export default createReducer(
    {
        userIdToImpersonate: null,
        isImpersonating: false,
    },
    {
        [START_IMPERSONATING]: (state) => ({
            ...state,
            isImpersonating: true,
        }),
        [SET_USER_ID_TO_IMPERSONATE]: (state, {userIdToImpersonate}) => ({
            ...state,
            userIdToImpersonate,
        }),
        [CLEAR_USER_ID_TO_IMPERSONATE]: (state) => ({
            ...state,
            userIdToImpersonate: null,
        }),
        [STOP_IMPERSONATING]: (state) => ({
            ...state,
            isImpersonating: false,
        })
    },
);

import {call, put} from "@redux-saga/core/effects";
import deltas from "../../../../redux/actions/deltas";
import request from "../../../helpers/request";
import {getAllFailedPaymentProcesses} from "../../../../api";
import pollingFailedPaymentProcessesStates from "../../../../redux/states/pollingFailedPaymentProcesses"
import reloadingFailedPaymentProcessesStates from "../../../../redux/states/reloadingFailedPaymentProcesses"

const {
    setPollingFailedPaymentProcessesState,
    setFailedPaymentProcesses,
    setReloadingFailedPaymentProcessesState,
} = deltas.actionCreators;

const {
    NO_UPDATE_NEEDED,
} = pollingFailedPaymentProcessesStates;

const {
    LOADING,
    NOT_LOADING,
} = reloadingFailedPaymentProcessesStates;

export default function* reloadFailedPaymentProcesses() {
    yield put(setReloadingFailedPaymentProcessesState(LOADING));

    const {response, error} = yield call(request, getAllFailedPaymentProcesses, {
        notificationOnFailure: {
            generateMessage: message => 'There was a problem reloading the data',
        },
    }) || {};
    if (undefined === error) {
        yield put(setFailedPaymentProcesses(response.data));
        yield put(setPollingFailedPaymentProcessesState(NO_UPDATE_NEEDED));
    }

    yield put(setReloadingFailedPaymentProcessesState(NOT_LOADING));
}

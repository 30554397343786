import CheckboxField from "../../atoms/CheckboxField";
import React from "react";
import LoadingIcon from "mdi-react/LoadingIcon";
import {connect} from "react-redux";
import privacyStates from "../../../redux/states/privacy";

const {
    REQUESTING,
} = privacyStates;

const mapStateToProps = state => ({
    isLoading: REQUESTING === state.states.privacy.state,
});

const PrivacyPermission = ({isPrivacyAgreed, toggleIsPrivacyAgreed, isLoading}) => <div>
    <p className="font-14 text-muted mb-2">Privacy consent to use my data</p>
    {
        isLoading
        ? <LoadingIcon className="more__icon animation--spin"/>
        : <CheckboxField
                id="o-privacyAgreement"
                label="I agree*"
                checked={isPrivacyAgreed}
                onClick={toggleIsPrivacyAgreed}
            />
    }
    <p className="font-12 text-muted mt-3 mb-0">
        * To administer the training, once you have selected a training provider - the information you give will be passed on to the training provider you subsequently select to allow them to arrange the training. By ticking this box you are consenting to the processing of your personal data as described above. Should you wish to withdraw your consent at any time please contact <a href={`mailto:careersleaders@careersandenterprise.co.uk`} className="link-teal-to-gray">careersleaders@careersandenterprise.co.uk</a>.
    </p>
</div>

export default connect(mapStateToProps)(PrivacyPermission);

import React from "react";
import Tippy from "@tippyjs/react";
import FileDocumentEditIcon from "mdi-react/FileDocumentEditIcon";
import CheckboxMarkedOutlineIcon from "mdi-react/CheckboxMarkedOutlineIcon";
import AccountGroupOutlineIcon from "mdi-react/AccountGroupOutlineIcon";
import classnames from "classnames";

export default ({isCec, setActionColumnHover, tryToMarkContractsAsIssued, tryToMarkCoursesAsComplete, tryToApplyCohortToEnrolments, selectedRows}) =>
    <div className="head__cell cell--actions" onMouseEnter={() => setActionColumnHover(true)} onMouseLeave={() => setActionColumnHover(false)}>
        {(selectedRows.length) ? '' : <span className="actions__text">Actions</span> }
        <div className={'actions__more ' + classnames({'show': selectedRows.length})}>
            <Tippy content="Mark contracts as issued" placement="top" animation="fade" arrow theme="light-border"
                appendTo={() => document.body}>
                <button className="link-gray-to-teal button-link" data-cy="enrolments--mark-as-issued" onClick={tryToMarkContractsAsIssued}>
                    <FileDocumentEditIcon className="more__icon"/>
                </button>
            </Tippy>
            <Tippy content="Mark courses as completed" placement="top" animation="fade" arrow theme="light-border"
                   appendTo={() => document.body}>
                <button className="link-gray-to-teal button-link" data-cy="enrolments--mark-as-completed" onClick={tryToMarkCoursesAsComplete}>
                    <CheckboxMarkedOutlineIcon className="more__icon"/>
                </button>
            </Tippy>
            {!isCec &&
                <Tippy content="Assign cohort to enrolments" placement="top" animation="fade" arrow theme="light-border"
                       appendTo={() => document.body}>
                    <button className="link-gray-to-teal button-link" data-cy="enrolments--assign-cohort-to-enrolments" onClick={tryToApplyCohortToEnrolments}>
                        <AccountGroupOutlineIcon className="more__icon"/>
                    </button>
                </Tippy>
            }
        </div>
    </div>;

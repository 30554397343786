import React from "react";
import Select from "react-select";

export default ({id, placeholder, isMulti = false, options = [], isGrouped, value, updateForm, formatOptionLabel, overrideOnChange, filterOption, onInputChange, isLoading , isClearable, disabled, ...rest}) => {

    const onChange = overrideOnChange || (selected => updateForm((selected ? selected.value : null)));

    return <Select
        onChange={onChange}
        inputId={id}
        {...{placeholder, isMulti, options, formatOptionLabel, filterOption, onInputChange, isLoading, ...rest}}
        isClearable={isClearable !== false}
        isDisabled={disabled}
        className="react-select-container"
        classNamePrefix="select--custom"
        value={!value ? null : (isGrouped ? options.reduce((flattenedOptions, group) => ([...flattenedOptions, ...group.options]), []) : options).find(option => value === option.value)}
        theme={(theme) => ({
            ...theme,
            colors: {
                ...theme.colors,
                primary25: '#B5DDDD',
                primary: '#00A8A8',
            },
        })}
    />;
}

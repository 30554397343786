import {call, delay, put, select} from "@redux-saga/core/effects";
import deltas from "../../../../redux/actions/deltas";
import request from "../../../helpers/request";
import {pollPaymentProcessesQueues} from "../../../../api";
import pollingPaymentProcessesStates from "../../../../redux/states/pollingPaymentProcesses"
import {selectPollingPaymentProcessesState} from "../../../../redux/reducers/states/pollingPaymentProcesses";

const {
    setPollingPaymentProcessesState,
} = deltas.actionCreators;

const {
    POLLING_PROCESSES_PENDING,
    POLLING_NO_PROCESSES_PENDING,
    PROCESSES_PENDING,
    NO_PROCESSES_PENDING,
} = pollingPaymentProcessesStates

const DELAY_BETWEEN_POLLS_IN_MS = 60000; // 60 seconds

export default function* pollPaymentProcesses()
{
    while (true) {
        const currentPollingPaymentProcessState = yield select(selectPollingPaymentProcessesState)

        if (currentPollingPaymentProcessState === PROCESSES_PENDING || currentPollingPaymentProcessState === POLLING_PROCESSES_PENDING) {
            yield put(setPollingPaymentProcessesState(POLLING_PROCESSES_PENDING));
        } else {
            yield put(setPollingPaymentProcessesState(POLLING_NO_PROCESSES_PENDING));
        }

        try {
            const {response} = yield call(request, pollPaymentProcessesQueues) || {};

            if (response.data === true) {
                yield put(setPollingPaymentProcessesState(PROCESSES_PENDING));
            } else {
                yield put(setPollingPaymentProcessesState(NO_PROCESSES_PENDING));
            }
        } catch {
        }

        yield delay(DELAY_BETWEEN_POLLS_IN_MS);
    }
}

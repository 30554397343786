import {connect} from "react-redux";

import Grid from "./Grid";
import deltas from "../../redux/actions/deltas";
import roles from "../../redux/roles";

const {
    addValueToPaymentsGridTextFilter,
    removeValueFromPaymentsGridTextFilter,
    clearPaymentsGridTextFilter,
    cyclePaymentsGridColumnSortState,
    setPaymentsGridDateFilter,
    clearPaymentsGridDateFilter,
} = deltas.actionCreators;

const mapStateToProps = (state) => ({
    rowsFromRedux: state.paymentsGrid.filteredSortedData,
    selectedRows: state.paymentsGrid.selectedRows,
    gridStateSliceName: 'paymentsGrid',
    isCec: roles.CEC === state.role,
});

const mapDispatchToProps = dispatch => ({
    addValueToTextFilter: addValueToPaymentsGridTextFilter,
    removeValueFromTextFilter: removeValueFromPaymentsGridTextFilter,
    clearTextFilter: clearPaymentsGridTextFilter,
    cycleColumnSortState: cyclePaymentsGridColumnSortState,
    setDateFilter: setPaymentsGridDateFilter,
    clearDateFilter: clearPaymentsGridDateFilter,
});


export default connect(mapStateToProps, mapDispatchToProps)(Grid);

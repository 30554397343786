import createDataReducer from "../../helpers/createDataReducer";
import deltas from "../../actions/deltas";

const {
    SET_PROVIDERS,
    UPDATE_PROVIDER,
    CLEAR_PROVIDERS,
} = deltas.actionTypes;

export const selectProviders = (state) => state.data.providers;

export default createDataReducer(
    SET_PROVIDERS,
    UPDATE_PROVIDER,
    CLEAR_PROVIDERS,
);

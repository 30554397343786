import cohortModalStates from "../../../../redux/states/cohortModal";
import cohortModalTypeStates from "../../../../redux/states/cohortModalType";
import React from "react";
import ModalMessage from "../../../molecules/ModalMessage";
import {connect} from "react-redux";
import signals from "../../../../redux/actions/signals";
import FormContext from "../../../FormContext";
import inputs from "../../../molecules/inputsWithContextAndSurrounds";
import deltas from "../../../../redux/actions/deltas";
import fields from "../../../../redux/fields/cohort";
import TextIcon from "mdi-react/TextIcon";
import AccountMultipleIcon from 'mdi-react/AccountMultipleIcon';
import BusinessIcon from "mdi-react/BusinessIcon";
import roles from "../../../../redux/roles";

const {
    TextInput,
    SelectInput,
} = inputs;

const {
    OPEN_STATIC,
    REQUESTING,
} = cohortModalStates;

const {
    NEW,
} = cohortModalTypeStates;

const {
    closeCohortModal,
    submitCohortForm,
} = signals.actionCreators;

const {
    updateCohortForm,
} = deltas.actionCreators;

const mapStateToProps = (state) => ({
    isCohortModalOpen: [OPEN_STATIC, REQUESTING].includes(state.states.cohortModal.state),
    type: state.states.cohortModalType.state,
    formState: state.forms.cohort,
    providerOptions: Object.entries(state.data.providers)
        .map(([id, {name}]) => ({
            label: name,
            value: parseInt(id),
        })),
    isCec: roles.CEC === state.role,
});

const mapDispatchToProps = {
    closeCohortModal,
    updateCohortForm,
    submitCohortForm,
};

const CohortModal = ({isCohortModalOpen, closeCohortModal, type, updateCohortForm, submitCohortForm, formState, providerOptions, isCec}) =>
    <ModalMessage
        title={
            NEW === type
            ? "Create a new cohort"
            : "Edit the information of this cohort"
        }
        overrideSaveButtonText={
            NEW === type
                ? "Create cohort"
                : null
        }
        isOpen={isCohortModalOpen}
        closeModal={closeCohortModal}
        size={"lg"}
        submitForm={submitCohortForm}
    >
        <FormContext.Provider value={{formState, updater: updateCohortForm}}>
            <div className="mb-3">
                <TextInput
                    id="o-name"
                    data-cy="cohort--name"
                    label="Name"
                    placeholder="Enter a name"
                    icon={<AccountMultipleIcon />}
                    field={fields.NAME}
                    required
                    maxChars={50}
                />
            </div>
            <div className="mb-3">
                <TextInput
                    id="o-name"
                    data-cy="cohort--description"
                    label="Description"
                    placeholder="Enter a description"
                    icon={<TextIcon />}
                    field={fields.DESCRIPTION}
                    required
                    maxChars={100}
                />
            </div>
            {isCec &&
                <SelectInput
                    id="o-cohortProvider"
                    label="Provider"
                    placeholder="Select provider"
                    icon={<BusinessIcon />}
                    options={providerOptions}
                    isGrouped={false}
                    field={fields.PROVIDER_ID}
                    required
                />
            }
        </FormContext.Provider>
    </ModalMessage>;

export default connect(mapStateToProps, mapDispatchToProps)(CohortModal);

import createDataReducer from "../../helpers/createDataReducer";
import deltas from "../../actions/deltas";
import {createSelector} from "reselect";

const {
    SET_COHORTS,
    UPDATE_COHORT,
    CLEAR_COHORTS,
    DELETE_COHORT,
} = deltas.actionTypes;

export default createDataReducer(
    SET_COHORTS,
    UPDATE_COHORT,
    CLEAR_COHORTS,
    DELETE_COHORT
);

export const selectCohorts = state => state.data.cohorts;
export const selectCountOfCohorts = createSelector(
    selectCohorts,
    (cohorts) => Object.keys(cohorts).length,
);
export const selectMaxUpdatedAtFromCohorts = createSelector(
    selectCohorts,
    (cohorts) => Object.values(cohorts)
        .reduce((maxUpdatedAt, {updatedAt}) => {
            const updatedAtDateObject = new Date(updatedAt);

            if (null === maxUpdatedAt || updatedAtDateObject > maxUpdatedAt) {
                return updatedAtDateObject;
            }

            return maxUpdatedAt;
        }, null),
);

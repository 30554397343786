import {call, put} from "@redux-saga/core/effects";
import deltas from "../../../../redux/actions/deltas";
import request from "../../../helpers/request";
import {getAllEnrolments, getAllCohorts} from "../../../../api";
import pollingEnrolmentsAndCohortsStates from "../../../../redux/states/pollingEnrolmentsAndCohorts"
import reloadingEnrolmentsAndCohortsStates from "../../../../redux/states/reloadingEnrolmentsAndCohorts"
import {all} from "redux-saga/effects";

const {
    setPollingEnrolmentsAndCohortsState,
    setEnrolments,
    setCohorts,
    setReloadingEnrolmentsAndCohortsState,
} = deltas.actionCreators;

const {
    NO_UPDATE_NEEDED,
} = pollingEnrolmentsAndCohortsStates;

const {
    LOADING,
    NOT_LOADING,
} = reloadingEnrolmentsAndCohortsStates;

const options = {
    notificationOnFailure: {
        generateMessage: message => 'There was a problem reloading the data',
    }
};

export default function* reloadEnrolmentsAndCohorts() {
    yield put(setReloadingEnrolmentsAndCohortsState(LOADING));

    const {
        enrolments: {response: {data: enrolments}},
        cohorts: {response: {data: cohorts}},
    } = yield all({
        enrolments: call(request, ...[getAllEnrolments, options]),
        cohorts: call(request, ...[getAllCohorts, options]),
    });

    yield put(setEnrolments(enrolments));
    yield put(setCohorts(cohorts));
    yield put(setPollingEnrolmentsAndCohortsState(NO_UPDATE_NEEDED));
    yield put(setReloadingEnrolmentsAndCohortsState(NOT_LOADING));
}

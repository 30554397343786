import React from "react";

import Header from "./organisms/Header";

export default ({children, pageClass = null}) => <div className={("page page--") + (pageClass || 'section')}>
    {/* Global Header */}
    <Header />
    {/* Content */}
    <main className="page__content">
        <div className="page__container">
            {children}
        </div>
    </main>
    {/* Footer */}
    <footer className="page__footer">
        <div className="page__container">
            © The Careers and Enterprise Company {new Date().getFullYear()}. All rights reserved.
        </div>
    </footer>
</div>;

import createDataReducer from "../../helpers/createDataReducer";
import deltas from "../../actions/deltas";

const {
    SET_GRIDS_CONFIRMATION_MODAL_ERRORS,
    UPDATE_GRIDS_CONFIRMATION_MODAL_ERRORS,
    CLEAR_GRIDS_CONFIRMATION_MODAL_ERRORS,
} = deltas.actionTypes;

export default createDataReducer(SET_GRIDS_CONFIRMATION_MODAL_ERRORS, UPDATE_GRIDS_CONFIRMATION_MODAL_ERRORS, CLEAR_GRIDS_CONFIRMATION_MODAL_ERRORS);

export const selectGridsConfirmationModalErrors = state => state.states.gridsConfirmationModalErrors;

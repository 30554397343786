import React from "react";
import classnames from "classnames";
import {dateColumns} from "../../redux/reducers/enrolmentsGrid";

import FilterVariantIcon from "mdi-react/FilterVariantIcon";

export default ({filtersApplied, columnName}) => {

    const filterActive = dateColumns.includes(columnName)
        ? filtersApplied && filtersApplied.active
        : filtersApplied && filtersApplied.length;

    return <React.Fragment>
        <span className="filter__container">
            {0 < filterActive && <span className="filter__active"/>}
            <FilterVariantIcon className={"filter__icon " + (classnames({'active' : 0 < filterActive}))} size={18} />
        </span>
    </React.Fragment>;
};

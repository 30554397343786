import React from "react";
import LoadingIcon from "mdi-react/LoadingIcon";

const DownloadButton = ({buttonText, isLoading, className = 'cec-btn cec-btn-primary-teal', ...rest}) => {
    return (
        <button
            type="button"
            className={!isLoading ? className : className + ' cec-btn-loading'}
            disabled={isLoading}
            {...rest}
        >
            {isLoading && <LoadingIcon className="more__icon animation--spin"/>}
            <span>{buttonText}</span>
        </button>
    )
};

export default DownloadButton;

import FormContext from "../../../FormContext";
import SchoolIcon from "mdi-react/SchoolIcon";
import KeyIcon from "mdi-react/KeyIcon";
import ReadOnlyField from "../../../atoms/ReadOnlyField";
import ModalMessage from "../../../molecules/ModalMessage";
import React from "react";
import inputs from "../../../molecules/inputsWithContextAndSurrounds";
import {connect} from "react-redux";
import deltas from "../../../../redux/actions/deltas";
import fields from "../../../../redux/fields/schoolAndLogin";
import signals from "../../../../redux/actions/signals";
import schoolAndLoginModalStates from "../../../../redux/states/schoolAndLoginModal";
import schoolSearchStates from "../../../../redux/states/schoolSearch";
import CalendarBlankIcon from "mdi-react/CalendarBlankIcon";
import AlertMessage from "../../../atoms/AlertMessage";

const {SelectInput, DateInput} = inputs;

const {
    updateSchoolAndLoginForm,
} = deltas.actionCreators;

const {
    closeSchoolAndLoginModal,
    submitSchoolAndLoginForm,
    searchSchools,
} = signals.actionCreators;

const {
    OPEN_STATIC,
    REQUESTING,
} = schoolAndLoginModalStates;

const mapStateToProps = state => ({
    formState: state.forms.schoolAndLogin,
    isSchoolAndLoginModalOpen: [OPEN_STATIC, REQUESTING].includes(state.states.schoolAndLoginModal.state),
    isSchoolAndLoginModalRequesting: REQUESTING === state.states.schoolAndLoginModal.state,
    isSchoolSelected: null !== state.forms.schoolAndLogin[fields.URN].value,
    schoolOptions: state.data.schoolSearchResults.results && state.data.schoolSearchResults.results.map(
        result => ({
            label: `${result.urn} - ${result.name}`,
            value: result.urn,
        })
    ),
    loginOptions: [
        {
            label: "I will login with my current email address",
            value: 'no',
        },
        {
            label: "I have a new email address which I will use to login",
            value: 'yes',
        },
    ],
    schoolChoiceOptions: [
        {
            label: "Use the school associated with my Compass account",
            value: 'schoolshub',
        },
        {
            label: "I want to select the school myself",
            value: 'self',
        },
        {
            label: "My school has not changed, only my email address",
            value: 'no-change',
        },
    ],
    willLoginWithNewEmail: state.forms.schoolAndLogin[fields.WANT_TO_CHANGE_LOGIN].value,
    schoolChoiceMethod: state.forms.schoolAndLogin[fields.SCHOOL_CHOICE_METHOD].value,
    selectedSchoolName: state.data.schoolSearchResults.results && (state.data.schoolSearchResults.results.find(
        result => state.forms.schoolAndLogin.urn.value === result.urn
    ) || {name: ''}).name,
    selectedSchoolRegionName: state.data.schoolSearchResults.results && (state.data.schoolSearchResults.results.find(
        result => state.forms.schoolAndLogin.urn.value === result.urn
    ) || {name: ''}).regionName,
    isSearchingForSchools: schoolSearchStates.REQUESTING === state.states.schoolSearch.state,
});

const mapDispatchToProps = dispatch => ({
    updateSchoolAndLoginForm: (field, value, error) => dispatch(updateSchoolAndLoginForm(field, value, error)),
    closeSchoolAndLoginModal: () => dispatch(closeSchoolAndLoginModal()),
    submitSchoolAndLoginForm: () => dispatch(submitSchoolAndLoginForm()),
    searchSchools: query => dispatch(searchSchools(query)),
});

const SchoolAndLoginModal = ({formState, updateSchoolAndLoginForm, isSchoolAndLoginModalOpen, isSchoolAndLoginModalRequesting, closeSchoolAndLoginModal, submitSchoolAndLoginForm, isSchoolSelected, loginOptions, willLoginWithNewEmail, schoolOptions, searchSchools, selectedSchoolRegionName, isSearchingForSchools, schoolChoiceOptions, schoolChoiceMethod}) =>
    <ModalMessage
        title="Edit your School and Login Information"
        isOpen={isSchoolAndLoginModalOpen}
        isRequesting={isSchoolAndLoginModalRequesting}
        closeModal={closeSchoolAndLoginModal}
        submitForm={submitSchoolAndLoginForm}
    >
        <FormContext.Provider value={{formState, updater: updateSchoolAndLoginForm}}>
            <div className="mb-3">
                <SelectInput id="o-wantToChangeLogin" label="Login Details" placeholder="Do you have a new email address?" field={fields.WANT_TO_CHANGE_LOGIN} icon={<KeyIcon />} options={loginOptions} required />
            </div>
            { 'yes' === willLoginWithNewEmail &&
                <>
                    <div className="mb-3">
                        <AlertMessage>
                            <p className="mb-3">
                                You will be prompted to login with your new email address after saving your changes.
                            </p>
                            <p className="mb-0">
                                You must have a Compass account connected to your new email address. Please contact CEC if this is not the case.
                            </p>
                        </AlertMessage>
                    </div>
                    <div className="mb-3">
                        <SelectInput id="o-schoolChoice" label="School Choice" placeholder="How should we find your new school?" field={fields.SCHOOL_CHOICE_METHOD} icon={<KeyIcon />} options={schoolChoiceOptions} required />
                    </div>
                </>
            }
            { ('no' === willLoginWithNewEmail || ('yes' === willLoginWithNewEmail && 'self' === schoolChoiceMethod)) &&
                <div className="mb-3">
                    <SelectInput
                        id="o-profileURN"
                        label="School URN or Name"
                        placeholder="Start typing to find your new school"
                        field={fields.URN}
                        options={schoolOptions}
                        icon={<SchoolIcon />}
                        onInputChange={e => {searchSchools(e);}}
                        isLoading={isSearchingForSchools}
                        required={'no' === willLoginWithNewEmail}
                    />
                </div>
            }
            <div className="row fade-in-bottom mt-0 mb-0">
                { (isSchoolSelected || ('yes' === willLoginWithNewEmail && 'schoolshub' === schoolChoiceMethod)) &&
                    <div className="col-md-6 mb-0 mt-0">
                        <DateInput id="o-schoolStartDate" label="Start Date" placeholder="Select the date you started at the school" field={fields.STARTED_AT} icon={<CalendarBlankIcon />} required noFutureDate />
                    </div>
                }
                { isSchoolSelected &&
                    <div className="col-md-6 mb-0">
                        <ReadOnlyField value={selectedSchoolRegionName} label="Region"/>
                    </div>
                }
            </div>
        </FormContext.Provider>
    </ModalMessage>;

export default connect(mapStateToProps, mapDispatchToProps)(SchoolAndLoginModal);

import createDataReducer from "../../helpers/createDataReducer";
import deltas from "../../actions/deltas";

const {
    SET_SCHOOL_SEARCH_RESULTS,
    UPDATE_SCHOOL_SEARCH_RESULTS,
    CLEAR_SCHOOL_SEARCH_RESULTS,
} = deltas.actionTypes;

export default createDataReducer(
    SET_SCHOOL_SEARCH_RESULTS,
    UPDATE_SCHOOL_SEARCH_RESULTS,
    CLEAR_SCHOOL_SEARCH_RESULTS,
);

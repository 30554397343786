import {put, select, call, take, spawn} from "@redux-saga/core/effects";
import {deleteCohortById} from "../../../../../api";
import request from "../../../../helpers/request";
import deltas from "../../../../../redux/actions/deltas";
import gridsConfirmationModalStates from "../../../../../redux/states/gridsConfirmationModal";
import modalTypeStates from "../../../../../redux/states/gridsConfirmationModalType";
import signals from "../../../../../redux/actions/signals";
import notify from "../../../../helpers/notify";

const {
    OPEN_STATIC,
    REQUESTING,
    CLOSED,
} = gridsConfirmationModalStates;

const {
    setGridsConfirmationModalState,
    setGridsConfirmationModalTypeState,
} = deltas.actionCreators;

const {
    SUBMIT_GRIDS_CONFIRMATION_MODAL,
    CLOSE_GRIDS_CONFIRMATION_MODAL,
} = signals.actionTypes;

const {
    setCohorts,
    deleteCohort: deleteCohortActionCreator,
} = deltas.actionCreators;

const failureMessage = 'There was a problem deleting this cohort';
const successMessage = 'This cohort was successfully deleted';

export default function* deleteCohort(id) {
    yield put(setGridsConfirmationModalTypeState(modalTypeStates.DELETE_COHORT));
    yield put(setGridsConfirmationModalState(OPEN_STATIC));

    while (true) {
        const {type} = yield take([SUBMIT_GRIDS_CONFIRMATION_MODAL, CLOSE_GRIDS_CONFIRMATION_MODAL]);

        switch (type) {
            case SUBMIT_GRIDS_CONFIRMATION_MODAL:
                yield put(setGridsConfirmationModalState(REQUESTING));

                const result = yield call(
                    request,
                    deleteCohortById,
                    ...[
                        {notificationOnError: {generateMessage: message => failureMessage}},
                        null,
                        id,
                    ]
                );

                const {response: {data: success} = {}} = result || {};

                if (true !== success) {
                    break;
                }

                yield spawn(notify, 'success', successMessage);

                // TODO it would be better if the grid reducer responded directly to the delete action
                yield put(deleteCohortActionCreator(id));
                const cohorts = yield select(state => state.data.cohorts);
                yield put(setCohorts(cohorts));

                yield put(setGridsConfirmationModalState(CLOSED));

                return;

            case CLOSE_GRIDS_CONFIRMATION_MODAL:
                yield put(setGridsConfirmationModalState(CLOSED));
                return;
            default:
        }

        yield put(setGridsConfirmationModalState(OPEN_STATIC));
    }
}

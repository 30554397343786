import createFormReducer from "../../helpers/createFormReducer";
import fields, {clearFieldErrorsByField} from "../../fields/enrolment";
import deltas from "../../actions/deltas";
import {getEnrolmentHasFixedTransactions} from "../../../components/organisms/enrolments/modals/EnrolmentModal";

const {
    UPDATE_ENROLMENT_FORM,
    SET_ENROLMENT_FORM,
    CLEAR_ENROLMENT_FORM,
} = deltas.actionTypes;

export const ENROLMENT_STATUS_INTEREST_SHOWN = 'Interest shown';
export const ENROLMENT_STATUS_ACTIVE = 'Active';
export const ENROLMENT_STATUS_COMPLETE = 'Complete';
export const ENROLMENT_STATUS_WITHDRAWN = 'Withdrawn';
export const ENROLMENT_STATUS_WITHDRAWN_WAIVE_COST = 'Withdrawn - waive cost';
export const ENROLMENT_STATUS_RELEASED = 'Released';

export default createFormReducer(fields, UPDATE_ENROLMENT_FORM, SET_ENROLMENT_FORM, CLEAR_ENROLMENT_FORM, clearFieldErrorsByField);

export const selectIsEnrolmentLockedDueToCompleted = (state) => true === state.forms.enrolment[fields.IS_COMPLETED].value;
export const selectIsEnrolmentLockedDueToReleased = (state, enrolmentId) => null != enrolmentId && ENROLMENT_STATUS_RELEASED === state.data.enrolments[enrolmentId].status;
export const selectIsEnrolmentLockedDueToContractSigned = (state, enrolmentId) => null != enrolmentId && true === state.data.enrolments[enrolmentId].isContractSigned && getEnrolmentHasFixedTransactions(state);

export const getAllowedStatuses = (currentStatus) => {
    if (undefined === currentStatus) {
        return [];
    }

    const activeAndWithdrawnStatuses = [
        ENROLMENT_STATUS_ACTIVE,
        ENROLMENT_STATUS_WITHDRAWN,
        ENROLMENT_STATUS_WITHDRAWN_WAIVE_COST,
        ENROLMENT_STATUS_RELEASED,
    ];

    if (activeAndWithdrawnStatuses.includes(currentStatus)) {
        return activeAndWithdrawnStatuses;
    }

    return [currentStatus];
}

import React from "react";
import {connect} from "react-redux";
import PollingAlertMessage from "./PollingAlertMessage";
import {selectIsUpdateNeeded} from "../../redux/reducers/states/pollingEnrolmentsAndCohorts";
import signals from "../../redux/actions/signals";
import {selectIsReloadingEnrolmentsAndCohorts} from "../../redux/reducers/states/reloadingEnrolmentsAndCohorts";

const {
    reloadEnrolmentsAndCohorts,
} = signals.actionCreators;

const mapStateToProps = state => ({
    isLoading: selectIsReloadingEnrolmentsAndCohorts(state),
    showAlert: selectIsUpdateNeeded(state),
    message: 'The data in this table has been updated. Please select the reload data button to reload the data in the table.',
});

const mapDispatchToProps = {
    buttonOnClick: reloadEnrolmentsAndCohorts,
};

export default connect(mapStateToProps, mapDispatchToProps)(PollingAlertMessage);

import {take, put, select, call} from "redux-saga/effects";
import signals from "../../../../redux/actions/signals";
import deltas from "../../../../redux/actions/deltas";
import states from "../../../../redux/states/resetPassword";
import fields from "../../../../redux/fields/resetPassword";
import {resetPassword as authResetPassword} from "../../../../auth";
import {PASSWORDS_DONT_MATCH} from "../../../../redux/reducers/forms/resetPassword";
import notify from "../../../helpers/notify";

const SUCCESS_MESSAGE = 'Your password was reset successfully';

const {
    SUBMIT_RESET_PASSWORD_FORM,
} = signals.actionTypes;

const {
    setResetPasswordState,
    updateResetPasswordForm,
    clearResetPasswordForm,
} = deltas.actionCreators;

const {
    STATIC,
    REQUESTING,
} = states;

const {
    EMAIL,
    PASSWORD,
    CONFIRM_PASSWORD,
    CODE,
} = fields;

export default function* resetPassword() {
    while (true) {
        yield put(setResetPasswordState(STATIC));
        const {type} = yield take([SUBMIT_RESET_PASSWORD_FORM]);

        switch (type) {
            case SUBMIT_RESET_PASSWORD_FORM:
                const hasErrors = yield select(state => state.forms.resetPassword._hasErrors);

                if (hasErrors) {
                    yield call(notify, 'warning', 'Please fix the form errors before continuing');
                    break;
                }

                yield put(setResetPasswordState(REQUESTING));

                const email = yield select(state => state.forms.resetPassword[EMAIL].value);
                const password = yield select(state => state.forms.resetPassword[PASSWORD].value);
                const confirmPassword = yield select(state => state.forms.resetPassword[CONFIRM_PASSWORD].value);
                const code = yield select(state => state.forms.resetPassword[CODE].value);

                if (password !== confirmPassword) {
                    yield put(updateResetPasswordForm(CONFIRM_PASSWORD, confirmPassword, PASSWORDS_DONT_MATCH));

                    continue;
                }

                try {
                    yield call(authResetPassword, email, password, code);
                } catch (e) {
                    yield put(updateResetPasswordForm(CODE, code, e.message));

                    continue;
                }

                yield put(clearResetPasswordForm());
                yield call(notify, 'success', SUCCESS_MESSAGE);

                return;
            default:
                break;
        }
    }
}

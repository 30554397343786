import createDataReducer from "../../helpers/createDataReducer";
import deltas from "../../actions/deltas";

const {
    SET_PAYMENTS,
    UPDATE_PAYMENT,
    CLEAR_PAYMENTS,
} = deltas.actionTypes;

export default createDataReducer(
    SET_PAYMENTS,
    UPDATE_PAYMENT,
    CLEAR_PAYMENTS,
);

import {connect} from "react-redux";

import signals from "../../redux/actions/signals";
import Grid from "./Grid";
import deltas from "../../redux/actions/deltas";
import EnrolmentsActionsCell from "../molecules/enrolments/EnrolmentsActionsCell";
import EnrolmentsGridActionsHeader from "../molecules/EnrolmentsGridActionsHeader";
import roles from "../../redux/roles";
import {selectIsReloadingEnrolmentsAndCohorts} from "../../redux/reducers/states/reloadingEnrolmentsAndCohorts";

const {
    addValueToEnrolmentsGridTextFilter,
    removeValueFromEnrolmentsGridTextFilter,
    clearEnrolmentsGridTextFilter,
    cycleEnrolmentsGridColumnSortState,
    setEnrolmentsGridDateFilter,
    clearEnrolmentsGridDateFilter,
    setEnrolmentsGridRowSelected,
    unsetEnrolmentsGridRowSelected,
} = deltas.actionCreators;

const {
    openEnrolmentModal,
    tryToMarkContractsAsIssued,
    tryToMarkCoursesAsComplete,
    tryToApplyCohortToEnrolments,
} = signals.actionCreators;


const mapStateToProps = (state) => ({
    rowsFromRedux: state.enrolmentsGrid.filteredSortedData,
    selectedRows: state.enrolmentsGrid.selectedRows,
    gridStateSliceName: 'enrolmentsGrid',
    ActionsCell: EnrolmentsActionsCell,
    ActionsHeader: EnrolmentsGridActionsHeader,
    isCec: roles.CEC === state.role,
    isLoading: selectIsReloadingEnrolmentsAndCohorts(state),
});

const mapDispatchToProps = dispatch => ({
    openModal: (id) => dispatch(openEnrolmentModal(id)),
    addValueToTextFilter: addValueToEnrolmentsGridTextFilter,
    removeValueFromTextFilter: removeValueFromEnrolmentsGridTextFilter,
    clearTextFilter: clearEnrolmentsGridTextFilter,
    cycleColumnSortState: cycleEnrolmentsGridColumnSortState,
    setDateFilter: setEnrolmentsGridDateFilter,
    clearDateFilter: clearEnrolmentsGridDateFilter,
    setEnrolmentsGridRowSelected: (id) => dispatch(setEnrolmentsGridRowSelected(id)),
    unsetEnrolmentsGridRowSelected: (id) => dispatch(unsetEnrolmentsGridRowSelected(id)),
    tryToMarkContractsAsIssued: () => dispatch(tryToMarkContractsAsIssued()),
    tryToMarkCoursesAsComplete: () => dispatch(tryToMarkCoursesAsComplete()),
    tryToApplyCohortToEnrolments: () => dispatch(tryToApplyCohortToEnrolments()),
});


export default connect(mapStateToProps, mapDispatchToProps)(Grid);

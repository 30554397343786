import deltas from "../actions/deltas";
import createGridReducer, {SORT_ASC} from "../helpers/createGridReducer";

const {
    SET_COHORTS,
    ADD_VALUE_TO_COHORTS_GRID_TEXT_FILTER,
    REMOVE_VALUE_FROM_COHORTS_GRID_TEXT_FILTER,
    CLEAR_COHORTS_GRID_TEXT_FILTER,
    CYCLE_COHORTS_GRID_COLUMN_SORT_STATE,
    SET_COHORTS_GRID_DATE_FILTER,
    CLEAR_COHORTS_GRID_DATE_FILTER,
    RESET_COHORTS_GRID_FILTER_AND_SORT,
    HIDE_COHORTS_GRID_COLUMN,
    SHOW_COHORTS_GRID_COLUMN,
} = deltas.actionTypes;

export const dateColumns = [''];

const initialSorters =
    [
        {
            column: 'providerName',
            direction: SORT_ASC,
        },
        {
            column: 'name',
            direction: SORT_ASC,
        },
    ];

export default createGridReducer(
    [],
    initialSorters,
    [],
    dateColumns,
    SET_COHORTS,
    ADD_VALUE_TO_COHORTS_GRID_TEXT_FILTER,
    REMOVE_VALUE_FROM_COHORTS_GRID_TEXT_FILTER,
    CLEAR_COHORTS_GRID_TEXT_FILTER,
    CYCLE_COHORTS_GRID_COLUMN_SORT_STATE,
    SET_COHORTS_GRID_DATE_FILTER,
    CLEAR_COHORTS_GRID_DATE_FILTER,
    RESET_COHORTS_GRID_FILTER_AND_SORT,
    HIDE_COHORTS_GRID_COLUMN,
    SHOW_COHORTS_GRID_COLUMN
);

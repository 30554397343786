import {
    getAllCohorts,
    getAllCourses,
    getAllEnrolments, getAllProviders, getAllRegions, getOwnProvider, getAllDeliveryTypes,
    getProfile,
    getAllPayments,
    getAllFailedPaymentProcesses,
    getAllFunds,
} from '../../api';
import {
    all,
    call,
    select,
    put,
    spawn,
} from "redux-saga/effects";
import request from "../helpers/request";
import deltas from "../../redux/actions/deltas";
import appStates from "../../redux/states/app";
import notify from "../helpers/notify";

const {
    setAppState,
    setEnrolments,
    setProfile,
    setProvider,
    setCourses,
    setDeliveryTypes,
    setCohorts,
    setPayments,
    setFailedPaymentProcesses,
    setFunds,
    setRegions,
    setProviders,
    clearEnrolments,
    clearProfile,
    clearProvider,
    clearCourses,
    clearCohorts,
    clearRegions,
    clearProviders,
    clearDeliveryTypes,
    clearPayments,
    clearFunds,
    clearFailedPaymentProcesses,
} = deltas.actionCreators;

const options = {
    notificationOnFailure: {
        generateMessage: message => `There was a problem loading the application data... Retrying...`,
    },
    attempts: 3,
};

const problemLoadingMessage = 'There was a problem loading the application data. Please contact CEC to report this issue.'

export default function* loading(clearFirst = false) {
    yield put(setAppState(appStates.LOADING));

    if (clearFirst) {
        yield put(clearEnrolments());
        yield put(clearProfile());
        yield put(clearProvider());
        yield put(clearCourses());
        yield put(clearCohorts());
        yield put(clearRegions());
        yield put(clearProviders());
        yield put(clearDeliveryTypes());
        yield put(clearPayments());
        yield put(clearFunds());
        yield put(clearFailedPaymentProcesses());
    }

    const role = yield select(state => state.role);

    try {

        // Load common data
        const {
            courses: {response: {data: courses}},
            regions: {response: {data: regions}},
            deliveryTypes: {response: {data: deliveryTypes}},
        } = yield all({
            courses: call(request, ...[getAllCourses, options]),
            regions: call(request, ...[getAllRegions, options]),
            deliveryTypes: call(request, ...[getAllDeliveryTypes, options]),
        });
        yield put(setCourses(courses));
        yield put(setRegions(regions));
        yield put(setDeliveryTypes(deliveryTypes));

        switch (role) {
            case 'CAREERS_LEAD':
                const {
                    profile: {response: {data: profile}},
                    enrolments: {response: {data: enrolmentsForCl}},
                    providers: {response: {data: providersForCl}},
                } = yield all({
                    profile: call(request, ...[getProfile, options]),
                    enrolments: call(request, ...[getAllEnrolments, options]),
                    providers: call(request, ...[getAllProviders, options]),
                });

                if (null !== profile.loginMessage && '' !== profile.loginMessage) {
                    yield call(notify, 'success', profile.loginMessage);
                }

                delete profile.loginMessage;

                yield put(setProfile(profile));
                yield put(setEnrolments(enrolmentsForCl));
                yield put(setProviders(providersForCl));

                break;
            case 'PROVIDER':
                const {
                    provider: {response: {data: provider}},
                    enrolments: {response: {data: enrolmentsForProvider}},
                    cohorts: {response: {data: cohortsForProvider}},
                    payments: {response: {data: paymentsForProvider}},
                    funds: {response: {data: fundsForProvider}},
                } = yield all({
                    provider: call(request, ...[getOwnProvider, options]),
                    enrolments: call(request, ...[getAllEnrolments, options]),
                    cohorts: call(request, ...[getAllCohorts, options]),
                    payments: call(request, ...[getAllPayments, options]),
                    funds: call(request, ...[getAllFunds, options]),
                });

                yield put(setProvider(provider));
                yield put(setEnrolments(enrolmentsForProvider));
                yield put(setCohorts(cohortsForProvider));
                yield put(setPayments(paymentsForProvider));
                yield put(setFunds(fundsForProvider));

                break;
            case 'CEC':
                const {
                    enrolments: {response: {data: enrolments}},
                    cohorts: {response: {data: cohorts}},
                    providers: {response: {data: providers}},
                    payments: {response: {data: payments}},
                    failedPaymentProcesses: {response: {data: failedPaymentProcesses}},
                    funds: {response: {data: funds}},
                } = yield all({
                    enrolments: call(request, ...[getAllEnrolments, options]),
                    cohorts: call(request, ...[getAllCohorts, options]),
                    providers: call(request, ...[getAllProviders, options]),
                    payments: call(request, ...[getAllPayments, options]),
                    failedPaymentProcesses: call(request, ...[getAllFailedPaymentProcesses, options]),
                    funds: call(request, ...[getAllFunds, options]),
                });

                yield put(setEnrolments(enrolments));
                yield put(setCohorts(cohorts));
                yield put(setProviders(providers));
                yield put(setPayments(payments));
                yield put(setFailedPaymentProcesses(failedPaymentProcesses));
                yield put(setFunds(funds));

                break;
            default:
                return;
        }
    }
    catch (ex) {
        console.log(ex);
        yield spawn(notify, 'warning', problemLoadingMessage);
        // Don't do this, it results in a loop with the new login arrangement
        // yield call(loggedOut);
    }
}

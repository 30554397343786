import React from "react";
import FormContext from "../FormContext";
import validator from "./validator";

export default Component => ({field, id, icon, label, placeholder, showError, options, isGrouped, isMulti, formatOptionLabel, checkboxLabel, isClearable, required=false, maxChars=null, disabled, filterOption, onInputChange, isLoading, noFutureDate, yupSchema, minDate, maxDate, ...rest}) =>
    <FormContext.Consumer>
        {({formState, updater}) => <Component {
            ...{
                field,
                label,
                id,
                options,
                isGrouped,
                isMulti,
                icon,
                placeholder,
                showError,
                formatOptionLabel,
                checkboxLabel,
                isClearable,
                required,
                maxChars,
                disabled,
                filterOption,
                onInputChange,
                isLoading,
                noFutureDate,
                minDate,
                maxDate,
                value: formState[field] && formState[field].value,
                isDirty: formState[field] && formState[field].isDirty,
                error: formState[field] && formState[field].error,
                updateForm: value => updater(field, value, validator(value, required, maxChars, noFutureDate, yupSchema)),
                ...rest
            }
        } />}
    </FormContext.Consumer>;

import {call, put} from "redux-saga/effects";
import deltas from "../../redux/actions/deltas";
import {refreshToken} from "../../auth";

const {
    setToken,
} = deltas.actionCreators;

export default function* tryToRefreshToken() {
    try {
        const token = yield call(refreshToken);

        yield put(setToken(token));
    } catch (e) {
        return false;
    }

    return true;
}

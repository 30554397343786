import createFormReducer from "../../helpers/createFormReducer";
import fields from "../../fields/courseInformation";
import deltas from "../../actions/deltas";

const {
    UPDATE_COURSE_INFORMATION_FORM,
    SET_COURSE_INFORMATION_FORM,
    CLEAR_COURSE_INFORMATION_FORM,
} = deltas.actionTypes;

export default createFormReducer(fields, UPDATE_COURSE_INFORMATION_FORM, SET_COURSE_INFORMATION_FORM, CLEAR_COURSE_INFORMATION_FORM);

import React from "react";
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import {DateRange} from 'react-date-range';
import {enGB} from 'date-fns/locale';

export default ({filtersApplied: {range}, setDateFilter, clearDateFilter}) => <React.Fragment>
    <DateRange
        locale={enGB}
        showMonthAndYearPickers={false}
        shownDate={new Date()}
        ranges={[range]}
        onChange={({selection}) => setDateFilter(selection)}
    />
    <button onClick={clearDateFilter} className="cec-btn font-12 text-right">Clear</button>
</React.Fragment>;

import React from "react";
import {connect} from "react-redux";
import PollingAlertMessage from "./PollingAlertMessage";
import {
    selectIsPollingProcessesPending,
    selectIsProcessesPending
} from "../../redux/reducers/states/pollingPaymentProcesses";

const mapStateToProps = state => ({
    isLoading: selectIsPollingProcessesPending(state),
    showAlert: selectIsProcessesPending(state),
    message: 'There are enrolments that are currently in a queue to be processed. Therefore the data available to download may not be up to date yet. Please wait a moment until this message no longer displays and the data available to download will then be up-to-date.',
});

export default connect(mapStateToProps)(PollingAlertMessage);

import createStateReducer from "../../helpers/createStateReducer";
import pollingEnrolmentsAndCohortsStates from "../../states/pollingEnrolmentsAndCohorts";
import deltas from "../../actions/deltas";
import React from "react";

const {SET_POLLING_ENROLMENTS_AND_COHORTS_STATE} = deltas.actionTypes;

export default createStateReducer(pollingEnrolmentsAndCohortsStates, null, SET_POLLING_ENROLMENTS_AND_COHORTS_STATE);

export const selectIsUpdateNeeded = (state) => pollingEnrolmentsAndCohortsStates.UPDATE_NEEDED === state.states.pollingEnrolmentsAndCohorts.state;

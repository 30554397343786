import AlertMessage from "../../../atoms/AlertMessage";
import React from "react";

const PreviouslyCompletedCourseAlertMessage = () => <div className="mb-4">
    <AlertMessage>
        <p className="mb-0">
            You have completed the Careers Leadership Training course below.
            You can now select our Enhanced Careers Leaders course which will provide you with a top-up of the most recent careers leadership developments and enhance your strategic and operational skills and knowledge.
        </p>
    </AlertMessage>
</div>;

export default PreviouslyCompletedCourseAlertMessage;

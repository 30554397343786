import gridsConfirmationModalTypeStates from "../../../../redux/states/gridsConfirmationModalType";
import React from "react";
import {connect} from "react-redux";
import FormContext from "../../../FormContext";
import inputs from "../../../molecules/inputsWithContextAndSurrounds";
import deltas from "../../../../redux/actions/deltas";
import fields from "../../../../redux/fields/applyCohort";
import AccountMultipleIcon from "mdi-react/AccountMultipleIcon";
import {GridsConfirmationModal} from "./GridsConfirmationModal";
import {BulkUpdateGridsConfirmationErrors} from "./BulkUpdateGridsConfirmationErrors";

const {
    SelectInput,
} = inputs;

const {
    APPLY_COHORT,
} = gridsConfirmationModalTypeStates;

const {
    updateApplyCohortForm,
} = deltas.actionCreators;

const mapStateToProps = (state) => ({
    formState: state.forms.applyCohort,
    cohortOptions: Object.entries(state.data.cohorts)
        .map(([id, {name}]) => ({
            label: name,
            value: parseInt(id),
        })),
});

const mapDispatchToProps = {
    updateApplyCohortForm,
};

const BulkApplyCohortConfirmationModal = ({formState, cohortOptions, updateApplyCohortForm}) =>
    <GridsConfirmationModal
        title={'Apply this cohort'}
        saveButtonText={'Apply cohort'}
        type={APPLY_COHORT}
    >
        <FormContext.Provider value={{formState, updater: updateApplyCohortForm}}>
            <SelectInput
                id="o-cohort"
                label="Choose the cohort you wish to apply to the selected enrolments"
                placeholder="Select cohort"
                icon={<AccountMultipleIcon/>}
                options={cohortOptions}
                isGrouped={false}
                field={fields.COHORT_ID}
                required
            />
        </FormContext.Provider>
        {/*TODO: Consider loading animation when waiting for request*/}
        {/*TODO: Disable button when clicked*/}
        <BulkUpdateGridsConfirmationErrors

        />
    </GridsConfirmationModal>

export default connect(mapStateToProps, mapDispatchToProps)(BulkApplyCohortConfirmationModal);

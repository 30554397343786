import {call, delay, put, select} from "@redux-saga/core/effects";
import deltas from "../../../../redux/actions/deltas";
import request from "../../../helpers/request";
import {pollEnrolmentsAndCohorts as pollEnrolmentsAndCohortsRequest} from "../../../../api";
import pollingEnrolmentsAndCohortsStates from "../../../../redux/states/pollingEnrolmentsAndCohorts"
import {
    selectCountOfEnrolments,
    selectMaxUpdatedAtFromEnrolments,
} from "../../../../redux/reducers/data/enrolments";
import {
    selectCountOfCohorts,
    selectMaxUpdatedAtFromCohorts,
} from "../../../../redux/reducers/data/cohorts";
import {selectIsUpdateNeeded} from "../../../../redux/reducers/states/pollingEnrolmentsAndCohorts";

const {
    setPollingEnrolmentsAndCohortsState,
} = deltas.actionCreators;

const {
    POLLING,
    UPDATE_NEEDED,
    NO_UPDATE_NEEDED,
} = pollingEnrolmentsAndCohortsStates;

const DELAY_BETWEEN_POLLS_IN_MS = 60000; // 60 seconds

export default function* pollEnrolmentsAndCohorts(skipInitialPoll = false)
{
    let skipPoll = skipInitialPoll;

    while (true) {

        const isUpdateNeeded = yield select(selectIsUpdateNeeded);

        if (false === isUpdateNeeded && false === skipPoll) {
            yield put(setPollingEnrolmentsAndCohortsState(POLLING));

            try {
                const {response, error} = yield call(request, pollEnrolmentsAndCohortsRequest) || {};

                if (undefined === error) {
                    const {enrolment: newEnrolmentsData, cohort: newCohortsData} = response.data;

                    const currentEnrolmentsCount = yield select(selectCountOfEnrolments);
                    const currentEnrolmentsMaxUpdatedAt = yield select(selectMaxUpdatedAtFromEnrolments);
                    const currentCohortsCount = yield select(selectCountOfCohorts);
                    const currentCohortsMaxUpdatedAt = yield select(selectMaxUpdatedAtFromCohorts);

                    const updateToEnrolmentsNeeded = currentEnrolmentsCount !== newEnrolmentsData.count ||
                        null !== newEnrolmentsData.maxUpdatedAt &&
                        (
                            null === currentEnrolmentsMaxUpdatedAt ||
                            new Date(newEnrolmentsData.maxUpdatedAt) > new Date(currentEnrolmentsMaxUpdatedAt)
                        )
                    ;

                    const updateToCohortsNeeded = currentCohortsCount !== newCohortsData.count ||
                        null !== newCohortsData.maxUpdatedAt &&
                        (
                            null === currentCohortsMaxUpdatedAt ||
                            new Date(newCohortsData.maxUpdatedAt) > new Date(currentCohortsMaxUpdatedAt)
                        )
                    ;

                    if (updateToEnrolmentsNeeded || updateToCohortsNeeded) {
                        yield put(setPollingEnrolmentsAndCohortsState(UPDATE_NEEDED));
                    } else {
                        yield put(setPollingEnrolmentsAndCohortsState(NO_UPDATE_NEEDED));
                    }
                }
            } catch {
            }
        }

        yield delay(DELAY_BETWEEN_POLLS_IN_MS);

        skipPoll = false;
    }
}

import createReducer from "../helpers/createReducer";
import dateObjectFromUkTextDate from "./dateObjectFromUkTextDate";

const gridDataFromData = data => data
    ? Object.values(data).map(row => Object.assign(...Object.entries(row).map(([key, value]) => ({
        [key]: null === value
            ? ''
            : true === value
                ? 'Yes'
                : false === value
                    ? 'No'
                    : value,
    }))))
    : [];

const dateFilterInitialState = {
    active: false,
    range: {
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
    },
};

const filtersAfterSetDateFilter = (state, column, range) => ({
    ...state.filters,
    [column]: {
        active: true,
        range,
    },
});
const filtersAfterClearDateFilter = (state, column) => ({
    ...state.filters,
    [column]: dateFilterInitialState,
});
const filtersAfterAdd = (state, column, value) => ({
    ...state.filters,
    [column]: state.filters[column]
        ? [...state.filters[column], value]
        : [value],
});
const filtersAfterRemove = (state, column, value) => ({
    ...state.filters,
    [column]: state.filters[column]
        ? state.filters[column].filter(existingValue => value !== existingValue)
        : [],
});
const filtersAfterClear = (state, column) => ({
    ...state.filters,
    [column]: [],
});

export const SORT_ASC = 'ASC';
export const SORT_DESC = 'DESC';

const sorterCycleValues = [
    '',
    SORT_ASC,
    SORT_DESC,
    '',
];

const getNextCycleValue = (sorters, column) => sorterCycleValues[
sorterCycleValues.indexOf(
    (sorters.find(sorter => column === sorter.column) || {direction: ''}).direction
) + 1
    ];

const sortersAfterCycle = (state, column) => ([
    ...state.sorters.filter(sorter => column !== sorter.column),
    ...('' !== getNextCycleValue(state.sorters, column)
            ? [{
                column,
                direction: getNextCycleValue(state.sorters, column),
            }]
            : []
    ),
]);

const sortTextOrDate = (a, b, isDate) =>
    (([x, y]) => (x != null ? String(x) : '').localeCompare(y, undefined, {numeric: true}))(
        isDate
            ? [dateObjectFromUkTextDate(b).getTime().toString(), dateObjectFromUkTextDate(a).getTime().toString()]
            : [b, a]
    );

const isDateInRange = (dateAsText, range) =>
    dateObjectFromUkTextDate(dateAsText).setHours(0, 0, 0, 0) >= range.startDate &&
    dateObjectFromUkTextDate(dateAsText).setHours(0, 0, 0, 0) <= range.endDate;

export default (initialData, initialSorters, initialColumnsToHide, dateColumns, setData, addValueToTextFilter, removeValueFromTextFilter, clearTextFilter, cycleColumnSortState, setDateFilter, clearDateFilter, resetFilterAndSort, hideColumn, showColumn, showAllColumns=null, setRowSelected=null, unsetRowSelected=null, clearRowsSelected=null, setAllVisibleRowsSelected=null) => {

    const emptyFiltersAndSort = {
        filters: {...Object.assign({}, ...dateColumns.map(columnName => ({
                [columnName]: dateFilterInitialState,
            })))},
        sorters: [],
    };

    const filteredSortedData = (data, filters, sorters) => data
        .filter(
            row => Object.entries(row).every(([columnName, value]) =>
                !filters[columnName] || (
                    dateColumns.includes(columnName)
                        ? !filters[columnName].active || isDateInRange(value, filters[columnName].range)
                        : !filters[columnName].length || filters[columnName].includes(value)
                )
            )
        )
        .sort((a, b) => sorters.length
            ? recursiveSort(a, b, sorters, 0)
            : 0
        );

    const initialSort = data => filteredSortedData(data, {}, initialSorters);

    const recursiveSort = (a, b, sorters, index) =>
        ({
            [-1]: () => -1,
            0: () => (index === sorters.length - 1)
                ? 0 // Just return equivalent if there are no more sorters
                : recursiveSort(a, b, sorters, index + 1), // Go to the next sorter if there is one
            1: () => 1,
        }[
        (SORT_ASC === sorters[index].direction
                ? -1 // Inverse the result of localeCompare if we want sort ascending
                : 1
        ) * sortTextOrDate(a[sorters[index].column], b[sorters[index].column], dateColumns.includes(sorters[index].column))
            ])();

    return createReducer({
            data: initialSort(gridDataFromData(initialData)),
            ...emptyFiltersAndSort,
            filteredSortedData: filteredSortedData(initialSort(initialData), {}, []),
            columnsToHide: initialColumnsToHide,
            selectedRows: [],
        },
        {
            [setData]: (state, {data}) => ({
                ...state,
                data: initialSort(gridDataFromData(data)),
                filteredSortedData: filteredSortedData(initialSort(gridDataFromData(data)), state.filters, state.sorters),
            }),
            [addValueToTextFilter]: (state, {column, value}) => ({
                ...state,
                filters: filtersAfterAdd(state, column, value),
                filteredSortedData: filteredSortedData(state.data, filtersAfterAdd(state, column, value), state.sorters),
                selectedRows: filteredSortedData(state.data, filtersAfterAdd(state, column, value), state.sorters)
                    .map(row => row.id)
                    .filter(id => state.selectedRows.includes(id)),
            }),
            [removeValueFromTextFilter]: (state, {column, value}) => ({
                ...state,
                filters: filtersAfterRemove(state, column, value),
                filteredSortedData: filteredSortedData(state.data, filtersAfterRemove(state, column, value), state.sorters),
            }),
            [clearTextFilter]: (state, {column}) => ({
                ...state,
                filters: filtersAfterClear(state, column),
                filteredSortedData: filteredSortedData(state.data, filtersAfterClear(state, column), state.sorters),
            }),
            [cycleColumnSortState]: (state, {column}) => ({
                ...state,
                sorters: sortersAfterCycle(state, column),
                filteredSortedData: filteredSortedData(state.data, state.filters, sortersAfterCycle(state, column)),
            }),
            [setDateFilter]: (state, {column, range}) => ({
                ...state,
                filters: filtersAfterSetDateFilter(state, column, range),
                filteredSortedData: filteredSortedData(state.data, filtersAfterSetDateFilter(state, column, range), state.sorters),
                selectedRows: filteredSortedData(state.data, filtersAfterSetDateFilter(state, column, range), state.sorters)
                    .map(row => row.id)
                    .filter(id => state.selectedRows.includes(id)),
            }),
            [clearDateFilter]: (state, {column}) => ({
                ...state,
                filters: filtersAfterClearDateFilter(state, column),
                filteredSortedData: filteredSortedData(state.data, filtersAfterClearDateFilter(state, column), state.sorters),
            }),
            [resetFilterAndSort]: state => ({
                ...state,
                ...emptyFiltersAndSort,
                filteredSortedData: filteredSortedData(state.data, emptyFiltersAndSort.filters, emptyFiltersAndSort.sorters),
                selectedRows: [],
            }),
            [hideColumn]: (state, {column}) => ({
                ...state,
                columnsToHide: [...new Set([...state.columnsToHide, column])],
            }),
            [showColumn]: (state, {column}) => ({
                ...state,
                columnsToHide: state.columnsToHide.filter(hiddenColumn => column !== hiddenColumn),
            }),
            ...(showAllColumns && {
                [showAllColumns]: (state) => ({
                    ...state,
                    columnsToHide: [],
                }),
            }),
            ...(setRowSelected && {
                [setRowSelected]: (state, {id}) => ({
                    ...state,
                    selectedRows: [...new Set([...state.selectedRows, id])],
                }),
            }),
            ...(unsetRowSelected && {
                [unsetRowSelected]: (state, {id}) => ({
                    ...state,
                    selectedRows: state.selectedRows.filter(rowId => id !== rowId),
                }),
            }),
            ...(clearRowsSelected && {
                [clearRowsSelected]: (state) => ({
                    ...state,
                    selectedRows: [],
                }),
            }),
            ...(setAllVisibleRowsSelected && {
                [setAllVisibleRowsSelected]: (state) => ({
                    ...state,
                    selectedRows: filteredSortedData(state.data, state.filters, state.sorters).map(row => row.id),
                }),
            }),
        },
    )
};

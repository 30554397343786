import {take, put, select, call, fork, spawn} from "redux-saga/effects";
import redirect from "../../redux/helpers/redirect";

import appStates from "../../redux/states/app";
import deltas from "../../redux/actions/deltas";
import signals from "../../redux/actions/signals";
import routes from "../../redux/routes";

import loggingIn from "./pages/login/loggingIn";
import loggedIn from "./loggedIn";
import watchForLogout from "../helpers/watchForLogout";
import loading from "./loading";
import forgotPassword from "./pages/login/forgotPassword";
import {FORGOT_PASSWORD_OPTIONAL_PATH} from "../../redux/routesMap";

const {
    setAppState,
} = deltas.actionCreators;

const {
    SUBMIT_LOGIN_FORM,
    OPEN_FORGOT_PASSWORD_MODAL,
} = signals.actionTypes;

export default function* loggedOut() {
    const location = yield select(state => state.location);

    yield put(redirect(routes.LOGIN));

    if (FORGOT_PASSWORD_OPTIONAL_PATH === location.payload['0']) {
        yield put(setAppState(appStates.LOGGED_OUT));
        yield call(forgotPassword);
    }

    while (true) {
        yield put(setAppState(appStates.LOGGED_OUT));

        const {type, email: forgotPasswordEmail} = yield take([SUBMIT_LOGIN_FORM, OPEN_FORGOT_PASSWORD_MODAL]);

        switch (type) {
            case SUBMIT_LOGIN_FORM:
                const success = yield call(loggingIn);

                if (!success) {
                    continue;
                }

                yield call(loading);
                const loggedInTask = yield fork(loggedIn);
                yield spawn(watchForLogout, loggedInTask);

                return;
            case OPEN_FORGOT_PASSWORD_MODAL:
                yield call(forgotPassword, forgotPasswordEmail);
                break;
            default:
                break;
        }
    }
}

import EmailInput from "../atoms/EmailInput";
import withContext from "./withContext";
import withSurrounds from "./withSurrounds";
import PasswordInput from "../atoms/PasswordInput";
import TextInput from "../atoms/TextInput";
import DateInput from "../atoms/DateInput";
import SelectInput from "../atoms/SelectInput";
import CheckboxInput from "../atoms/CheckboxInput";
import BooleanRadioInput from "../atoms/BooleanRadioInput";

export default {...Object.assign(...Object.entries({
    EmailInput: EmailInput,
    PasswordInput: PasswordInput,
    TextInput: TextInput,
    DateInput: DateInput,
    SelectInput: SelectInput,
    CheckboxInput: CheckboxInput,
    BooleanRadioInput: BooleanRadioInput,
}).map(([name, Component]) => ({
    [name]: withContext(withSurrounds(Component)),
})))};

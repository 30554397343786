import createDataReducer from "../../helpers/createDataReducer";
import deltas from "../../actions/deltas";
import {createSelector} from "reselect";

const {
    SET_FAILED_PAYMENT_PROCESSES,
    UPDATE_FAILED_PAYMENT_PROCESS,
    CLEAR_FAILED_PAYMENT_PROCESSES,
    DELETE_FAILED_PAYMENT_PROCESS,
} = deltas.actionTypes;

export default createDataReducer(
    SET_FAILED_PAYMENT_PROCESSES,
    UPDATE_FAILED_PAYMENT_PROCESS,
    CLEAR_FAILED_PAYMENT_PROCESSES,
    DELETE_FAILED_PAYMENT_PROCESS,
);

export const selectFailedPaymentProcesses = state => state.data.failedPaymentProcesses;

export const selectFailedPaymentProcessById = id => state => state.data.failedPaymentProcesses[id];

export const selectFailedPaymentProcessMoreInfoById = id => state => state.data.failedPaymentProcesses[id].moreInfo;

export const selectCountOfFailedPaymentProcesses = createSelector(
    selectFailedPaymentProcesses,
    (failedPaymentProcesses) => Object.keys(failedPaymentProcesses).length,
);

export const selectMaxFailedAtFromFailedPaymentProcesses = createSelector(
    selectFailedPaymentProcesses,
    (failedPaymentProcesses) => Object.values(failedPaymentProcesses)
        .reduce((maxFailedAt, {failedAt}) => {
            const failedAtDateObject = new Date(failedAt);

            if (null === maxFailedAt || failedAtDateObject > maxFailedAt) {
                return failedAtDateObject;
            }

            return maxFailedAt;
        }, null),
);

import React from "react";
import Tippy from "@tippyjs/react";

export default ({title, collapse, setCollapse, disabled}) =>
    <div className="d-flex border-bottom pb-4 px-4 px-md-5">
        <h5 className="font-16 my-auto">
            {title}
        </h5>
        <button className="button-link link-teal-to-gray ml-auto my-auto" data-cy={`collapse-expand-${title}`} {...{disabled}} onClick={(event) => {
            event.preventDefault();
            setCollapse(!collapse);
        }}>
            <Tippy
                content="Only available if contract signed"
                placement="top"
                animation="fade"
                arrow theme="light-border"
                appendTo={() => document.body}
                disabled={!disabled}
            >
                <span>{collapse ? 'Collapse' : 'Expand'}</span>
            </Tippy>
        </button>
    </div>;

import FormContext from "../../../FormContext";
import PhoneIcon from "mdi-react/PhoneIcon";
import ReadOnlyField from "../../../atoms/ReadOnlyField";
import ModalMessage from "../../../molecules/ModalMessage";
import React from "react";
import inputs from "../../../molecules/inputsWithContextAndSurrounds";
import {connect} from "react-redux";
import deltas from "../../../../redux/actions/deltas";
import fields from "../../../../redux/fields/personalInformation";
import signals from "../../../../redux/actions/signals";
import personalInformationModalStates from "../../../../redux/states/personalInformationModal";
import AlertMessage from "../../../atoms/AlertMessage";

const {EmailInput, TextInput, SelectInput} = inputs;

const {
    updatePersonalInformationForm,
} = deltas.actionCreators;

const {
    closePersonalInformationModal,
    submitPersonalInformationForm,
} = signals.actionCreators;

const {
    OPEN_STATIC,
    REQUESTING,
} = personalInformationModalStates;

const mapStateToProps = state => ({
    formState: state.forms.personalInformation,
    isPersonalInformationModalOpen: [OPEN_STATIC, REQUESTING].includes(state.states.personalInformationModal.state),
    isPersonalInformationModalRequesting: REQUESTING === state.states.personalInformationModal.state,
    email: state.data.profile.email,
    firstName: state.data.profile.firstName,
    surname: state.data.profile.lastName,
});

const mapDispatchToProps = {
    updatePersonalInformationForm,
    closePersonalInformationModal,
    submitPersonalInformationForm,
};

const PersonalInformationModal = ({formState, updatePersonalInformationForm, isPersonalInformationModalOpen, isPersonalInformationModalRequesting, closePersonalInformationModal, submitPersonalInformationForm, email, firstName, surname}) =>
    <ModalMessage
        title="Edit your Personal Information"
        isOpen={isPersonalInformationModalOpen}
        isRequesting={isPersonalInformationModalRequesting}
        closeModal={closePersonalInformationModal}
        submitForm={submitPersonalInformationForm}
    >
        <FormContext.Provider value={{formState, updater: updatePersonalInformationForm}}>
            <div className="mb-3">
                <ReadOnlyField value={firstName} label={"First Name"} />
            </div>
            <div className="mb-3">
                <ReadOnlyField value={surname} label={"Surname"} />
            </div>
            <div className="mb-3">
                <ReadOnlyField value={email} label={"Email"} />
                <p className="font-14 text-muted mb-0 mt-0 font-italic">All usage of data provided is subject to consent.</p>
            </div>
            <div className="mb-3">
                <TextInput id="o-profilePhone" label="Phone Number (Optional)" placeholder="Enter your phone number" icon={<PhoneIcon />}  field={fields.PHONE} maxChars={25}/>
                <p className="font-14 text-muted mb-0 mt-0 font-italic">All usage of data provided is subject to consent.</p>
            </div>
            <AlertMessage>
                <p className="mb-0">
                    Please note that your name and email address are associated with your Compass account and cannot be changed here.
                    If you have a new email address, use the "School and Login Information" button on the main screen.
                </p>
            </AlertMessage>
        </FormContext.Provider>
    </ModalMessage>;

export default connect(mapStateToProps, mapDispatchToProps)(PersonalInformationModal);

import {call, fork, put, select} from "redux-saga/effects";
import deltas from "../../redux/actions/deltas";
import loading from "./loading";
import routes from "../../redux/routes";
import redirect from "../../redux/helpers/redirect";
import appStates from "../../redux/states/app";
import tryToRefreshToken from "../helpers/tryToRefreshToken";
import loggedOut from "./loggedOut";
import appLogin from "./appLogin";

const {
    stopImpersonating,
    setAppState,
} = deltas.actionCreators;

export default function* endImpersonate() {
    const {isImpersonating} = yield select(state => state.impersonation);

    if (!isImpersonating) {
        return;
    }

    yield put(setAppState(appStates.LOADING));
    yield put(stopImpersonating());

    // Make sure we're still authenticated (and set the role back to CEC if so)
    const success = yield call(tryToRefreshToken);

    if (!success) {
        return yield fork(loggedOut);
    }

    try {
        yield call(appLogin);

        // Reload all the app data from the perspective of the CEC admin user
        yield call(loading,true);
    } catch (e) {
        return yield fork(loggedOut);
    }

    yield put(redirect(routes.ENROLMENTS));
    yield put(setAppState(appStates.LOGGED_IN));
}

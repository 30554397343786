import React from 'react';
import {connect} from 'react-redux';
import Loading from "./components/pages/Loading";
import getPageForRoute from "./components/getPageForRoute";
import Layout from "./components/Layout";
import appStates from './redux/states/app';
import {UNAUTHENTICATED} from "./redux/routesAllowed";
import routes from "./redux/routes";

//   -------------------------------------------------------
//   Bootstrap
//   -------------------------------------------------------
require('bootstrap');

const {
    LOGIN,
    RESET_PASSWORD
} = routes;

const mapStateToProps = state => {
    const {
        role,
        location: {type: locationType},
        states: {app: {state: appState}},
    } = state;

    return {
        role,
        locationType,
        isAppLoading: appStates.TRYING_INITIAL_LOGIN === appState || appStates.LOADING === appState,
        isLogin: LOGIN === locationType,
        isResetPassword: RESET_PASSWORD === locationType,
    }
};

const App = ({role, locationType, isAppLoading, isLogin, isResetPassword}) => <Layout pageClass={isLogin ? 'login' : isResetPassword ? 'reset-password' : null}>
    {isAppLoading
        ? <Loading {...{isLogin}} />
        : getPageForRoute(locationType, role || UNAUTHENTICATED)
    }
</Layout>;

export default connect(mapStateToProps)(App);

import {put, select, call, take, spawn} from "@redux-saga/core/effects";
import {
    bulkUpdateCohort,
    bulkUpdateCompleted,
    bulkUpdateContractIssued,
} from "../../../../../api";
import request from "../../../../helpers/request";
import deltas from "../../../../../redux/actions/deltas";
import applyCohortFields from "../../../../../redux/fields/applyCohort";
import genericBulkActionFields from "../../../../../redux/fields/genericBulkAction";
import signals from "../../../../../redux/actions/signals";
import modalTypeStates from "../../../../../redux/states/gridsConfirmationModalType";
import gridsConfirmationModalStates from "../../../../../redux/states/gridsConfirmationModal";
import isFormDataClean from "../../../../helpers/isFormDataClean";
import notify from "../../../../helpers/notify";
import getFieldValuesFromFormData from "../../../../helpers/getFieldValuesFromFormData";

const {
    OPEN_STATIC,
    REQUESTING,
    CLOSED,
} = gridsConfirmationModalStates;

const {
    clearApplyCohortForm,
    setGridsConfirmationModalState,
    setGridsConfirmationModalTypeState,
    setEnrolments,
    clearEnrolmentsGridRowsSelected,
    setGridsConfirmationModalErrors,
} = deltas.actionCreators;

const {
    TRY_TO_MARK_CONTRACTS_AS_ISSUED,
    TRY_TO_MARK_COURSES_AS_COMPLETE,
    TRY_TO_APPLY_COHORT_TO_ENROLMENTS,
    SUBMIT_GRIDS_CONFIRMATION_MODAL,
    CLOSE_GRIDS_CONFIRMATION_MODAL,
} = signals.actionTypes;

const areYouSureMessage = 'Are you sure? You have unsaved changes.';
const failureMessage = 'There was a problem applying this update';
const successMessage = 'Your enrolments were successfully updated';

export default function* confirmBulkAction(modalActionType) {
    const selectedRowIds = yield select(state => state.enrolmentsGrid.selectedRows);
    const visibleRowIds = yield select(state => state.enrolmentsGrid.filteredSortedData.map(row => row.id));

    const ids = visibleRowIds.filter(id => selectedRowIds.includes(id));

    if (0 === ids.length) {
        alert('Please select at least one enrolment.');
        return;
    }

    if (TRY_TO_APPLY_COHORT_TO_ENROLMENTS === modalActionType) {
        yield put(clearApplyCohortForm());
    }

    yield put(setGridsConfirmationModalTypeState(
        {
            [TRY_TO_MARK_CONTRACTS_AS_ISSUED]: modalTypeStates.MARK_CONTRACT_ISSUED,
            [TRY_TO_MARK_COURSES_AS_COMPLETE]: modalTypeStates.MARK_COMPLETED,
            [TRY_TO_APPLY_COHORT_TO_ENROLMENTS]: modalTypeStates.APPLY_COHORT,
        }[modalActionType]
    ));

    yield put(setGridsConfirmationModalState(OPEN_STATIC));

    while (true) {

        const {type} = yield take([SUBMIT_GRIDS_CONFIRMATION_MODAL, CLOSE_GRIDS_CONFIRMATION_MODAL]);
        const formData = yield select(state => state.forms.applyCohort);

        switch (type) {
            case SUBMIT_GRIDS_CONFIRMATION_MODAL:
                yield put(setGridsConfirmationModalState(REQUESTING));

                if (TRY_TO_APPLY_COHORT_TO_ENROLMENTS === modalActionType && formData._hasErrors) {
                    yield call(notify, 'warning', 'Please fix the form errors before continuing');
                    break;
                }

                const fieldsForType = TRY_TO_APPLY_COHORT_TO_ENROLMENTS === modalActionType ? applyCohortFields : genericBulkActionFields;

                const formDataForRequest = Object.assign(
                    ...Object.entries(formData)
                        .filter(([key]) => Object.values(fieldsForType).includes(key))
                        .map(([key, value]) => ({
                            [key]: value,
                        }))
                );

                formDataForRequest[genericBulkActionFields.IDS].value = ids;

                const apiFunctionForModalActionType = {
                    [TRY_TO_MARK_CONTRACTS_AS_ISSUED]: bulkUpdateContractIssued,
                    [TRY_TO_MARK_COURSES_AS_COMPLETE]: bulkUpdateCompleted,
                    [TRY_TO_APPLY_COHORT_TO_ENROLMENTS]: bulkUpdateCohort,
                }[modalActionType];

                const result = yield call(
                    request,
                    apiFunctionForModalActionType,
                    ...[
                        {notificationOnError: {generateMessage: message => failureMessage}},
                        getFieldValuesFromFormData(formDataForRequest),
                    ]
                );

                const {response: {status, data: {enrolments, success, entities, fieldErrorsByEntityId} = {}} = {}} = result || {};

                if (400 === status && entities && fieldErrorsByEntityId) {
                    // TODO: Complete implementation of handling errors on bulk update
                    yield put(setEnrolments(entities));
                    yield put(setGridsConfirmationModalErrors(fieldErrorsByEntityId));
                    yield put(setGridsConfirmationModalState(OPEN_STATIC));

                }

                if (!enrolments) {
                    break;
                }

                yield put(setEnrolments(enrolments));

                if (!success) {
                    yield spawn(notify, 'warning', failureMessage);
                    break;
                }

                yield spawn(notify, 'success', successMessage);

                yield put(setGridsConfirmationModalState(CLOSED));
                yield put(clearEnrolmentsGridRowsSelected());
                yield put(clearApplyCohortForm());
                return;

            case CLOSE_GRIDS_CONFIRMATION_MODAL:
                if (TRY_TO_APPLY_COHORT_TO_ENROLMENTS === type || (isFormDataClean(formData) || window.confirm(areYouSureMessage))) {
                    yield put(clearApplyCohortForm());
                    yield put(setGridsConfirmationModalState(CLOSED));
                    return;
                }
                break;
            default:
        }

        yield put(setGridsConfirmationModalState(OPEN_STATIC));
    }
}

import {select} from "@redux-saga/core/effects";
import papaparse from 'papaparse';
import {providerColumns, cecColumns} from "../../../../components/organisms/enrolments/EnrolmentsSection";
import roles from "../../../../redux/roles";

const filename = () => {
    const now = new Date();

    return `enrolments-${now.getFullYear()}-${pad(now.getMonth() + 1)}-${pad(now.getDate())}.csv`;
};

const pad = text => text.toString().padStart(2, '0');

export default function* downloadCsv()
{
    const columnsToHide = yield select(state => state.enrolmentsGrid.columnsToHide);
    const role = yield select(state => state.role);

    let columns = providerColumns;

    if (roles.CEC === role) {
        columns = cecColumns;
    }

    const columnsForCSV = columns.filter(column => !columnsToHide.includes(column.accessor));

    const enrolments = yield select(state => state.enrolmentsGrid.filteredSortedData);

    const csvData = enrolments
        .map(row => ({
            ...Object.assign(...columnsForCSV.map(
                column => ({
                    [column.Header]: row[column.accessor],
                })
            )),
        }));

    const csv = papaparse.unparse(csvData);

    const blob = new Blob([csv], {type: 'text/csv'});

    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.download = filename();
    a.href = url;

    const clickHandler = () => {
        setTimeout(() => {
            URL.revokeObjectURL(url);
            a.removeEventListener('click', clickHandler);
        }, 150);
    };

    a.addEventListener('click', clickHandler, false);

    a.click();
}

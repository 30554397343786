import routes from "./routes";

export const FORGOT_PASSWORD_OPTIONAL_PATH = 'forgot-password';

export default {
    [routes.HOME]: '/',
    [routes.LOGIN]: `/login/(${FORGOT_PASSWORD_OPTIONAL_PATH})?`,
    [routes.RESET_PASSWORD]: '/reset-password',
    [routes.LOGOUT]: '/logout',
    [routes.CL_PROFILE]: '/profile',
    [routes.ENROLMENTS]: '/enrolments',
};

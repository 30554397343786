import {combineReducers} from 'redux';
import login from './forms/login';
import forgotPassword from './forms/forgotPassword';
import resetPassword from './forms/resetPassword';
import personalInformation from "./forms/personalInformation";
import courseInformation from "./forms/courseInformation";
import cohort from "./forms/cohort";
import enrolment from "./forms/enrolment";
import applyCohort from "./forms/applyCohort";
import schoolAndLogin from "./forms/schoolAndLogin";

export default combineReducers({login, forgotPassword, resetPassword, personalInformation, courseInformation, cohort, enrolment, applyCohort, schoolAndLogin});

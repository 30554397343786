import {
    call,
    take,
    cancel,
} from "redux-saga/effects";
import routes from "../../redux/routes";
import loggingOut from "../state-machines/loggingOut";

export default function* watchForLogout(loggedInTask) {
    yield take(routes.LOGOUT);
    yield cancel(loggedInTask);
    yield call(loggingOut);
}

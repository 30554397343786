import React from "react";
import {connect} from "react-redux";
import WelcomeSection from "../organisms/WelcomeSection";
import EnrolmentModal from "../organisms/enrolments/modals/EnrolmentModal";
import EnrolmentsPageTabs from "../organisms/enrolments/EnrolmentsPageTabs";
import CohortModal from "../organisms/enrolments/modals/CohortModal";
import ConfirmDeleteEnrolmentModal from "../organisms/enrolments/modals/ConfirmDeleteEnrolmentModal";
import roles from "../../redux/roles";
import FailedPaymentProcessesMoreInfoModal from "../organisms/enrolments/modals/FailedPaymentProcessesMoreInfoModal";
import BulkApplyCohortConfirmationModal from "../organisms/enrolments/modals/BulkApplyCohortConfirmationModal";
import {BulkMarkCompletedConfirmationModal} from "../organisms/enrolments/modals/BulkMarkCompletedConfirmationModal";
import {BulkMarkContractIssuedConfirmationModal} from "../organisms/enrolments/modals/BulkMarkContractIssuedConfirmationModal";
import {DeleteCohortConfirmationModal} from "../organisms/enrolments/modals/DeleteCohortConfirmationModal";
import ConfirmReleaseEnrolmentModal from "../organisms/enrolments/modals/ConfirmReleaseEnrolmentModal";

const mapStateToProps = (state) => ({
    role: state.role,
});

const Enrolments = ({role}) =>
    <React.Fragment>
        {/* Edit Enrolment Modal */}
        <EnrolmentModal/>
        {/* Confirm Delete Careers Lead Modal */}
        <ConfirmDeleteEnrolmentModal/>
        {/* Confirm Release Careers Lead Modal */}
        <ConfirmReleaseEnrolmentModal/>
        {/* Edit Cohort Modal */}
        <CohortModal/>
        {/* Confirmation Modals */}
        {roles.PROVIDER === role && <BulkApplyCohortConfirmationModal/>}
        <BulkMarkCompletedConfirmationModal/>
        <BulkMarkContractIssuedConfirmationModal/>
        <DeleteCohortConfirmationModal/>
        {/* Failed Payment Processes More Info Modal */}
        {roles.CEC === role && <FailedPaymentProcessesMoreInfoModal/>}
        {/* Content */}
        <React.Fragment>
            <WelcomeSection
                title="Welcome to the enrolments page"
                description={
                    roles.CEC === role
                        ? "You can use this page to see who has signed up to the available courses, amend their enrolment details, and impersonate Careers Leader users."
                        : "You can use this page to see who has signed up to the available courses and amend their enrolment details."
                }
            />
            <EnrolmentsPageTabs />
        </React.Fragment>
    </React.Fragment>;

export default connect(mapStateToProps)(Enrolments);

import React, {useState} from "react";
import {useFlexLayout, useResizeColumns, useTable} from "react-table";
import GridHeader from "../molecules/GridHeader";

const defaultColumn = {
    minWidth: 30,
    width: 150,
    maxWidth: 400,
};

// TODO Replace some of the enrolment-specific props with generic props
export default ({columns, rowsFromRedux, openModal, deleteRow, addValueToTextFilter, removeValueFromTextFilter, clearTextFilter, cycleColumnSortState, setDateFilter, clearDateFilter, gridStateSliceName, ActionsCell, ActionsHeader, isCec, selectedRows, setEnrolmentsGridRowSelected, unsetEnrolmentsGridRowSelected, tryToMarkContractsAsIssued, tryToMarkCoursesAsComplete, tryToApplyCohortToEnrolments, clearEnrolmentsGridRowsSelected, setEnrolmentsGridAllVisibleRowsSelected, isLoading}) => {

    const [actionColumnHover, setActionColumnHover] = useState(false);

    const data = React.useMemo(
        () => rowsFromRedux,
        [rowsFromRedux]
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable(
        {
            columns,
            data,
            defaultColumn,
        },
        useFlexLayout,
        useResizeColumns
    );

    return <React.Fragment>
        <div {...getTableProps()} className={`table${isLoading ? ' table__loading' : ''}`}>
            <div className="table__head">
                {headerGroups.map(headerGroup => (
                    <div {...headerGroup.getHeaderGroupProps()} className="table__row">
                        {
                            ActionsHeader && ActionsCell &&
                            <ActionsHeader {...{isCec, actionColumnHover, setActionColumnHover, tryToMarkContractsAsIssued, tryToMarkCoursesAsComplete, tryToApplyCohortToEnrolments, selectedRows}}/>
                        }
                        {headerGroup.headers.map(column => (
                            <div {...column.getHeaderProps()} className="head__cell">
                                <GridHeader {...{column, addValueToTextFilter, removeValueFromTextFilter, clearTextFilter, cycleColumnSortState, setDateFilter, clearDateFilter, gridStateSliceName}} />
                                {/* Use column.getResizerProps to hook up the events correctly */}
                                <div
                                    {...column.getResizerProps()}
                                    className={`resizer ${column.isResizing ? 'isResizing' : ''}`}
                                />
                            </div>
                        ))}
                    </div>
                ))}
            </div>
            <div {...getTableBodyProps()} className="table__body">
                {rows.map((row, i) => {
                    prepareRow(row);
                    return (
                        <div key={i} {...row.getRowProps()} className="table__row">
                            {
                                ActionsCell && ActionsHeader &&
                                <ActionsCell {...{openModal, deleteRow, row, actionColumnHover, setActionColumnHover, selectedRows, setEnrolmentsGridRowSelected, unsetEnrolmentsGridRowSelected, clearEnrolmentsGridRowsSelected, setEnrolmentsGridAllVisibleRowsSelected}}/>
                            }
                            {row.cells.map(cell => {
                                return (
                                    <div {...cell.getCellProps()} className="body__cell">
                                        {cell.render('Cell')}
                                    </div>
                                )
                            })}
                        </div>
                    )
                })}
                {rows.length > 0 &&
                    <div className="table__loader" />
                }
            </div>
        </div>
    </React.Fragment>;
};

import createStateReducer from "../../helpers/createStateReducer";
import pollingFailedPaymentProcessesStates from "../../states/pollingFailedPaymentProcesses";
import deltas from "../../actions/deltas";
import React from "react";

const {SET_POLLING_FAILED_PAYMENT_PROCESSES_STATE} = deltas.actionTypes;

export default createStateReducer(pollingFailedPaymentProcessesStates, null, SET_POLLING_FAILED_PAYMENT_PROCESSES_STATE);

export const selectIsUpdateNeeded = (state) => pollingFailedPaymentProcessesStates.UPDATE_NEEDED === state.states.pollingFailedPaymentProcesses.state;


// export const selectIsPollingProcessesPending = (state) => selectPollingPaymentProcessesState(state) === pollingPaymentProcessesStates.POLLING_PROCESSES_PENDING;
//
// export const selectIsProcessesPending = (state) => selectPollingPaymentProcessesState(state) === pollingPaymentProcessesStates.PROCESSES_PENDING || selectPollingPaymentProcessesState(state) === pollingPaymentProcessesStates.POLLING_PROCESSES_PENDING;

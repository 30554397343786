import React from "react";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.min.css";

export default ({value, updateForm, isClearable=null, disabled, minDate, maxDate}) => {

    return (
        <DatePicker
            className="form__field"
            selected={value && dateFromText(value)}
            onChange={date => updateForm(date ? dateToText(date) : null)}
            dateFormat={"dd/MM/yyyy"}
            isClearable={!disabled && isClearable}
            {...{disabled, minDate, maxDate}}
        />
    )
}

export const dateToText = date => `${pad(date.getDate())}/${pad(date.getMonth() + 1)}/${date.getFullYear()}`;
const pad = text => String(text).padStart(2, '0');
const dayMonthYearFromText = text => text.split('/');
const dateFromDayMonthYear = ([day, month, year]) => new Date(`${month}/${day}/${year}`);
export const dateFromText = text => dateFromDayMonthYear(dayMonthYearFromText(text));

import {combineReducers} from 'redux';
import notifications from "./notifications";
import states from "./states";
import forms from "./forms";
import data from "./data";
import {routerReducer as location} from "../router";
import token from "./token";
import provider from "./provider";
import role from "./role";
import enrolmentsGrid from "./enrolmentsGrid";
import cohortsGrid from "./cohortsGrid";
import impersonation from "./impersonation";
import paymentsGrid from "./paymentsGrid";
import failedPaymentProcessesGrid from "./failedPaymentProcessesGrid";

export default combineReducers({location, token, provider, role, notifications, states, forms, enrolmentsGrid, cohortsGrid, paymentsGrid, failedPaymentProcessesGrid, data, impersonation});

import createReducer from "./createReducer";

export default (set, update, clear, del=null) => createReducer(
    {},
    {
        [set]: (state, {data}) => ({
            ...data,
        }),
        [update]: (state, {id, data}) => ({
            ...state,
            [id]: data,
        }),
        ...(del && {
            [del]: (state, {id}) => ({
            ...Object.assign({}, ...Object.entries(state)
                .filter(([entryId]) => String(id) !== String(entryId))
                .map(([entryId, entry]) => ({
                    [entryId]: entry,
                }))),
            }),
        }),
        [clear]: () => ({}),
    },
);

import React from "react";

import CheckIcon from "mdi-react/CheckIcon";

export default ({id, name, checkboxLabel, type = "checkbox", value, updateForm, onClickOverride, disabled, ...rest}) => {
    const dataCy = rest['data-cy'];
    return <div className={("pretty p-pulse p-has-focus ") + ('checkbox' === type ? 'p-icon' : 'p-round p-default')}>
        <input
            id={id}
            name={name}
            data-cy={dataCy}
            type={type}
            checked={value}
            onClick={onClickOverride || (e => updateForm(e.target.checked))}
            {...{disabled}}
        />
        <div className="state p-default-o">
            {'checkbox' === type && (
                <CheckIcon className="icon"/>
            )}
            <label htmlFor={id}>{checkboxLabel}</label>
        </div>
    </div>;
}

import {call, put, select, spawn} from "@redux-saga/core/effects";
import deltas from "../../../../redux/actions/deltas";
import privacyStates from "../../../../redux/states/privacy";
import request from "../../../helpers/request";
import {updatePrivacyAgreed} from "../../../../api";
import notify from "../../../helpers/notify";

const {
    setPrivacyState,
    setProfile,
} = deltas.actionCreators;

const failureMessage = 'There was a problem updating your CEC privacy notice';
const forbiddenMessage = 'It is not possible to update your CEC privacy notice if you have a past or current enrolment';

export default function* updatePrivacy() {
    const isPrivacyAgreed = yield select(state => state.data.profile.isPrivacyAgreed);
    const newIsPrivacyAgreed = !isPrivacyAgreed;

    const enrolments = yield select(state => state.data.enrolments);

    if (false === newIsPrivacyAgreed && 0 !== Object.values(enrolments).length) {
        yield spawn(notify, 'warning', forbiddenMessage);
        yield put(setPrivacyState(privacyStates.STATIC));
        return;
    }

    yield put(setPrivacyState(privacyStates.REQUESTING));

    const result = yield call(request, updatePrivacyAgreed, ...[{notificationOnError: {generateMessage: message => failureMessage}}, {isPrivacyAgreed: newIsPrivacyAgreed}]);

    const {response: {data: profile} = {}} = result || {};

    if (profile) {
        yield put(setProfile(profile));
    }

    // Set the state to no longer updating privacy
    yield put(setPrivacyState(privacyStates.STATIC));
}

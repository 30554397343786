import AlertMessage from "../../../atoms/AlertMessage";
import React from "react";

const LockedProfileAlertMessage = () => <div className="mb-4">
    <AlertMessage>
        <p className="mb-1">
            You are unable to change your training course/provider at the moment because the contract has been signed with the provider.
        </p>
        <p className="mb-0">
            If required, please contact the provider or CEC to unlock these fields.
        </p>
    </AlertMessage>
</div>;

export default LockedProfileAlertMessage;

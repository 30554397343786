import {call, put, select, spawn, take} from "@redux-saga/core/effects";
import signals from "../../../../../redux/actions/signals";
import confirmDeleteEnrolmentModalStates from "../../../../../redux/states/confirmDeleteEnrolmentModal";
import deltas from "../../../../../redux/actions/deltas";
import notify from "../../../../helpers/notify";
import {deleteEnrolmentById} from "../../../../../api";
import request from "../../../../helpers/request";

const {
    setConfirmDeleteEnrolmentModalState,
    setCohorts,
    setEnrolments,
    setEnrolmentModalState,
    setEnrolmentModalIdState,
    clearEnrolmentForm,
} = deltas.actionCreators;

const {
    SUBMIT_CONFIRM_DELETE_ENROLMENT_MODAL,
    CLOSE_CONFIRM_DELETE_ENROLMENT_MODAL
} = signals.actionTypes;

const {
    OPEN_STATIC,
    REQUESTING,
    CLOSED,
} = confirmDeleteEnrolmentModalStates;

const successMessage = 'Success! This enrolment has been permanently deleted.';
const failureMessage = 'There was an error deleting this enrolment. Please try again later.';

export default function* confirmDeleteEnrolment(enrolmentId) {
    yield put(setConfirmDeleteEnrolmentModalState(OPEN_STATIC));

    while (true) {
        const {type} = yield take([SUBMIT_CONFIRM_DELETE_ENROLMENT_MODAL, CLOSE_CONFIRM_DELETE_ENROLMENT_MODAL]);
        switch (type) {
            case CLOSE_CONFIRM_DELETE_ENROLMENT_MODAL:
                yield put(setConfirmDeleteEnrolmentModalState(CLOSED));
                return false;
            case SUBMIT_CONFIRM_DELETE_ENROLMENT_MODAL:
                yield put(setConfirmDeleteEnrolmentModalState(REQUESTING));

                const result = yield call(
                    request,
                    deleteEnrolmentById,
                    ...[
                        {notificationOnError: {generateMessage: message => failureMessage}},
                        null,
                        enrolmentId,
                    ]
                );

                const {response: {data: success} = {}} = result || {};

                if (true !== success) {
                    yield put (setConfirmDeleteEnrolmentModalState(OPEN_STATIC));
                    break;
                }

                yield spawn(notify, 'success', successMessage);

                const modifiedCohortsState = yield select(state => ({
                    ...state.data.cohorts,
                }));

                const currentEnrolmentsState = yield select(state => state.data.enrolments);

                const modifiedEnrolmentsState = Object.assign(
                    {},
                    ...Object.entries(currentEnrolmentsState)
                        .filter(([id, enrolment]) => {return enrolmentId !== enrolment.id;})
                        .map(([id, enrolment]) => ({
                            [id]: enrolment,
                        }))
                );

                // Close modals before updating the state to prevent errors
                yield put(setConfirmDeleteEnrolmentModalState(CLOSED));
                yield put(clearEnrolmentForm());
                yield put(setEnrolmentModalIdState(null));
                yield put(setEnrolmentModalState(CLOSED));

                // Update state
                yield put(setCohorts(modifiedCohortsState));
                yield put(setEnrolments(modifiedEnrolmentsState));

                return true;
            default:
        }
    }
}

import React from "react";

const ReadOnlyField = ({label, value}) => {
    return (
        <>
            <p className="font-14 text-muted mb-2">
                {label}
            </p>
            <p className="mb-0" style={{height: '38px', lineHeight: '20px', verticalAlign: 'middle'}}>{!value ? <i className="text-muted">Not set</i> : value}</p>
        </>
    )
}

export default ReadOnlyField;

import gridsConfirmationModalTypeStates from "../../../../redux/states/gridsConfirmationModalType";
import React from "react";
import {GridsConfirmationModal} from "./GridsConfirmationModal";
import {BulkUpdateGridsConfirmationErrors} from "./BulkUpdateGridsConfirmationErrors";
import {useSelector} from "react-redux";
import {selectGridsConfirmationModalErrors} from "../../../../redux/reducers/states/gridsConfirmationModalErrors";
import {selectGridsConfirmationModalState} from "../../../../redux/reducers/states/gridsConfirmationModal";
import gridsConfirmationModal from "../../../../redux/states/gridsConfirmationModal";
import Loading from "../../../pages/Loading";

const {
    MARK_COMPLETED,
} = gridsConfirmationModalTypeStates;

const {
    REQUESTING,
} = gridsConfirmationModal

export const BulkMarkCompletedConfirmationModal = () => {
    const isLoading = REQUESTING === useSelector(selectGridsConfirmationModalState);
    const hasErrors = 0 < Object.keys(useSelector(selectGridsConfirmationModalErrors)).length;

    return <GridsConfirmationModal
        title={'Mark as completed'}
        saveButtonText={'Mark as completed'}
        type={MARK_COMPLETED}
    >
        {
            isLoading
                ? <Loading/>
                : hasErrors
                    ? <BulkUpdateGridsConfirmationErrors/>
                    : 'Are you sure you want to mark the selected enrolments as "Completed"?'
        }
        {/*TODO: Consider loading animation when waiting for request*/}
        {/*TODO: Disable button when clicked*/}
    </GridsConfirmationModal>
}


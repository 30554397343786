import createDataReducer from "../../helpers/createDataReducer";
import deltas from "../../actions/deltas";

const {
    SET_FUNDS,
    UPDATE_FUND,
    CLEAR_FUNDS,
} = deltas.actionTypes;

export default createDataReducer(
    SET_FUNDS,
    UPDATE_FUND,
    CLEAR_FUNDS,
);

import createReducer from "../helpers/createReducer";
import deltas from "../actions/deltas";

const {
    SET_PROVIDER,
    CLEAR_PROVIDER,
} = deltas.actionTypes;

export default createReducer(null,
    {
        [SET_PROVIDER]: (state, {data}) => data,
        [CLEAR_PROVIDER]: () => null,
    },
);

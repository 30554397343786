import React from "react";
import {SORT_ASC, SORT_DESC} from "../../redux/helpers/createGridReducer";

import MenuUpIcon from "mdi-react/MenuUpIcon";
import MenuDownIcon from "mdi-react/MenuDownIcon";

export default ({sortDirection, sortOrder, cycleSort}) => <React.Fragment>
    <span className="sort__container">
        {null !== sortDirection && <span className="sort_order">{sortOrder}</span>}
        <span className="sort__icon" onClick={cycleSort}>
            {{
                [SORT_DESC]: <MenuDownIcon size={20} />,
                [SORT_ASC]: <MenuUpIcon size={20} />,
                [null]: '',
            }[sortDirection]}
        </span>
    </span>
</React.Fragment>;

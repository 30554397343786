import {put, select, take, call, fork, spawn} from "@redux-saga/core/effects";
import deltas from "../../../../../redux/actions/deltas";
import signals from "../../../../../redux/actions/signals";
import enrolmentFields, {enrolmentFieldsToExcludeForProvider, enrolmentFieldsToExcludeForCec} from "../../../../../redux/fields/enrolment";
import enrolmentModalStates from "../../../../../redux/states/enrolmentModal";
import confirmReleaseEnrolmentModalStates from "../../../../../redux/states/confirmReleaseEnrolmentModal";
import getDataForForm from "../../../../helpers/getDataForForm";
import isFormDataClean from "../../../../helpers/isFormDataClean";
import getFieldValuesFromFormData from "../../../../helpers/getFieldValuesFromFormData";
import {updateEnrolmentById} from "../../../../../api";
import submitFormData from "../../../../helpers/submitFormData";
import roles from "../../../../../redux/roles";
import fields from "../../../../../redux/fields/enrolment";
import {dateToText} from "../../../../../components/atoms/DateInput";
import validator from "../../../../../components/molecules/validator";
import notify from "../../../../helpers/notify";
import confirmDeleteEnrolment from "./confirmDeleteEnrolment";
import {
    ENROLMENT_STATUS_ACTIVE,
    ENROLMENT_STATUS_COMPLETE,
    ENROLMENT_STATUS_INTEREST_SHOWN,
    ENROLMENT_STATUS_RELEASED,
} from "../../../../../redux/reducers/forms/enrolment";
import pollFailedPaymentProcesses from "../pollFailedPaymentProcesses";

const areYouSureMessage = 'Are you sure? You have unsaved changes.';
const failureMessage = 'There was a problem updating this enrolment';

const {
    setEnrolmentModalState,
    setEnrolmentModalIdState,
    setEnrolmentForm,
    clearEnrolmentForm,
    setEnrolments,
    setCohorts,
    updateEnrolment,
    updateEnrolmentForm,
    setConfirmReleaseEnrolmentModalState,
} = deltas.actionCreators;

const {
    UPDATE_ENROLMENT_FORM,
} = deltas.actionTypes;

const {
    SUBMIT_ENROLMENT_FORM,
    CLOSE_ENROLMENT_MODAL,
    OPEN_CONFIRM_DELETE_ENROLMENT_MODAL,
    CONFIRM_RELEASE_ENROLMENT,
    CANCEL_RELEASE_ENROLMENT,
} = signals.actionTypes;

const {
    OPEN_STATIC,
    REQUESTING,
    CLOSED,
} = enrolmentModalStates;

const DELAY_BEFORE_POLL_FAILED_PAYMENT_PROCESSES_IN_MS = 5000;

export default function* editEnrolment(id) {
    const originalEnrolment = yield select(state => state.data.enrolments[id]);

    yield put(setEnrolmentModalIdState(id));
    yield put(setEnrolmentForm(getDataForForm(originalEnrolment, enrolmentFields)));

    yield put(setEnrolmentModalState(OPEN_STATIC));

    const role = yield select(state => state.role);

    while (true) {
        const {type, field, value} = yield take([SUBMIT_ENROLMENT_FORM, UPDATE_ENROLMENT_FORM, CLOSE_ENROLMENT_MODAL, OPEN_CONFIRM_DELETE_ENROLMENT_MODAL]);
        const formData = yield select(state => state.forms.enrolment);

        switch (type) {
            case OPEN_CONFIRM_DELETE_ENROLMENT_MODAL:
                const enrolmentDeleted = yield call(confirmDeleteEnrolment, id);

                if (enrolmentDeleted) {
                    return;
                }
                break;
            case UPDATE_ENROLMENT_FORM:
                const originalStatus = yield select(state => state.data.enrolments[id].status);
                const originalStatusUpdatedAt = yield select(state => state.data.enrolments[id].statusUpdatedAt)
                let newStatus;

                if (fields.CONTRACT_SIGNED_AT === field) {
                    if (null !== value) {
                        newStatus = ENROLMENT_STATUS_ACTIVE
                    } else {
                        newStatus = ENROLMENT_STATUS_INTEREST_SHOWN;
                        yield put(updateEnrolmentForm(fields.START_DATE, null));
                        yield put(updateEnrolmentForm(fields.THEORETICAL_COMPLETION_DATE, null));
                    }
                }
                if (fields.IS_CONTRACT_ISSUED === field && true === value) {
                    const contractIssuedAt = yield select(state => state.forms.enrolment[fields.CONTRACT_ISSUED_AT].value);

                    if (null == contractIssuedAt) {
                        const now = new Date();
                        yield put(updateEnrolmentForm(fields.CONTRACT_ISSUED_AT, dateToText(now)));
                    }
                }
                if (fields.IS_COMPLETED === field) {
                    if (true === value) {
                        const completedAt = yield select(state => state.forms.enrolment[fields.COMPLETED_AT].value);

                        if (null == completedAt) {
                            const originalCompletedAt = yield select(state => state.data.enrolments[id].completedAt);

                            yield put(updateEnrolmentForm(fields.COMPLETED_AT, null !== originalCompletedAt ? originalCompletedAt : dateToText(new Date())));
                        }

                        newStatus = ENROLMENT_STATUS_COMPLETE;
                    }

                    if (false === value) {
                        yield put(updateEnrolmentForm(fields.COMPLETED_AT, null));
                        yield put(updateEnrolmentForm(fields.ASSESSMENT_OUTCOME, null))
                        yield put(updateEnrolmentForm(fields.ASSESSMENT_OUTCOME_UPDATED_AT, null))
                        newStatus = ENROLMENT_STATUS_ACTIVE;
                    }
                }
                if (fields.COMPLETED_AT === field) {
                    if (null !== value) {
                        yield put(updateEnrolmentForm(fields.IS_COMPLETED, true));
                        newStatus = ENROLMENT_STATUS_COMPLETE;
                    } else {
                        yield put(updateEnrolmentForm(fields.IS_COMPLETED, false));
                        yield put(updateEnrolmentForm(fields.ASSESSMENT_OUTCOME, null))
                        yield put(updateEnrolmentForm(fields.ASSESSMENT_OUTCOME_UPDATED_AT, null))
                        newStatus = ENROLMENT_STATUS_ACTIVE;
                    }
                }
                if (fields.COURSE_ID === field) {
                    yield put(updateEnrolmentForm(fields.REGION_ID, null, validator(null, true)));
                    yield put(updateEnrolmentForm(fields.COHORT_ID, null));

                    if (roles.CEC !== role) {
                        break;
                    }

                    if (null === value) {
                        yield put(updateEnrolmentForm(fields.PROVIDER_ID, null));
                        break;
                    }

                    const newCourse = yield select(state => state.data.courses[value]);
                    yield put(updateEnrolmentForm(fields.PROVIDER_ID, newCourse.providerId));

                    const deliveryTypeId = yield select(state => state.forms.enrolment[fields.DELIVERY_TYPE_ID].value);

                    if (null !== deliveryTypeId) {
                        const deliveryType = yield select(state => state.data.deliveryTypes[deliveryTypeId]);
                        if (!deliveryType.providers.includes(newCourse.providerId)) {
                            yield put(updateEnrolmentForm(fields.DELIVERY_TYPE_ID, null));
                        }
                    }

                    // Use in case Cohort should be kept when changing to a course from the same provider
                    // if (roles.CEC !== role) {
                    //     break;
                    // }
                    //
                    // if (!previouslySelectedCourseId) {
                    //     break;
                    // }
                    //
                    // const previouslySelectedCourse = yield select(state => state.data.courses[String(previouslySelectedCourseId)]);
                    //
                    // if (!previouslySelectedCourse) {
                    //     break;
                    // }
                    //
                    // const newCourse = yield select(state => state.data.courses[String(value)]);
                    //
                    // if (newCourse) {
                    //     if (previouslySelectedCourse.providerId === newCourse.providerId) {
                    //         break;
                    //     }
                    // }
                    //
                    // yield put(updateEnrolmentForm(fields.COHORT_ID, null));
                }
                if(fields.STATUS === field) {
                    newStatus = value;
                }
                if(fields.ASSESSMENT_OUTCOME === field) {
                    const originalAssessmentOutcome = yield select(state => state.data.enrolments[id].assessmentOutcome);
                    const originalAssessmentOutcomeUpdatedAt = yield select(state => state.data.enrolments[id].assessmentOutcomeUpdatedAt)

                    yield put(updateEnrolmentForm(fields.ASSESSMENT_OUTCOME_UPDATED_AT, originalAssessmentOutcome === value ? originalAssessmentOutcomeUpdatedAt : dateToText(new Date())));
                }

                if (undefined !== newStatus) {
                    yield put(updateEnrolmentForm(fields.STATUS, newStatus));
                    yield put(updateEnrolmentForm(fields.STATUS_UPDATED_AT, originalStatus === newStatus ? originalStatusUpdatedAt : dateToText(new Date())));
                }
                break;
            case SUBMIT_ENROLMENT_FORM:
                yield put(setEnrolmentModalState(REQUESTING));

                if (formData._hasErrors) {
                    yield call(notify, 'warning', 'Please fix the form errors before continuing');
                    break;
                }

                const originalEnrolmentStatus = yield select(state => state.data.enrolments[id].status);
                const updatedEnrolmentStatus = yield select(state => state.forms.enrolment[fields.STATUS].value);

                const newlyReleased = ENROLMENT_STATUS_RELEASED === updatedEnrolmentStatus &&
                    ENROLMENT_STATUS_RELEASED !== originalEnrolmentStatus;

                if (newlyReleased) {
                    yield put(setConfirmReleaseEnrolmentModalState(confirmReleaseEnrolmentModalStates.OPEN_STATIC));

                    const {type} = yield take([CONFIRM_RELEASE_ENROLMENT, CANCEL_RELEASE_ENROLMENT]);

                    yield put(setConfirmReleaseEnrolmentModalState(confirmReleaseEnrolmentModalStates.CLOSED));

                    if (CANCEL_RELEASE_ENROLMENT === type) {
                        break;
                    }
                }

                const formDataForRequest = {...formData};

                if (roles.PROVIDER === role) {
                    for (const fieldToExclude of enrolmentFieldsToExcludeForProvider) {
                        delete formDataForRequest[fieldToExclude];
                    }
                }

                if (roles.CEC === role) {
                    for (const fieldToExclude of enrolmentFieldsToExcludeForCec) {
                        delete formDataForRequest[fieldToExclude];
                    }
                }

                const {currentEntity: enrolment, fieldErrors} = yield call(submitFormData, ...[updateEnrolmentById, formDataForRequest, failureMessage, id]);

                if(!enrolment) {
                    break;
                }

                // TODO it would be better if the grid reducer responds to updates
                yield put(updateEnrolment(enrolment.id, enrolment));
                const enrolments = yield select(state => state.data.enrolments);
                yield put(setEnrolments(enrolments));

                // If the cohort has changed or been removed, update the old cohort's enrolments numbers
                if (enrolment.cohortId !== originalEnrolment.cohortId) {
                    const cohorts = yield select(state => state.data.cohorts);

                    const cohortsWithUpdatedEnrolmentNumbers = Object.assign(...Object.entries(cohorts).map(([id, cohort]) => ({
                        [id]: {
                            ...cohort,
                            ...(enrolment.cohortId && String(enrolment.cohortId) === String(id) && {
                                enrolments: cohort.enrolments + 1,
                            }),
                            ...(originalEnrolment.cohortId && String(originalEnrolment.cohortId) === String(id) && {
                                enrolments: Math.max(cohort.enrolments - 1, 0),
                            }),
                        },
                    })));

                    yield put(setCohorts(cohortsWithUpdatedEnrolmentNumbers));
                }

                if (fieldErrors) {
                    yield put(setEnrolmentForm(getFieldValuesFromFormData(formData), fieldErrors, false));
                    break;
                }

                yield spawn(notify, ...['success', 'Enrolment saved successfully']);

                if (roles.CEC === role) {
                    yield spawn(pollFailedPaymentProcesses, true, DELAY_BEFORE_POLL_FAILED_PAYMENT_PROCESSES_IN_MS);
                }

                yield put(setEnrolmentModalState(CLOSED));
                yield put(setEnrolmentModalIdState(null));
                yield put(clearEnrolmentForm());
                return;
            case CLOSE_ENROLMENT_MODAL:
                if (isFormDataClean(formData) || window.confirm(areYouSureMessage)) {
                    yield put(setEnrolmentModalState(CLOSED));
                    yield put(setEnrolmentModalIdState(null));
                    yield put(clearEnrolmentForm());
                    return;
                }
                break;
            default:
        }
        yield put(setEnrolmentModalState(OPEN_STATIC));
    }
}

import React, {useState} from "react";
import {connect} from "react-redux";
import EnrolmentsSection from "./EnrolmentsSection";
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import CohortsSection from "./CohortsSection";
import ImpersonationSection from "./ImpersonationSection";
import roles from "../../../redux/roles";
import PaymentsSection from './PaymentsSection';
import FailedPaymentProcessesSection from "./FailedPaymentProcessesSection";
import enrolmentsPageTabStates from "../../../redux/states/enrolmentsPageTab";
import {selectEnrolmentPageTab} from "../../../redux/reducers/states/enrolmentsPageTab";
import deltas from "../../../redux/actions/deltas";

const {setEnrolmentsPageTabState} = deltas.actionCreators

const mapStateToProps = (state) => ({
    role: state.role,
    failedPaymentProcesses: state.data.failedPaymentProcesses,
    tab: selectEnrolmentPageTab(state),
});

const mapDispatchToProps = (dispatch) => ({
    setTab: tab => dispatch(setEnrolmentsPageTabState(tab)),
});

const {
    ENROLMENTS_TAB,
    COHORTS_TAB,
    PAYMENTS_TAB,
    FAILED_PAYMENT_PROCESSES_TAB,
    IMPERSONATION_TAB,
} = enrolmentsPageTabStates

const EnrolmentsPageTabs = ({role, failedPaymentProcesses, tab, setTab}) => {
    return <div className="section__block my-4">
        <Nav tabs className="nav nav-tabs padding--negative">
            <NavItem>
                <NavLink
                    className={classnames('tabLink', { active: tab === ENROLMENTS_TAB })}
                    onClick={() => { setTab(ENROLMENTS_TAB); }}
                    data-cy="enrolments-tab"
                >
                    Enrolments
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink
                    className={classnames({ active: tab === COHORTS_TAB })}
                    onClick={() => { setTab(COHORTS_TAB); }}
                    data-cy="cohorts-tab"
                >
                    Cohorts
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink
                    className={classnames({ active: tab === PAYMENTS_TAB })}
                    onClick={() => { setTab(PAYMENTS_TAB); }}
                    data-cy="payments-tab"
                >
                    Payments
                </NavLink>
            </NavItem>
            {
                roles.CEC === role &&
                (0 !== Object.entries(failedPaymentProcesses).length || FAILED_PAYMENT_PROCESSES_TAB === tab) &&
                <NavItem>
                    <NavLink
                        className={classnames({active: tab === FAILED_PAYMENT_PROCESSES_TAB})}
                        onClick={() => { setTab(FAILED_PAYMENT_PROCESSES_TAB); }}
                    >
                        Failed Payments
                    </NavLink>
                </NavItem>
            }
            {
                roles.CEC === role &&
                <NavItem>
                    <NavLink
                        className={classnames({ active: tab === IMPERSONATION_TAB })}
                        onClick={() => { setTab(IMPERSONATION_TAB); }}
                        data-cy="impersonation-tab"
                    >
                        Impersonation
                    </NavLink>
                </NavItem>
            }
        </Nav>
        <TabContent activeTab={tab}>
            <TabPane tabId={ENROLMENTS_TAB}>
                <EnrolmentsSection/>
            </TabPane>
            <TabPane tabId={COHORTS_TAB}>
                <CohortsSection/>
            </TabPane>
            <TabPane tabId={PAYMENTS_TAB}>
                <PaymentsSection/>
            </TabPane>
            <TabPane tabId={FAILED_PAYMENT_PROCESSES_TAB}>
                <FailedPaymentProcessesSection/>
            </TabPane>
            <TabPane tabId={IMPERSONATION_TAB}>
                <ImpersonationSection/>
            </TabPane>
        </TabContent>
    </div>
};

export default connect(mapStateToProps, mapDispatchToProps)(EnrolmentsPageTabs);

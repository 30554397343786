import createFormReducer from "../../helpers/createFormReducer";
import fields from "../../fields/cohort";
import deltas from "../../actions/deltas";

const {
    UPDATE_COHORT_FORM,
    SET_COHORT_FORM,
    CLEAR_COHORT_FORM,
} = deltas.actionTypes;

export default createFormReducer(fields, UPDATE_COHORT_FORM, SET_COHORT_FORM, CLEAR_COHORT_FORM);

import createReducer from "../helpers/createReducer";
import deltas from "../actions/deltas";

const {
    PUSH_NOTIFICATION,
    UNSHIFT_NOTIFICATION,
} = deltas.actionTypes;

export default createReducer([],
    {
        [PUSH_NOTIFICATION]: (state, {style, message}) => ([
            ...state,
            ...[{style, message}],
        ]),
        [UNSHIFT_NOTIFICATION]: (state) => ([
            ...state.slice(1),
        ]),
    },
);

import {put, select, take, call} from "@redux-saga/core/effects";
import deltas from "../../../../../redux/actions/deltas";
import signals from "../../../../../redux/actions/signals";
import failedPaymentProcessesMoreInfoModalStates from "../../../../../redux/states/failedPaymentProcessesMoreInfoModal";
import {getFailedPaymentProcessesMoreInfo} from "../../../../../api";
import request from "../../../../helpers/request";
import {
    selectFailedPaymentProcessById,
    selectFailedPaymentProcessMoreInfoById
} from "../../../../../redux/reducers/data/failedPaymentProcesses";

const {
    setFailedPaymentProcessesMoreInfoModalState,
    setFailedPaymentProcessesMoreInfoModalIdState,
    updateFailedPayment,
} = deltas.actionCreators;

const {
    CLOSE_FAILED_PAYMENT_PROCESSES_MORE_INFO_MODAL,
} = signals.actionTypes;

const {
    OPEN_STATIC,
    REQUESTING,
    CLOSED,
} = failedPaymentProcessesMoreInfoModalStates;


export default function* failedPaymentProcessesMoreInfo(id) {
    yield put(setFailedPaymentProcessesMoreInfoModalIdState(id));
    yield put(setFailedPaymentProcessesMoreInfoModalState(REQUESTING));

    const existingMoreInfo = yield select(selectFailedPaymentProcessMoreInfoById(id));

    if (null == existingMoreInfo) {
        const {response, error} = yield call(request,getFailedPaymentProcessesMoreInfo, {notificationOnError: {generateMessage: message => 'Unable to load more info'}}, null, id);

        if (error || null == response.data || 200 !== response.status) {
            yield put(setFailedPaymentProcessesMoreInfoModalState(CLOSED));
            yield put(setFailedPaymentProcessesMoreInfoModalIdState(null));
            return;
        }

        const failedPaymentProcess = yield select(selectFailedPaymentProcessById(id));

        yield put(updateFailedPayment(id, {...failedPaymentProcess, moreInfo: response.data}));
    }

    yield put(setFailedPaymentProcessesMoreInfoModalState(OPEN_STATIC));

    const {type} = yield take([CLOSE_FAILED_PAYMENT_PROCESSES_MORE_INFO_MODAL]);

    switch (type) {
        case CLOSE_FAILED_PAYMENT_PROCESSES_MORE_INFO_MODAL:
            yield put(setFailedPaymentProcessesMoreInfoModalState(CLOSED));
            yield put(setFailedPaymentProcessesMoreInfoModalIdState(null));
            return;
        default:
    }
}

import {call, delay, fork, put, select} from "@redux-saga/core/effects";
import deltas from "../../../../redux/actions/deltas";
import request from "../../../helpers/request";
import {pollFailedPaymentProcesses as pollFailedPaymentProcessesRequest} from "../../../../api";
import pollingFailedPaymentProcessesStates from "../../../../redux/states/pollingFailedPaymentProcesses"
import {
    selectCountOfFailedPaymentProcesses,
    selectMaxFailedAtFromFailedPaymentProcesses
} from "../../../../redux/reducers/data/failedPaymentProcesses";
import {selectIsUpdateNeeded} from "../../../../redux/reducers/states/pollingFailedPaymentProcesses";
import reloadFailedPaymentProcesses from "./reloadFailedPaymentProcesses";

const {
    setPollingFailedPaymentProcessesState,
} = deltas.actionCreators;

const {
    POLLING,
    UPDATE_NEEDED,
    NO_UPDATE_NEEDED,
} = pollingFailedPaymentProcessesStates;

const DELAY_BETWEEN_POLLS_IN_MS = 60000; // 60 seconds

export default function* pollFailedPaymentProcesses(pollOnce = false, delayBeforePollInMs = 0)
{
    while (true) {
        yield delay(delayBeforePollInMs);

        const isUpdateNeeded = yield select(selectIsUpdateNeeded);

        if (false === isUpdateNeeded) {
            yield put(setPollingFailedPaymentProcessesState(POLLING));

            try {
                const {response, error} = yield call(request, pollFailedPaymentProcessesRequest) || {};

                if (undefined === error) {
                    const {count: newCount, maxFailedAt: newMaxFailedAt} = response.data;

                    const currentCount = yield select(selectCountOfFailedPaymentProcesses);
                    const currentMaxFailedAt = yield select(selectMaxFailedAtFromFailedPaymentProcesses);

                    const updateNeeded = currentCount !== newCount ||
                        (null !== newMaxFailedAt) &&
                        (
                            null === currentMaxFailedAt ||
                            new Date(newMaxFailedAt) > currentMaxFailedAt
                        );

                    if (updateNeeded) {
                        if (0 === currentCount) {
                            yield fork(reloadFailedPaymentProcesses);
                        } else {
                            yield put(setPollingFailedPaymentProcessesState(UPDATE_NEEDED));
                        }
                    } else {
                        yield put(setPollingFailedPaymentProcessesState(NO_UPDATE_NEEDED));
                    }
                }
            } catch {
            }
        }

        if (pollOnce) {
            return;
        }

        yield delay(DELAY_BETWEEN_POLLS_IN_MS);
    }
}

import {call, put, fork, spawn} from "redux-saga/effects";

import appStates from "../../redux/states/app";
import deltas from "../../redux/actions/deltas";

// Sagas
import loading from "./loading";
import loggedIn from "./loggedIn";
import loggedOut from "./loggedOut";
import tryToRefreshToken from "../helpers/tryToRefreshToken";
import watchForLogout from "../helpers/watchForLogout";
import appLogin from "./appLogin";

const {
    setAppState,
} = deltas.actionCreators;

export function* tryingInitialLogin() {
    try {
        yield put(setAppState(appStates.TRYING_INITIAL_LOGIN));

        const success = yield call(tryToRefreshToken);

        if (!success) {
            return yield fork(loggedOut);
        }

        yield call(appLogin);

        yield call(loading);
        const loggedInTask = yield fork(loggedIn);
        yield spawn(watchForLogout, loggedInTask);
    } catch (e) {
        return yield fork(loggedOut);
    }
}

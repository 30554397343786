import {take, put, select, delay, call, fork, spawn} from "redux-saga/effects";
import signals from "../../../../redux/actions/signals";
import deltas from "../../../../redux/actions/deltas";
import states from "../../../../redux/states/forgotPasswordModal";
import fields from "../../../../redux/fields/forgotPassword";
import {forgotPassword as authForgotPassword} from "../../../../auth";

const {
    SUBMIT_FORGOT_PASSWORD_FORM,
    CLOSE_FORGOT_PASSWORD_MODAL,
} = signals.actionTypes;

const {
    setForgotPasswordModalState,
    setForgotPasswordForm,
    updateForgotPasswordForm,
    clearForgotPasswordForm,
} = deltas.actionCreators;

const {
    OPEN_STATIC,
    REQUESTING,
    SUCCESS,
    CLOSED,
} = states;

const {
    EMAIL,
} = fields;

export default function* forgotPassword(email = '') {
    yield put(setForgotPasswordModalState(OPEN_STATIC));
    yield put(setForgotPasswordForm({email}));

    while (true) {
        const {type} = yield take([SUBMIT_FORGOT_PASSWORD_FORM, CLOSE_FORGOT_PASSWORD_MODAL]);

        switch (type) {
            case SUBMIT_FORGOT_PASSWORD_FORM:
                yield put(setForgotPasswordModalState(REQUESTING));

                const email = yield select(state => state.forms.forgotPassword[EMAIL].value);

                try {
                    yield call(authForgotPassword, email);
                } catch (e) {
                    yield put(updateForgotPasswordForm(EMAIL, email, e.message));

                    continue;
                }

                yield put(setForgotPasswordModalState(SUCCESS));
                break;
            case CLOSE_FORGOT_PASSWORD_MODAL:
                yield put(setForgotPasswordModalState(CLOSED));
                yield put(clearForgotPasswordForm());
                return;
            default:
                break;
        }
    }
}

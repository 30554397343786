const fields = {
    NAME: 'name',
    DESCRIPTION: 'description',
    PROVIDER_ID: 'providerId',
};

export default fields;

export const cohortFieldsToExcludeForProvider = [
    fields.PROVIDER_ID,
];

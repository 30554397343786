import createFormReducer from "../../helpers/createFormReducer";
import fields from "../../fields/login";
import deltas from "../../actions/deltas";

const {
    UPDATE_FORGOT_PASSWORD_FORM,
    SET_FORGOT_PASSWORD_FORM,
    CLEAR_FORGOT_PASSWORD_FORM,
} = deltas.actionTypes;

export default createFormReducer(fields, UPDATE_FORGOT_PASSWORD_FORM, SET_FORGOT_PASSWORD_FORM, CLEAR_FORGOT_PASSWORD_FORM);

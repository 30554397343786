import {cancel, fork, take, select} from "@redux-saga/core/effects";
import deltas from "../../../../redux/actions/deltas";
import enrolmentsPageTabStates from "../../../../redux/states/enrolmentsPageTab";
import pollPaymentProcesses from "./pollPaymentProcesses";
import pollFailedPaymentProcesses from "./pollFailedPaymentProcesses";
import pollEnrolmentsAndCohorts from "./pollEnrolmentsAndCohorts";
import {selectEnrolmentPageTab} from "../../../../redux/reducers/states/enrolmentsPageTab";

const {
    PAYMENTS_TAB,
    FAILED_PAYMENT_PROCESSES_TAB,
    ENROLMENTS_TAB,
    COHORTS_TAB,
} = enrolmentsPageTabStates;

const {
    SET_ENROLMENTS_PAGE_TAB_STATE
} = deltas.actionTypes;

const TAB_POLLING_MATRIX = {
    [PAYMENTS_TAB]: pollPaymentProcesses,
    [FAILED_PAYMENT_PROCESSES_TAB]: pollFailedPaymentProcesses,
    [ENROLMENTS_TAB]: pollEnrolmentsAndCohorts,
    [COHORTS_TAB]: pollEnrolmentsAndCohorts,
};

export default function* polling()
{
    let previousPolling;
    let previousTab = yield select(selectEnrolmentPageTab);

    if (TAB_POLLING_MATRIX[previousTab] !== undefined) {
        previousPolling = yield fork(TAB_POLLING_MATRIX[previousTab], true);
    }

    while (true) {
        const {type, stateConstant: tab} = yield take([
            SET_ENROLMENTS_PAGE_TAB_STATE,
        ]);

        switch (type) {
            case SET_ENROLMENTS_PAGE_TAB_STATE:
                if (TAB_POLLING_MATRIX[previousTab] === TAB_POLLING_MATRIX[tab]) {
                    break;
                }

                previousTab = tab;

                if (undefined !== previousPolling) {
                    yield cancel(previousPolling);
                }

                if (TAB_POLLING_MATRIX[tab] !== undefined) {
                    previousPolling = yield fork(TAB_POLLING_MATRIX[tab]);
                }
                break;
            default:
        }
    }
}

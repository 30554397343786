import ModalMessage from "../../../molecules/ModalMessage";
import React from "react";
import {connect} from "react-redux";
import signals from "../../../../redux/actions/signals";
import confirmDeleteEnrolmentModalStates from "../../../../redux/states/confirmDeleteEnrolmentModal";

const {
    closeConfirmDeleteEnrolmentModal,
    submitConfirmDeleteEnrolmentModal,
} = signals.actionCreators;

const {
    OPEN_STATIC,
    REQUESTING,
} = confirmDeleteEnrolmentModalStates;

const mapStateToProps = state => ({
    isConfirmDeleteEnrolmentModalOpen: [OPEN_STATIC, REQUESTING].includes(state.states.confirmDeleteEnrolmentModal.state),
    isConfirmDeleteEnrolmentModalRequesting: REQUESTING === state.states.confirmDeleteEnrolmentModal.state,
});

const mapDispatchToProps = dispatch => ({
    closeConfirmDeleteEnrolmentModal: () => dispatch(closeConfirmDeleteEnrolmentModal()),
    submitConfirmDeleteEnrolmentModal: () => dispatch(submitConfirmDeleteEnrolmentModal())
});

const ConfirmDeleteEnrolmentModal = ({isConfirmDeleteEnrolmentModalOpen, isConfirmDeleteEnrolmentModalRequesting, closeConfirmDeleteEnrolmentModal, submitConfirmDeleteEnrolmentModal}) =>
    <ModalMessage
        title="Confirm Delete Enrolment"
        overrideSaveButtonText="Delete Permanently"
        isOpen={isConfirmDeleteEnrolmentModalOpen}
        isRequesting={isConfirmDeleteEnrolmentModalRequesting}
        closeModal={closeConfirmDeleteEnrolmentModal}
        submitForm={submitConfirmDeleteEnrolmentModal}
        additionalClasses={"mt-5"}
    >
        <div className={`alert alert-cec-warning fade-in-bottom mb-0`}>
            <div>
                <b>Warning!</b> This will delete the enrolment record.
            </div>
        </div>
        <div className="mt-4">
            Are you sure you want to permanently delete this enrolment?
        </div>
    </ModalMessage>;

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmDeleteEnrolmentModal);

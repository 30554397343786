import {put, select, take, call} from "@redux-saga/core/effects";
import deltas from "../../../../../redux/actions/deltas";
import signals from "../../../../../redux/actions/signals";
import cohortModalStates from "../../../../../redux/states/cohortModal";
import cohortFields, {cohortFieldsToExcludeForProvider} from "../../../../../redux/fields/cohort";
import cohortModalTypeStates from "../../../../../redux/states/cohortModalType";
import getDataForForm from "../../../../helpers/getDataForForm";
import isFormDataClean from "../../../../helpers/isFormDataClean";
import getFieldValuesFromFormData from "../../../../helpers/getFieldValuesFromFormData";
import {createCohort, updateCohortById} from "../../../../../api";
import submitFormData from "../../../../helpers/submitFormData";
import roles from "../../../../../redux/roles";
import notify from "../../../../helpers/notify";

const areYouSureMessage = 'Are you sure? You have unsaved changes.';
const failureMessage = 'There was a problem updating the information of this cohort';

const {
    setCohortModalState,
    setCohortModalTypeState,
    setCohortForm,
    clearCohortForm,
    updateCohort,
    setCohortModalIdState,
    setCohorts,
    setEnrolments,
} = deltas.actionCreators;

const {
    SUBMIT_COHORT_FORM,
    CLOSE_COHORT_MODAL,
} = signals.actionTypes;

const {
    OPEN_STATIC,
    REQUESTING,
    CLOSED,
} = cohortModalStates;

const {
    NEW,
    EDIT,
} = cohortModalTypeStates;

export default function* createOrEditCohort(id) {
    yield put(clearCohortForm());

    const cohortData = id ? yield select(state => state.data.cohorts[id]) : null;

    if (id) {
        yield put(setCohortModalIdState(id));
        yield put(setCohortForm(getDataForForm(cohortData, cohortFields)));
    }

    id
        ? yield put(setCohortModalTypeState(EDIT))
        : yield put(setCohortModalTypeState(NEW));

    yield put(setCohortModalState(OPEN_STATIC));

    while (true) {
        const {type} = yield take([SUBMIT_COHORT_FORM, CLOSE_COHORT_MODAL]);
        const formData = yield select(state => state.forms.cohort);

        switch (type) {
            case SUBMIT_COHORT_FORM:
                yield put(setCohortModalState(REQUESTING));

                if (formData._hasErrors) {
                    yield call(notify, 'warning', 'Please fix the form errors before continuing');
                    break;
                }

                const role = yield select(state => state.role);

                const formDataForRequest = {...formData};

                if (roles.PROVIDER === role) {
                    for (const fieldToExclude of cohortFieldsToExcludeForProvider) {
                        delete formDataForRequest[fieldToExclude];
                    }
                }

                const {currentEntity: cohort, fieldErrors} = yield call(submitFormData, ...[(id ? updateCohortById : createCohort), formDataForRequest, failureMessage, id]);

                if(!cohort) {
                    break;
                }

                // Only put the cohort returned by the backend into the list of cohorts if we are editing, or if the creation has succeeded
                if (id || !fieldErrors) {
                    // TODO it would be better if the grid reducer responds to updates
                    // In the case of deletions, it could remove the row with the corresponding id
                    yield put(updateCohort(cohort.id, cohort));
                    const cohorts = yield select(state => state.data.cohorts);
                    yield put(setCohorts(cohorts));

                    // If the name has changed from the original data, update the cohort name on the related enrolments
                    if (id && cohortData.name !== cohort.name) {
                        const cohortId = id;

                        const enrolments = yield select(state => state.data.enrolments);

                        const enrolmentsWithNewCohortName = Object.assign(...Object.entries(enrolments).map(([id, enrolment]) => ({
                            [id]: {
                                ...enrolment,
                                ...(String(enrolment.cohortId) === String(cohortId) && {
                                    cohortName: cohort.name,
                                }),
                            }
                        })));

                        yield put(setEnrolments(enrolmentsWithNewCohortName));
                    }
                }

                if (fieldErrors) {
                    yield put(setCohortForm(getFieldValuesFromFormData(formData), fieldErrors, false));
                    break;
                }

                yield put(setCohortModalState(CLOSED));
                yield put(clearCohortForm());
                return;
            case CLOSE_COHORT_MODAL:
                if (isFormDataClean(formData) || window.confirm(areYouSureMessage)) {
                    yield put(setCohortModalState(CLOSED));
                    yield put(clearCohortForm());
                    return;
                }
            break;
            default:
        }
        yield put(setCohortModalState(OPEN_STATIC));
    }
}

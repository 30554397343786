import createDataReducer from "../../helpers/createDataReducer";
import deltas from "../../actions/deltas";

const {
    SET_COURSES,
    UPDATE_COURSE,
    CLEAR_COURSES,
} = deltas.actionTypes;

export const selectCourses = (state) => state.data.courses;

export default createDataReducer(
    SET_COURSES,
    UPDATE_COURSE,
    CLEAR_COURSES,
);

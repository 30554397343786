import createDataReducer from "../../helpers/createDataReducer";
import deltas from "../../actions/deltas";

const {
    SET_REGIONS,
    UPDATE_REGION,
    CLEAR_REGIONS,
} = deltas.actionTypes;

export const selectRegions = (state) => state.data.regions;

export default createDataReducer(
    SET_REGIONS,
    UPDATE_REGION,
    CLEAR_REGIONS,
);

import {tryingInitialLogin} from "./state-machines/tryingInitialLogin";
import {call, fork, select} from "redux-saga/effects";
import routes from "../redux/routes";
import resetPassword from "./state-machines/pages/reset-password/resetPassword";

export default function* root() {
    const route = yield select(state => state.location.type);

    if (routes.RESET_PASSWORD === route) {
        yield call(resetPassword);
    }

    yield fork(tryingInitialLogin);
}

import {put, select, take, call, cancel, debounce, spawn} from "@redux-saga/core/effects";
import deltas from "../../../../../redux/actions/deltas";
import signals from "../../../../../redux/actions/signals";
import schoolAndLoginFields from "../../../../../redux/fields/schoolAndLogin";
import schoolAndLoginModalStates from "../../../../../redux/states/schoolAndLoginModal";
import getDataForForm from "../../../../helpers/getDataForForm";
import isFormDataClean from "../../../../helpers/isFormDataClean";
import getFieldValuesFromFormData from "../../../../helpers/getFieldValuesFromFormData";
import {updateSchoolAndLoginInformation} from "../../../../../api";
import submitFormData from "../../../../helpers/submitFormData";
import fields from "../../../../../redux/fields/schoolAndLogin";
import notify from "../../../../helpers/notify";
import schoolSearch from "./schoolSearch";
import validator from "../../../../../components/molecules/validator";
import loggingOut from "../../../loggingOut";
import {redirect} from "redux-first-router";
import routes from "../../../../../redux/routes";

const areYouSureMessage = 'Are you sure? You have unsaved changes.';
const failureMessage = 'There was a problem updating your school and login information';
const schoolOnlySuccessMessage = 'You have successfully updated your school information';

const {
    setSchoolAndLoginModalState,
    setSchoolAndLoginForm,
    setProfile,
    clearSchoolAndLoginForm,
    clearSchoolSearchResults,
    updateSchoolAndLoginForm,
} = deltas.actionCreators;

const {
    OPEN_STATIC,
    REQUESTING,
    CLOSED,
} = schoolAndLoginModalStates;

const {
    SUBMIT_SCHOOL_AND_LOGIN_FORM,
    CLOSE_SCHOOL_AND_LOGIN_MODAL,
    SEARCH_SCHOOLS,
} = signals.actionTypes;

const {
    UPDATE_SCHOOL_AND_LOGIN_FORM,
} = deltas.actionTypes;

const debounceMilliseconds = 200;

export default function* schoolAndLogin() {

    const profile = yield select(state => state.data.profile);

    const dataForForm = getDataForForm(profile, schoolAndLoginFields);
    dataForForm[fields.URN] = null;

    const searchSchoolsTaskId = yield debounce(debounceMilliseconds, [SEARCH_SCHOOLS], schoolSearch);

    yield put(setSchoolAndLoginForm(dataForForm));
    yield put(clearSchoolSearchResults());
    yield put(setSchoolAndLoginModalState(OPEN_STATIC));

    while (true) {
        const {type, field, value} = yield take([SUBMIT_SCHOOL_AND_LOGIN_FORM, UPDATE_SCHOOL_AND_LOGIN_FORM, CLOSE_SCHOOL_AND_LOGIN_MODAL]);
        const formData = yield select(state => state.forms.schoolAndLogin);

        switch (type) {
            case UPDATE_SCHOOL_AND_LOGIN_FORM:
                if (fields.WANT_TO_CHANGE_LOGIN === field) {
                    yield put(updateSchoolAndLoginForm(fields.URN, null, validator(null, 'no' === value)));
                }
                if (fields.URN === field) {
                    yield put(updateSchoolAndLoginForm(fields.STARTED_AT, null, validator(null, true)));
                }
                break;
            case SUBMIT_SCHOOL_AND_LOGIN_FORM:
                yield put(setSchoolAndLoginModalState(REQUESTING));

                if (formData._hasErrors) {
                    yield call(notify, 'warning', 'Please fix the form errors before continuing');
                    break;
                }

                const {currentEntity: profile, fieldErrors, headers} = yield call(submitFormData, ...[updateSchoolAndLoginInformation, formData, failureMessage]);

                if(!profile) {
                    break;
                }

                yield put(setProfile(profile));

                if (fieldErrors) {
                    yield put(setSchoolAndLoginForm(getFieldValuesFromFormData(formData), fieldErrors, false));
                    break;
                }

                if (headers && headers['re-authenticate'] && 'true' === headers['re-authenticate']) {

                    // Logout, user will then get redirected to the login screen
                    yield put({type: routes.LOGOUT});
                    return;
                }

                yield spawn(notify, 'success', schoolOnlySuccessMessage);

                yield put(setSchoolAndLoginModalState(CLOSED));
                yield put(clearSchoolAndLoginForm());
                yield put(clearSchoolSearchResults());
                yield cancel(searchSchoolsTaskId);
                return;
            case CLOSE_SCHOOL_AND_LOGIN_MODAL:
                if (isFormDataClean(formData) || window.confirm(areYouSureMessage)) {
                    yield put(clearSchoolAndLoginForm());
                    yield put(setSchoolAndLoginModalState(CLOSED));
                    // This is important, otherwise this saga doesn't end, and doesn't return control to the profile page saga
                    // Result being that no other modals can open
                    yield cancel(searchSchoolsTaskId);
                    return;
                }
                break;
            default:
        }

        yield put(setSchoolAndLoginModalState(OPEN_STATIC));
    }
}

import React, {useMemo, useState} from "react";
import GridTextFilterOption from "./GridTextFilterOption";
import Fuse from 'fuse.js';

export default ({filterOptions, addFilterValue, removeFilterValue, clearFilter}) => {
    const [search, setSearch] = useState('');

    const fuse = useMemo(() => new Fuse(filterOptions, fuseOptions), [filterOptions]);

    return <div className="py-3 px-2">
        <input className="px-2" placeholder="Search filter" type={'text'} value={search} onChange={e => setSearch(e.target.value)}/>
        <div className="text-right my-3">
            <button onClick={clearFilter} className="cec-btn font-12 text-right">Uncheck all</button>
        </div>
        <div className='filter-scrollable-options'>
            <ul className="m-0 p-0 pr-2">
                {('' === search
                        ? [...filterOptions]
                        : fuse.search(search).map(({item: {value, selected}}) => ({value, selected}))
                )
                    .sort(({value: aValue, selected: aSelected}, {value: bValue, selected: bSelected}) =>
                        aSelected
                            ? bSelected
                                ? String(aValue).localeCompare(String(bValue), undefined, {numeric: true})
                                : -1
                            : bSelected
                                ? 1
                                : String(aValue).localeCompare(String(bValue), undefined, {numeric: true})
                    )
                    .map(({value, selected}) => <GridTextFilterOption {...{
                            value,
                            selected,
                            addFilterValue,
                            removeFilterValue
                        }} />
                    )}
            </ul>
        </div>
    </div>;
};

const fuseOptions = {
    shouldSort: true,
    tokenize: true,
    findAllMatches: true,
    threshold: 0,
    location: 0,
    distance: 0,
    maxPatternLength: 32,
    minMatchCharLength: 1,
    keys: ['value'],
};

import React, {useState} from "react";

export const FieldErrorEntry = ({message, data, i}) => {
    const [isCollapsed, setCollapsed] = useState(true);
    const toggleCollapsed = () => setCollapsed((isCollapsed) => !isCollapsed);

    return <div className={`field-error--entry ${isCollapsed ? 'collapsed' : ''}`} onClick={toggleCollapsed}>
        <span className={"field-error--entry-index"}>#{i}</span>
        <p className={"field-error--entry-message"}>
            {message}
        </p>
        <p className={"field-error--entry-data"} style={{marginLeft: '10px'}} onClick={(e) => e.stopPropagation()}>
            {renderData(data)}
        </p>
    </div>;
}

const renderData = (data) => {
    if (Array.isArray(data)) {
        return renderArray(data);
    }

    if (null !== data && 'object' === typeof data) {
        return renderObject(data);
    }

    return renderValue(data);
};
const renderObject = (data) => Object.entries(data).map(([key, value]) => <div className={"field-error--object"}>
    <span className={"field-error--object-key"}>
        {key}:&nbsp;
    </span>
    <span className={"field-error--object-value"}>
        {(null !== value && 'object' === typeof value)
            ? renderData(value)
            : renderValue(value)
        }
    </span>
</div>);
const renderArray = (data) => <div className={"field-error--array"}><ul>{data.map(renderData)}</ul></div>;
const renderValue = (value) => <span className={"field-error--value"}><li>{String(value)}</li></span>;

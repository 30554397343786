import React from "react";
import {useDispatch, useSelector} from "react-redux";
import signals from "../../../../redux/actions/signals";
import {selectGridsConfirmationModalErrors} from "../../../../redux/reducers/states/gridsConfirmationModalErrors";
import Loading from "../../../pages/Loading";
import AlertMessage from "../../../atoms/AlertMessage";
import {FieldErrorEntry} from "../../../molecules/FieldErrorEntry";

const {
    closeGridsConfirmationModal,
    submitGridsConfirmationModal,
} = signals.actionCreators;

export const BulkUpdateGridsConfirmationErrors = () => {

    const errors = useSelector(selectGridsConfirmationModalErrors);

    return <div className={"field-error--entry-list"}>
        <AlertMessage>There were errors during the bulk update</AlertMessage>
            {
                errors
                    ? Object.entries(errors).map(([enrolmentId, fieldErrors]) => <FieldErrorEntry message={'Enrolment:' + enrolmentId} data={fieldErrors} i={enrolmentId}/>)
                    : <AlertMessage>More information not available</AlertMessage>
            }
        </div>;

    };

import {applyMiddleware, compose, createStore} from 'redux';
import createSagaMiddleware from 'redux-saga';
import rootReducer from './reducers/root';
import {routerEnhancer, routerMiddleware} from "./router";
import root from "../sagas/root";

const sagaMiddleware = createSagaMiddleware();
const composeEnhancers = ('development' === process.env.NODE_ENV && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

export const store = createStore(
    rootReducer,
    undefined,
    composeEnhancers(
        routerEnhancer,
        applyMiddleware(routerMiddleware, sagaMiddleware)
    )
);

sagaMiddleware.run(root);


import createFormReducer from "../../helpers/createFormReducer";
import fields from "../../fields/personalInformation";
import deltas from "../../actions/deltas";

const {
    UPDATE_PERSONAL_INFORMATION_FORM,
    SET_PERSONAL_INFORMATION_FORM,
    CLEAR_PERSONAL_INFORMATION_FORM,
} = deltas.actionTypes;

export default createFormReducer(fields, UPDATE_PERSONAL_INFORMATION_FORM, SET_PERSONAL_INFORMATION_FORM, CLEAR_PERSONAL_INFORMATION_FORM);

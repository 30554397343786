import {combineReducers} from 'redux';
import app from './states/app';
import login from "./states/login";
import privacy from "./states/privacy";
import comms from "./states/comms";
import personalInformationModal from "./states/personalInformationModal";
import courseInformationModal from "./states/courseInformationModal";
import enrolmentModal from "./states/enrolmentModal";
import enrolmentModalId from "./states/enrolmentModalId";
import cohortModal from "./states/cohortModal";
import cohortModalType from "./states/cohortModalType";
import cohortModalId from "./states/cohortModalId";
import gridsConfirmationModal from "./states/gridsConfirmationModal";
import gridsConfirmationModalType from "./states/gridsConfirmationModalType";
import schoolAndLoginModal from "./states/schoolAndLoginModal";
import confirmDeleteEnrolmentModal from "./states/confirmDeleteEnrolmentModal";
import confirmReleaseEnrolmentModal from "./states/confirmReleaseEnrolmentModal";
import schoolSearch from "./states/schoolSearch";
import careersLeadSearch from "./states/careersLeadSearch";
import retryingFailedPaymentProcessIds from "./states/retryingFailedPaymentProcessIds";
import enrolmentsPageTab from "./states/enrolmentsPageTab";
import pollingPaymentProcesses from "./states/pollingPaymentProcesses";
import pollingFailedPaymentProcesses from "./states/pollingFailedPaymentProcesses";
import pollingEnrolmentsAndCohorts from "./states/pollingEnrolmentsAndCohorts";
import reloadingFailedPaymentProcesses from "./states/reloadingFailedPaymentProcesses";
import reloadingEnrolmentsAndCohorts from "./states/reloadingEnrolmentsAndCohorts";
import downloadAllTransactions from "./states/downloadAllTransactions"
import downloadMonthlyClaim from "./states/downloadMonthlyClaim";
import downloadFinanceReport from "./states/downloadFinanceReport";
import failedPaymentProcessesMoreInfoModal from "./states/failedPaymentProcessesMoreInfoModal";
import failedPaymentProcessesMoreInfoModalId from "./states/failedPaymentProcessesMoreInfoModalId";
import gridsConfirmationModalErrors from "./states/gridsConfirmationModalErrors";
import forgotPasswordModal from "./states/forgotPasswordModal";
import resetPassword from "./states/resetPassword";

export default combineReducers({
    app,
    login,
    privacy,
    comms,
    personalInformationModal,
    courseInformationModal,
    enrolmentModal,
    enrolmentModalId,
    cohortModal,
    cohortModalType,
    cohortModalId,
    gridsConfirmationModal,
    gridsConfirmationModalType,
    schoolAndLoginModal,
    confirmDeleteEnrolmentModal,
    confirmReleaseEnrolmentModal,
    schoolSearch,
    careersLeadSearch,
    retryingFailedPaymentProcessIds,
    enrolmentsPageTab,
    pollingPaymentProcesses,
    pollingFailedPaymentProcesses,
    pollingEnrolmentsAndCohorts,
    reloadingFailedPaymentProcesses,
    reloadingEnrolmentsAndCohorts,
    downloadAllTransactions,
    downloadMonthlyClaim,
    downloadFinanceReport,
    failedPaymentProcessesMoreInfoModal,
    failedPaymentProcessesMoreInfoModalId,
    gridsConfirmationModalErrors,
    forgotPasswordModal,
    resetPassword,
});

import createFormReducer from "../../helpers/createFormReducer";
import fields from "../../fields/resetPassword";
import deltas from "../../actions/deltas";
import * as Yup from "yup";

const {
    UPDATE_RESET_PASSWORD_FORM,
    SET_RESET_PASSWORD_FORM,
    CLEAR_RESET_PASSWORD_FORM,
} = deltas.actionTypes;

const {
    EMAIL,
    PASSWORD,
    CONFIRM_PASSWORD,
    CODE,
} = fields;

export default createFormReducer(fields, UPDATE_RESET_PASSWORD_FORM, SET_RESET_PASSWORD_FORM, CLEAR_RESET_PASSWORD_FORM, {[PASSWORD]: [CONFIRM_PASSWORD]});

export const PASSWORDS_DONT_MATCH = 'Passwords don\'t match, please try again.';

export const resetPasswordSchemaByField = {
    [EMAIL]: Yup.string()
        .email('Please enter a valid email.')
        .required('Please enter an email.'),
    [PASSWORD]: Yup.string()
        .required('Please enter the password.')
        .min(6, 'Password needs to be 10 characters.')
        .max(99, 'Password needs to be fewer than 99 characters.')
        .matches(/.*[A-Z]+.*/, 'Password must include a capital letter.')
        .matches(/.*[a-z]+.*/, 'Password must include a lowercase letter.')
        .matches(/.*[0-9]+.*/, 'Password must include a number.')
        .matches(/.*[\^$*.[\]{}()?"!@#%&/\\,><':;|_~`]+.*/, 'Password must include a special character.'),
    [CONFIRM_PASSWORD]: (password) => Yup.string()
        .required('Please repeat the password.')
        .oneOf([ password, '' ], PASSWORDS_DONT_MATCH),
    [CODE]: Yup.string()
        .required('Please enter a valid verification code.')
        .matches(/^[0-9]{6}$/, 'Please enter a valid verification code.'),
};

import createReducer from "./createReducer";

export default (stateConstants, initialStateConstant, update) => createReducer(
    {
        state: initialStateConstant,
    },
    {
        [update]: (state, {stateConstant}) => ({
            ...state,
            ...(Object.values(stateConstants).includes(stateConstant)) && {state: stateConstant},
        }),
    },
);

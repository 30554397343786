import failedPaymentProcessesMoreInfoModalStates from "../../../../redux/states/failedPaymentProcessesMoreInfoModal";
import React from "react";
import ModalMessage from "../../../molecules/ModalMessage";
import {connect} from "react-redux";
import signals from "../../../../redux/actions/signals";
import {
    selectFailedPaymentProcessesMoreInfoModalState
} from "../../../../redux/reducers/states/failedPaymentProcessesMoreInfoModal";
import {
    selectFailedPaymentProcessesMoreInfoModalId
} from "../../../../redux/reducers/states/failedPaymentProcessesMoreInfoModalId";
import {
    selectFailedPaymentProcessMoreInfoById
} from "../../../../redux/reducers/data/failedPaymentProcesses";
import {MoreInfoEntry} from "../../../molecules/MoreInfoEntry";
import Loading from "../../../pages/Loading";
import AlertMessage from "../../../atoms/AlertMessage";

const {
    OPEN_STATIC,
    REQUESTING,
} = failedPaymentProcessesMoreInfoModalStates;

const {
    closeFailedPaymentProcessesMoreInfoModal,
} = signals.actionCreators;

const mapStateToProps = (state) => {
    const id = selectFailedPaymentProcessesMoreInfoModalId(state);
    const failedPaymentProcessesMoreInfoModalState = selectFailedPaymentProcessesMoreInfoModalState(state);

    return {
        isModalOpen: [OPEN_STATIC, REQUESTING].includes(failedPaymentProcessesMoreInfoModalState),
        isDataLoading: REQUESTING === failedPaymentProcessesMoreInfoModalState,
        moreInfo: id ? selectFailedPaymentProcessMoreInfoById(id)(state) : null,
    };
}

const mapDispatchToProps = {
    closeModal: closeFailedPaymentProcessesMoreInfoModal,
};

const FailedPaymentProcessesMoreInfoModal = ({isModalOpen, closeModal, isDataLoading, moreInfo}) =>
    <ModalMessage
        title={'More Information'}
        isOpen={isModalOpen}
        closeModal={closeModal}
        size={"lg"}
        overrideCancelButtonText={'Close'}
    >
        {
            isDataLoading
                ? <Loading />
                : <div className={"more-info--entry-list"}>
                    {
                        moreInfo &&
                            Array.isArray(moreInfo)
                                ? moreInfo.map((entry, i) => <MoreInfoEntry message={entry.message} data={entry.data} i={i}/>)
                            : <AlertMessage>More information not available</AlertMessage>
                    }
                </div>
        }
    </ModalMessage>;

export default connect(mapStateToProps, mapDispatchToProps)(FailedPaymentProcessesMoreInfoModal);

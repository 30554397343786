import routes from "../../redux/routes";
import isRouteAllowed from "../../components/isRouteAllowed";
import {call, cancel, select} from "redux-saga/effects";
import profile from "../state-machines/pages/profile/profile";
import enrolments from "../state-machines/pages/enrolments/enrolments";

export default function* respondToRoute(route) {

    const role = yield select(state => state.role);

    // Routes are wrapped in while true and try/catch in order to recover from errors

    let runningSagaId;

    if (isRouteAllowed(route, role)) {
        switch (route) {
            case routes.CL_PROFILE:
                while (true) {
                    try {
                        if (runningSagaId) {
                            yield cancel(runningSagaId);
                        }

                        runningSagaId = yield call(profile);
                    } catch (exception) {
                        if ('development' === process.env.NODE_ENV) {
                            console.error(exception);
                        }
                    }
                }
            case routes.ENROLMENTS:
                while (true) {
                    try {
                        if (runningSagaId) {
                            yield cancel(runningSagaId);
                        }

                        runningSagaId = yield call(enrolments);
                    } catch (exception) {
                        if ('development' === process.env.NODE_ENV) {
                            console.error(exception);
                        }
                    }
                }
            default:
        }
    }
}

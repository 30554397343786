import React, {useState} from "react";

export const MoreInfoEntry = ({message, data, i}) => {
    const [isCollapsed, setCollapsed] = useState(true);
    const toggleCollapsed = () => setCollapsed((isCollapsed) => !isCollapsed);

    return <div className={`more-info--entry ${isCollapsed ? 'collapsed' : ''}`} onClick={toggleCollapsed}>
        <span className={"more-info--entry-index"}>#{i + 1}</span>
        <p className={"more-info--entry-message"}>
            {message}
        </p>
        <p className={"more-info--entry-data"} style={{marginLeft: '10px'}} onClick={(e) => e.stopPropagation()}>
            {renderData(data)}
        </p>
    </div>;
}

const renderData = (data) => {
    if (Array.isArray(data)) {
        return renderArray(data);
    }

    if (null !== data && 'object' === typeof data) {
        return renderObject(data);
    }

    return renderValue(data);
};
const renderObject = (data) => Object.entries(data).map(([key, value]) => <div className={"more-info--object"}>
    <span className={"more-info--object-key"}>
        {key}:&nbsp;
    </span>
    <span className={"more-info--object-value"}>
        {(null !== value && 'object' === typeof value)
            ? renderData(value)
            : renderValue(value)
        }
    </span>
</div>);
const renderArray = (data) => <div className={"more-info--array"}>[{data.map(renderData)}]</div>;
const renderValue = (value) => <span className={"more-info--value"}>{String(value)}</span>;

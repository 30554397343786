import {call, put} from "@redux-saga/core/effects";
import request from "../../../../helpers/request";
import {searchSchoolByUrnOrName} from "../../../../../api";
import deltas from "../../../../../redux/actions/deltas";
import schoolSearchStates from "../../../../../redux/states/schoolSearch";

const {
    setSchoolSearchResults,
    setSchoolSearchState,
} = deltas.actionCreators;

export default function* schoolSearch({query}) {
    if ('' === query) {
        return;
    }

    yield put (setSchoolSearchState(schoolSearchStates.REQUESTING));

    const result = yield call(
        request,
        searchSchoolByUrnOrName,
        ...[
            {},
            {query},
        ]
    );

    const {response: {data} = {}} = result || {};

    if (data) {
        yield put(setSchoolSearchResults({
            results: data,
        }));
    }

    yield put (setSchoolSearchState(schoolSearchStates.STATIC));
}

import React from "react";
import {connect} from "react-redux";
import fields from "../../redux/fields/resetPassword";
import states from "../../redux/states/resetPassword";
import FormContext from "../FormContext";
import inputs from "../molecules/inputsWithContextAndSurrounds";
import deltas from "../../redux/actions/deltas";
import signals from "../../redux/actions/signals";
import EmailOutlineIcon from "mdi-react/EmailOutlineIcon";
import KeyIcon from "mdi-react/KeyIcon";
import {resetPasswordSchemaByField as schema} from "../../redux/reducers/forms/resetPassword";

const {
    TextInput,
    EmailInput,
    PasswordInput,
} = inputs;

const {
    REQUESTING,
} = states;

const {
    updateResetPasswordForm,
} = deltas.actionCreators;

const {
    submitResetPasswordForm,
} = signals.actionCreators;

const mapStateToProps = state => ({
    formState: state.forms.resetPassword,
    isRequesting: REQUESTING === state.states.resetPassword.state,
});

const mapDispatchToProps = {
    updateResetPasswordForm,
    submitResetPasswordForm,
};

const ResetPassword = ({isRequesting, formState, updateResetPasswordForm, submitResetPasswordForm}) => {
    return (
        <React.Fragment>
            <div className="form--reset-password bg-white rounded shadow-lg px-3 py-5 px-md-5">
                {/* Title */}
                <div className="mb-5">
                    <h1 className="font-20 font-weight-bold">Reset Password</h1>
                </div>
                {/* Form */}
                <FormContext.Provider value={{formState, updater: updateResetPasswordForm}}>
                    <form action={"#"} onSubmit={e => {e.preventDefault(); submitResetPasswordForm();}}>
                        <div className="mb-3">
                            <EmailInput id="o-resetPassword-email" label="Confirm your email address" placeholder="Enter your email address" icon={<EmailOutlineIcon />} field={fields.EMAIL} required yupSchema={schema[fields.EMAIL]} />
                        </div>
                        <div className="mb-3">
                            <PasswordInput id="o-resetPassword-password" label="Enter your new password" placeholder="Enter your new password" icon={<KeyIcon />} field={fields.PASSWORD} required yupSchema={schema[fields.PASSWORD]} />
                        </div>
                        <div className="mb-3">
                            <PasswordInput id="o-resetPassword-confirmPassword" label="Enter your password one more time" placeholder="Enter your new password again" icon={<KeyIcon />} field={fields.CONFIRM_PASSWORD} required yupSchema={schema[fields.CONFIRM_PASSWORD](formState[fields.PASSWORD].value)} />
                        </div>
                        <div className="mb-3">
                            <TextInput id="o-resetPassword-code" label="Verification code" placeholder="Enter the verification code" icon={<KeyIcon />} field={fields.CODE} required yupSchema={schema[fields.CODE]} />
                        </div>
                        <div className="mt-4 mb-4">
                            <p>
                                We will always take appropriate measures to keep your personal information confidential, secure, and protected, including when we need to share it with third parties.
                            </p>
                        </div>
                        <div className="mt-3">
                            <div className="row no-gutters justify-content-between align-items-end">
                                <div className="col-auto">
                                    <button type={"submit"} data-cy="reset-password-button" className="cec-btn cec-btn-primary-teal" disabled={isRequesting}>Reset password</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </FormContext.Provider>
            </div>
        </React.Fragment>
    )
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);

import {call, put, select} from "@redux-saga/core/effects";
import deltas from "../../../../redux/actions/deltas";
import commsStates from "../../../../redux/states/comms";
import request from "../../../helpers/request";
import {updateCommsAgreed} from "../../../../api";

const {
    setCommsState,
    setProfile,
} = deltas.actionCreators;

const failureMessage = 'There was a problem updating your communications agreement';

export default function* updateComms() {
    yield put(setCommsState(commsStates.REQUESTING));

    const isCommsAgreed = yield select(state => state.data.profile.isCommsAgreed);
    const newIsCommsAgreed = !isCommsAgreed;

    const result = yield call(request, updateCommsAgreed, ...[{notificationOnError: {generateMessage: message => failureMessage}}, {isCommsAgreed: newIsCommsAgreed}]);

    const {response: {data: profile} = {}} = result || {};

    if (profile) {
        yield put(setProfile(profile));
    }

    // Set the state to no longer updating comms
    yield put(setCommsState(commsStates.STATIC));
}

import createActionCreator from "../helpers/createActionCreator";

const actionTypes = {
    // Login
    SUBMIT_LOGIN_FORM: 'SUBMIT_LOGIN_FORM',

    OPEN_FORGOT_PASSWORD_MODAL: 'OPEN_FORGOT_PASSWORD_MODAL',
    CLOSE_FORGOT_PASSWORD_MODAL: 'CLOSE_FORGOT_PASSWORD_MODAL',

    SUBMIT_FORGOT_PASSWORD_FORM: 'SUBMIT_FORGOT_PASSWORD_FORM',

    SUBMIT_RESET_PASSWORD_FORM: 'SUBMIT_RESET_PASSWORD_FORM',

    // Profile
    TOGGLE_IS_PRIVACY_AGREED: 'TOGGLE_IS_PRIVACY_AGREED',
    TOGGLE_IS_COMMS_AGREED: 'TOGGLE_IS_COMMS_AGREED',

    OPEN_PERSONAL_INFORMATION_MODAL: 'OPEN_PERSONAL_INFORMATION_MODAL',
    CLOSE_PERSONAL_INFORMATION_MODAL: 'CLOSE_PERSONAL_INFORMATION_MODAL',

    SUBMIT_PERSONAL_INFORMATION_FORM: 'SUBMIT_PERSONAL_INFORMATION_FORM',

    OPEN_COURSE_INFORMATION_MODAL: 'OPEN_COURSE_INFORMATION_MODAL',
    CLOSE_COURSE_INFORMATION_MODAL: 'CLOSE_COURSE_INFORMATION_MODAL',

    SUBMIT_COURSE_INFORMATION_FORM: 'SUBMIT_COURSE_INFORMATION_FORM',

    OPEN_SCHOOL_AND_LOGIN_MODAL: 'OPEN_SCHOOL_AND_LOGIN_MODAL',
    CLOSE_SCHOOL_AND_LOGIN_MODAL: 'CLOSE_SCHOOL_AND_LOGIN_MODAL',

    OPEN_CONFIRM_DELETE_ENROLMENT_MODAL: 'OPEN_CONFIRM_DELETE_ENROLMENT_MODAL',
    CLOSE_CONFIRM_DELETE_ENROLMENT_MODAL: 'CLOSE_CONFIRM_DELETE_ENROLMENT_MODAL',

    CONFIRM_RELEASE_ENROLMENT: 'CONFIRM_RELEASE_ENROLMENT',
    CANCEL_RELEASE_ENROLMENT: 'CANCEL_RELEASE_ENROLMENT',

    SUBMIT_CONFIRM_DELETE_ENROLMENT_MODAL: 'SUBMIT_CONFIRM_DELETE_ENROLMENT_MODAL',

    SUBMIT_SCHOOL_AND_LOGIN_FORM: 'SUBMIT_SCHOOL_AND_LOGIN_FORM',

    // Enrolments
    OPEN_ENROLMENT_MODAL: 'OPEN_ENROLMENT_MODAL',
    CLOSE_ENROLMENT_MODAL: 'CLOSE_ENROLMENT_MODAL',

    SUBMIT_ENROLMENT_FORM: 'SUBMIT_ENROLMENT_FORM',

    DOWNLOAD_ENROLMENTS_CSV: 'DOWNLOAD_ENROLMENTS_CSV',

    TRY_TO_MARK_CONTRACTS_AS_ISSUED: 'TRY_TO_MARK_CONTRACTS_AS_ISSUED',
    TRY_TO_MARK_COURSES_AS_COMPLETE: 'TRY_TO_MARK_COURSES_AS_COMPLETE',
    TRY_TO_APPLY_COHORT_TO_ENROLMENTS: 'TRY_TO_APPLY_COHORT_TO_ENROLMENTS',

    RELOAD_ENROLMENTS_AND_COHORTS: 'RELOAD_ENROLMENTS_AND_COHORTS',

    // Cohorts
    OPEN_COHORT_MODAL: 'OPEN_COHORT_MODAL',
    CLOSE_COHORT_MODAL: 'CLOSE_COHORT_MODAL',

    SUBMIT_COHORT_FORM: 'SUBMIT_COHORT_FORM',

    TRY_TO_DELETE_COHORT: 'TRY_TO_DELETE_COHORT',

    // Payments
    DOWNLOAD_TRANSACTIONS: 'DOWNLOAD_TRANSACTIONS',
    DOWNLOAD_MONTHLY_CLAIM: 'DOWNLOAD_MONTHLY_CLAIM',
    DOWNLOAD_FINANCE_REPORT: 'DOWNLOAD_FINANCE_REPORT',

    // Failed payment processes
    RETRY_FAILED_PAYMENT_PROCESS: 'RETRY_FAILED_PAYMENT_PROCESS',
    RELOAD_FAILED_PAYMENT_PROCESSES: 'RELOAD_FAILED_PAYMENT_PROCESSES',
    OPEN_FAILED_PAYMENT_PROCESSES_MORE_INFO_MODAL: 'OPEN_FAILED_PAYMENT_PROCESSES_MORE_INFO_MODAL',
    CLOSE_FAILED_PAYMENT_PROCESSES_MORE_INFO_MODAL: 'CLOSE_FAILED_PAYMENT_PROCESSES_MORE_INFO_MODAL',

    // Grids confirmation modal
    CLOSE_GRIDS_CONFIRMATION_MODAL: 'CLOSE_GRIDS_CONFIRMATION_MODAL',
    SUBMIT_GRIDS_CONFIRMATION_MODAL: 'SUBMIT_GRIDS_CONFIRMATION_MODAL',

    // Search schools
    SEARCH_SCHOOLS: 'SEARCH_SCHOOLS',

    // Impersonation
    SEARCH_CAREERS_LEADS: 'SEARCH_CAREERS_LEADS',
    TRY_TO_START_IMPERSONATING: 'TRY_TO_START_IMPERSONATING',
    TRY_TO_STOP_IMPERSONATING: 'TRY_TO_STOP_IMPERSONATING',
};

const actionCreators = {
    // Login
    submitLoginForm: createActionCreator(actionTypes.SUBMIT_LOGIN_FORM),

    openForgotPasswordModal: createActionCreator(actionTypes.OPEN_FORGOT_PASSWORD_MODAL, 'email'),
    closeForgotPasswordModal: createActionCreator(actionTypes.CLOSE_FORGOT_PASSWORD_MODAL),

    submitForgotPasswordForm: createActionCreator(actionTypes.SUBMIT_FORGOT_PASSWORD_FORM),

    submitResetPasswordForm: createActionCreator(actionTypes.SUBMIT_RESET_PASSWORD_FORM),

    // Profile
    toggleIsPrivacyAgreed: createActionCreator(actionTypes.TOGGLE_IS_PRIVACY_AGREED),
    toggleIsCommsAgreed: createActionCreator(actionTypes.TOGGLE_IS_COMMS_AGREED),

    openPersonalInformationModal: createActionCreator(actionTypes.OPEN_PERSONAL_INFORMATION_MODAL),
    closePersonalInformationModal: createActionCreator(actionTypes.CLOSE_PERSONAL_INFORMATION_MODAL),

    submitPersonalInformationForm: createActionCreator(actionTypes.SUBMIT_PERSONAL_INFORMATION_FORM),

    openCourseInformationModal: createActionCreator(actionTypes.OPEN_COURSE_INFORMATION_MODAL),
    closeCourseInformationModal: createActionCreator(actionTypes.CLOSE_COURSE_INFORMATION_MODAL),

    submitCourseInformationForm: createActionCreator(actionTypes.SUBMIT_COURSE_INFORMATION_FORM),

    openSchoolAndLoginModal: createActionCreator(actionTypes.OPEN_SCHOOL_AND_LOGIN_MODAL),
    closeSchoolAndLoginModal: createActionCreator(actionTypes.CLOSE_SCHOOL_AND_LOGIN_MODAL),

    openConfirmDeleteEnrolmentModal: createActionCreator(actionTypes.OPEN_CONFIRM_DELETE_ENROLMENT_MODAL),
    closeConfirmDeleteEnrolmentModal: createActionCreator(actionTypes.CLOSE_CONFIRM_DELETE_ENROLMENT_MODAL),

    confirmReleaseEnrolment: createActionCreator(actionTypes.CONFIRM_RELEASE_ENROLMENT),
    cancelReleaseEnrolment: createActionCreator(actionTypes.CANCEL_RELEASE_ENROLMENT),

    submitConfirmDeleteEnrolmentModal: createActionCreator(actionTypes.SUBMIT_CONFIRM_DELETE_ENROLMENT_MODAL),

    submitSchoolAndLoginForm: createActionCreator(actionTypes.SUBMIT_SCHOOL_AND_LOGIN_FORM),

    //Enrolments
    openEnrolmentModal: createActionCreator(actionTypes.OPEN_ENROLMENT_MODAL, 'id'),
    closeEnrolmentModal: createActionCreator(actionTypes.CLOSE_ENROLMENT_MODAL),

    submitEnrolmentForm: createActionCreator(actionTypes.SUBMIT_ENROLMENT_FORM),

    downloadEnrolmentsCsv: createActionCreator(actionTypes.DOWNLOAD_ENROLMENTS_CSV),

    tryToMarkContractsAsIssued: createActionCreator(actionTypes.TRY_TO_MARK_CONTRACTS_AS_ISSUED),
    tryToMarkCoursesAsComplete: createActionCreator(actionTypes.TRY_TO_MARK_COURSES_AS_COMPLETE),
    tryToApplyCohortToEnrolments: createActionCreator(actionTypes.TRY_TO_APPLY_COHORT_TO_ENROLMENTS),

    reloadEnrolmentsAndCohorts: createActionCreator(actionTypes.RELOAD_ENROLMENTS_AND_COHORTS),

    // Cohorts
    openCohortModal: createActionCreator(actionTypes.OPEN_COHORT_MODAL, 'id'),
    closeCohortModal: createActionCreator(actionTypes.CLOSE_COHORT_MODAL),

    submitCohortForm: createActionCreator(actionTypes.SUBMIT_COHORT_FORM),

    tryToDeleteCohort: createActionCreator(actionTypes.TRY_TO_DELETE_COHORT, 'id'),

    // Payments
    downloadTransactions: createActionCreator(actionTypes.DOWNLOAD_TRANSACTIONS, 'year', 'month'),
    downloadMonthlyClaim: createActionCreator(actionTypes.DOWNLOAD_MONTHLY_CLAIM),
    downloadFinanceReport: createActionCreator(actionTypes.DOWNLOAD_FINANCE_REPORT),

    retryFailedPaymentProcess: createActionCreator(actionTypes.RETRY_FAILED_PAYMENT_PROCESS, 'id'),
    reloadFailedPaymentProcesses: createActionCreator(actionTypes.RELOAD_FAILED_PAYMENT_PROCESSES),
    openFailedPaymentProcessesMoreInfoModal: createActionCreator(actionTypes.OPEN_FAILED_PAYMENT_PROCESSES_MORE_INFO_MODAL, 'id'),
    closeFailedPaymentProcessesMoreInfoModal: createActionCreator(actionTypes.CLOSE_FAILED_PAYMENT_PROCESSES_MORE_INFO_MODAL, 'id'),

    // Grids confirmation modal
    closeGridsConfirmationModal: createActionCreator(actionTypes.CLOSE_GRIDS_CONFIRMATION_MODAL),
    submitGridsConfirmationModal: createActionCreator(actionTypes.SUBMIT_GRIDS_CONFIRMATION_MODAL),

    // School search
    searchSchools: createActionCreator(actionTypes.SEARCH_SCHOOLS, 'query'),

    // Impersonation
    searchCareersLeads: createActionCreator(actionTypes.SEARCH_CAREERS_LEADS, 'query'),
    tryToStartImpersonating: createActionCreator(actionTypes.TRY_TO_START_IMPERSONATING),
    tryToStopImpersonating: createActionCreator(actionTypes.TRY_TO_STOP_IMPERSONATING),
};

export default {
    actionTypes,
    actionCreators,
};
